import React, {PureComponent, Fragment} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import propTypes from 'prop-types';

//components
import IconButton from "../../_commonComponents/buttons/IconButton";
import Visit1 from './Visit1';
import Visit2 from './Visit2';
import {getStoreData} from "../../../utils/getStoreData";
import {GET, SUB_VISIT1_OBJECT, SUB_VISIT2_OBJECT} from "../../../config/requestConstants";
import {bindActionCreators} from "redux";
import {fetchVisit1Data} from "../../../redux/actions/visit1/visit1Action";
import connect from "react-redux/es/connect/connect";
import {fetchVisit2Data} from "../../../redux/actions/visit2/visit2Action";

//re

class Tabs extends PureComponent {
    static propTypes = {
        visit2Data: propTypes.object,
        subName: propTypes.string,
        therapy2: propTypes.array,
        events: propTypes.array,
        verified: propTypes.bool,
        onToggle: propTypes.func.isRequired,
        verifyUser: propTypes.func.isRequired,
    };

    static defaultProps = {
        subName: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            visit1Data: null,
            visit2Data: null,
            isLoading: false
        }
    }

    componentDidMount() {
        this.getInitialData();
    }

    componentDidUpdate(prevProps) {
        if (getStoreData(() => prevProps.selectedRow.id) !== getStoreData(() => this.props.selectedRow.id)) {
            this.resetInitialData();
        } else if (prevProps.activeTab !== this.props.activeTab) {
            if (!this.state.visit1Data) {
                this.fetchVisit1Data();
            } else if (!this.state.visit2Data) {
                this.fetchVisit2Data();
            }
        }
    }

    getInitialData = () => {
        const {
            activeTab
        } = this.props;

        switch (activeTab) {
            case '1':
                this.fetchVisit1Data();
                break;
            case '2':
                this.fetchVisit2Data();
                break;
            default:
                this.fetchVisit1Data();
        }
    };

    resetInitialData = async () => {
        await this.getInitialData();
        this.setState({
            visit1Data: null,
            visit2Data: null,
        })
    };

    /**
     * @return {Promise<void>}
     */
    fetchVisit1Data = async () => {
        const {
            fetchVisit1Data,
            selectedRow
        } = this.props;

        const id = getStoreData(() => selectedRow.id);
        if (!id) return;

        const filter = [{name: "sub", value: id}];

        this.setState({
            isLoading: true
        });

        await fetchVisit1Data(GET, filter, {}, true, SUB_VISIT1_OBJECT);

        this.setState({
            visit1Data: this.props.visit1Data,
            isLoading: false
        })
    };

    /**
     * @return {Promise<void>}
     */
    fetchVisit2Data = async () => {
        const {
            fetchVisit2Data,
            selectedRow
        } = this.props;

        const id = getStoreData(() => selectedRow.id);
        if (!id) return;
        const filter = [{name: "sub", value: getStoreData(() => id)}];

        this.setState({
            isLoading: true
        });

        await fetchVisit2Data(GET, filter, {}, true, SUB_VISIT2_OBJECT);

        this.setState({
            visit2Data: this.props.visit2Data,
            isLoading: false
        })
    };

    /**
     * @return {Promise<void>}
     */
    onVerify1 = () => {
        const {
            id,
            verified1,
            verifyUser
        } = this.props;

        const data = {
            id,
            verified: !verified1
        };

        verifyUser(data);
    };

    /**
     * @return {Promise<void>}
     */
    onVerify2 = () => {
        const {
            id,
            verified2,
            verifyUser
        } = this.props;


        const data = {
            id,
            verified_1: !verified2
        };

        verifyUser(data);
    };

    onBlock = () => {
        const {
            id,
            blocked,
            blockUser
        } = this.props;

        blockUser(id, blocked);
    };

    render() {
        const {
            onToggle,
            verified1,
            verified2,
            blocked,
            activeTab,
            toggle,
            selectedRow,
            role
        } = this.props;

        const {
            visit1Data,
            visit2Data,
            isLoading
        } = this.state;

        return (
            <Fragment>
                <div className="tableHeader d-flex justify-content-between justify-content-xl-end">
                <div className="d-block d-xl-none">
                        <IconButton
                            icon={"chevron-left"}
                            className={"tableHeader__button"}
                            color={'secondary'}
                            onClick={onToggle}
                            outline
                        />
                    </div>
                {role !== 'ROLES.ADMIN' && <div>
                        <IconButton
                            type={"button"}
                            className={"tableHeader__button"}
                            color={verified1 ? 'success' : 'secondary'}
                            onClick={this.onVerify1}
                            text={'В1'}
                            outline={!verified1}
                        />
                        <IconButton
                            type={"button"}
                            className={"tableHeader__button"}
                            color={verified2 ? 'success' : 'secondary'}
                            onClick={this.onVerify2}
                            text={'В2'}
                            outline={!verified2}
                        />
                        <IconButton
                            icon={blocked ? "lock-open" : "lock"}
                            className={"tableHeader__button"}
                            color={'secondary'}
                            onClick={this.onBlock}
                            outline
                        />
                    </div>
                }
                </div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === '1'})}
                            onClick={() => {
                                toggle('1');
                            }}
                        >
                            Визит 1
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === '2'})}
                            onClick={() => {
                                toggle('2');
                            }}
                        >
                            Визит 2
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="p-3">
                            {activeTab === '1' && <Visit1
                                selected={selectedRow}
                                visitData={visit1Data}
                                isLoading={isLoading}
                            />}
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="p-3">
                            {activeTab === '2' && <Visit2
                                selected={selectedRow}
                                visitData={visit2Data}
                                isLoading={isLoading}
                            />}
                        </div>
                    </TabPane>
                </TabContent>
            </Fragment>
        )
    }
}

const mapStateToProps = (store) => ({
    visit1Data: getStoreData(() => store.visit1.formData),
    visit2Data: getStoreData(() => store.visit2.formData),
    role: getStoreData(() => store.profile.initialData.data[0].role_name)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchVisit1Data,
    fetchVisit2Data,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
