import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const renderInputWithSortButton = ({
                                       input,
                                       mask,
                                       placeholder,
                                       inputRef,
                                       type,
                                       autoFocus = false,
                                       setFieldToBeFocused = () => {
                                       },
                                       meta: {touched, error},
                                       label,
                                       onSort,
                                       name,
                                       sortedName,
                                       isSorted,
                                       style,
                                       isSortable=true,
                                       isFilterable=true,
                                        position=null
                                   }) => (
    <div style={style} className={classnames("form-group", !isFilterable ? "form-group_borderNone" : "", position ? `form-group_${position}` : '')}>
        {label && <label>{label}</label>}
        <InputMask
            {...input}
            mask={mask}
            maskChar={null}
            value={input.value}
            onChange={input.onChange}
            readOnly={!isFilterable}
            type={type}
            placeholder={placeholder}
            autoFocus={autoFocus}
            inputRef={inputRef}
            className={classnames("form-control", touched && error ? 'is-invalid' : '')}
        />
        {isSortable && <div key={isSorted} className="sortIcon">
            {isSorted === 'nosort' &&
            <FontAwesomeIcon color={'#E5E5E5'} icon={"sort"} onClick={() => onSort(input.name)}/>}
            {isSorted !== 'nosort' && <FontAwesomeIcon color={'black'} icon={isSorted ? "sort-up" : "sort-down"}
                                                       onClick={onSort}/>}
        </div>}
        {touched && error && <div className="invalid-feedback">{error}</div>}
    </div>

);

renderInputWithSortButton.propTypes = {
    input: PropTypes.shape().isRequired,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    type: PropTypes.string,
    mask: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
};

renderInputWithSortButton.defaultProps = {
    placeholder: '',
    meta: null,
    type: 'text',
    readOnly: false,
    mask: null
};

export default renderInputWithSortButton;
