import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import classnames from 'classnames';

const renderInputValidationField = ({
                                        input,
                                        mask,
                                        placeholder,
                                        inputRef,
                                        type,
                                        readOnly=false,
                                        autoFocus=false,
                                        setFieldToBeFocused = ()=>{},
                                        meta: { touched, error },
                                        label,
                                        style,
                                        subtext,
                                        className= "",
                                        autoComplete
                                    }) => (
    <div style={style} className="form-group">
        {label && <label >{label}</label>}
        <InputMask
            {...input}
            mask={mask}
            maskChar={null}
            value={input.value}
            onChange={input.onChange}
            readOnly={readOnly}
            type={type}
            placeholder={placeholder}
            autoFocus={autoFocus}
            inputRef={inputRef}
            className={classnames("form-control", touched && error ? 'is-invalid' : '', className)}
            autoComplete={autoComplete}
        />
        {subtext && <small className="form-text text-muted">{ subtext }</small>}
        {touched && error && <div className="invalid-feedback">{error}</div>}
    </div>
);

renderInputValidationField.propTypes = {
    input: PropTypes.shape().isRequired,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    type: PropTypes.string,
    mask: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
};

renderInputValidationField.defaultProps = {
    placeholder: '',
    meta: null,
    type: 'text',
    readOnly: false,
    mask: null
};

export default renderInputValidationField;
