import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux';
import thunk from 'redux-thunk';

import { rootReducer } from './reducers/index';

// import appReducer from 'src/redux/modules/app/reducer';
// import appPrefix from 'src/redux/modules/app/prefix';

const composeEnhancers = typeof window === 'object' &&
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const enhancer = applyMiddleware(thunk);

const store = createStore(
  rootReducer,
  enhancer,
);

export default store;
