import React, {Fragment} from 'react';
import PatientsInfoForm from "./infoForm/PatientsInfoForm";
import TherapyCard from "./TherapyCard";
import DiseasesInfoForm from "./infoForm/DiseasesInfoForm";
import DiseasesCard from "./DiseasesCard";
import IndexInfoForm from "./infoForm/IndexInfoForm";

export const Visit1 = ({onChange, onCheckboxChange, name, onSubmit, data, readOnly = false, id}) => (
    <Fragment>
        <PatientsInfoForm
            name={name}
            _onChange={onChange}
            _onCheckboxChange={onCheckboxChange}
            onSubmit={onSubmit}
            data={data}
            readOnly={readOnly}
        />
        <TherapyCard
            id={id}
            readOnly={readOnly}
        />
        <DiseasesInfoForm
            _onChange={onChange}
            _onCheckboxChange={onCheckboxChange}
            onSubmit={onSubmit}
            data={data}
            readOnly={readOnly}
        />

        <DiseasesCard
            id={id}
            readOnly={readOnly}
        />
        <IndexInfoForm
            _onChange={onChange}
            _onCheckboxChange={onCheckboxChange}
            onSubmit={onSubmit}
            data={data}
            readOnly={readOnly}
        />
    </Fragment>
);
