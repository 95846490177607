import React, {PureComponent} from 'react';
import {getStoreData} from "../../utils/getStoreData";

//components
import SubManagementContainer from '../subManagementContainer/index';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchPatientMRData} from "../../redux/actions/patients/patientsMRAction";

/**
 * Домашняя страница Мед.представителей
 */
class SubUsersMR extends PureComponent {

    render() {
        const {
            fetchPatientMRData,
            patients,
        } = this.props;


        return (
            <SubManagementContainer
                patients={patients}
                fetchAction={fetchPatientMRData}
            />
        )
    }
}

const mapStateToProps = (store) => ({
    patients: getStoreData(() => store.patientsMR.initialData.data),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchPatientMRData,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SubUsersMR);

