import React, {PureComponent} from 'react';
import {AnxietyForm, CGISForm} from "../../../visit1/components/infoForm/DiseasesInfoForm";
import {reduxForm} from "redux-form";
import {getStoreData} from "../../../../utils/getStoreData";
import connect from "react-redux/es/connect/connect";

class DiseasesInfoFrom extends PureComponent {
    static defaultProps = {
        readOnly: false
    };

    onCheckboxChange = () => this.props._onCheckboxChange(this.props.handleSubmit(this.props.onSubmit));

    render() {
        const {
            readOnly
        } = this.props;

        return (
            <form className={"form form_small"}>
                    <AnxietyForm
                        onChange={this.onCheckboxChange}
                        readOnly={readOnly}
                    />
                    <CGISForm
                        onChange={this.onCheckboxChange}
                        readOnly={readOnly}
                    />
            </form>
        )
    }
}

DiseasesInfoFrom = reduxForm({
    form: 'visit_2_diseases_info_form'
})(DiseasesInfoFrom);

const mapStateToProps = (store, props) => ({
    initialValues: {
        NRDIAG: getStoreData(() => props.data.nrdiag),
        HADS1: getStoreData(() => props.data.hads1),
        HADS2: getStoreData(() => props.data.hads2),
        HADS3: getStoreData(() => props.data.hads3),
        HADS4: getStoreData(() => props.data.hads4),
        HADS5: getStoreData(() => props.data.hads5),
        HADS6: getStoreData(() => props.data.hads6),
        HADS7: getStoreData(() => props.data.hads7),
        HADS8: getStoreData(() => props.data.hads8),
        HADS9: getStoreData(() => props.data.hads9),
        HADS10: getStoreData(() => props.data.hads10),
        HADS11: getStoreData(() => props.data.hads11),
        HADS12: getStoreData(() => props.data.hads12),
        HADS13: getStoreData(() => props.data.hads13),
        HADS14: getStoreData(() => props.data.hads14),
        CGIS: getStoreData(() => props.data.cgis),
        A_RES: getStoreData(() => props.data.a_res),
        D_RES: getStoreData(() => props.data.d_res),
    },
    enableReinitialize: true
});

export default connect(mapStateToProps)(DiseasesInfoFrom)
