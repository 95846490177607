import React, {PureComponent} from 'react';
import IconButton from '../../buttons/IconButton';

export default class TablePagination extends PureComponent {
    onChange = (event) => {
        const defaultPage = event.target.value;
        if (Number.isNaN(defaultPage)) return;

        this.props.onPageChange(defaultPage);
    };

    getSafePage(page) {
        if (Number.isNaN(page)) {
            page = this.props.page;
        }

        return Math.min(Math.max(page, 0), this.props.pages);
    }

    changePage(page) {
        page = this.getSafePage(page);
        if (this.props.page === page) return;

        this.props.onPageChange(page);

        this.setState({
            defaultPage: page + 1
        })
    }

    render() {
        const {
            page,
            pages,
            total,
        } = this.props;

        const isFirst = page === 1;
        const isLast = page === pages;

        return (
            <div className="tablePagination align-items-center">
                {total > 0 &&
                <div className="d-flex align-items-center flex-nowrap tablePagination__info" key={page}>
                    <span>Страница</span>
                    <input
                        type={"text"}
                        value={page}
                        className={"tablePagination__control"}
                        onChange={this.onChange}/> из {pages}</div>}
                <div className="tablePagination__buttons">
                    <IconButton
                        outline
                        color={"secondary"}
                        icon={"fast-backward"}
                        onClick={() => this.changePage(1)}
                        disabled={isFirst}
                    />
                    <IconButton
                        outline
                        color={"secondary"}
                        icon={"step-backward"}
                        onClick={() => this.changePage(page - 1)}
                        disabled={isFirst}
                    />
                    <IconButton
                        outline
                        color={"secondary"}
                        icon={"step-forward"}
                        onClick={() => this.changePage(page + 1)}
                        disabled={isLast}
                    />
                    <IconButton
                        outline
                        color={"secondary"}
                        icon={"fast-forward"}
                        onClick={() => this.changePage(pages)}
                        disabled={isLast}
                    />
                </div>
            </div>
        );
    }
}
