import React, { PureComponent } from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import propTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {returnBootstrapColor} from "../../../utils/returnBootstrapColor";

class ModalForm extends PureComponent {
    static propTypes = {
        isOpen: propTypes.bool.isRequired,
        toggle: propTypes.func.isRequired,
        submit: propTypes.func.isRequired,
        title: propTypes.string,
        submitText: propTypes.string,
        closeText: propTypes.string,
        color: propTypes.string,
        icon: propTypes.string,
    };

    static defaultProps = {
        title: '',
        submitText: 'Создать',
        closeText: 'Отмена',
        color: 'success',
        icon: null
    };

    onSubmit = async (values) => {
        await this.props.onSubmit(values);
        this.props.toggle();
    };

    render() {
        const {
            isOpen,
            toggle,
            className,
            title,
            submitText,
            closeText,
            children,
            handleSubmit,
            color,
            icon,
        } = this.props;

        return (
            <Modal color={color} isOpen={isOpen} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>
                    {icon &&
                        <span className="mr-2" style={{'fontSize': 30}}>
                            <FontAwesomeIcon icon={icon} color={returnBootstrapColor(color)}/>
                        </span>
                    }
                    {title}
                    </ModalHeader>
                <form onSubmit={handleSubmit(this.onSubmit)}>
                    <ModalBody>
                        {children}
                    </ModalBody>
                    <ModalFooter>
                        <Button type={"button"}  color="secondary" onClick={toggle}>{closeText}</Button>{' '}
                        <Button type={"submit"} color={color}>{submitText}</Button>
                    </ModalFooter>
                </form>
            </Modal>
        )
    }
}

ModalForm = reduxForm({
    destroyOnUnmount: false,
})(ModalForm);

const mapStateToProps = (store, props) => ({
    form: props.form,
    initialValues: props.initialValues,
    enableReinitialize: true
});

export default connect(mapStateToProps)(ModalForm);
