import React, {PureComponent} from 'react';
import {Col, Row, Table} from "reactstrap";
import {Field, reduxForm} from "redux-form";
import renderRadio from "../../../_commonComponents/form/RadioGroupWithValidation";
import {connect} from 'react-redux';
import {getStoreData} from "../../../../utils/getStoreData";

class IndexInfoForm extends PureComponent {
    static defaultProps = {
        readOnly: false
    };

    onChange = () => this.props._onChange(this.props.handleSubmit(this.props.onSubmit));
    onCheckboxChange = () => this.props._onCheckboxChange(this.props.handleSubmit(this.props.onSubmit));

    render() {
        const {
            readOnly
        } = this.props;

        return (
            <form className={"form form_small"}>
                <Row>
                    <Col xs={12}>
                        <div className="visitContainer__header visitContainer__header_success mb-0">
                            <h5>Индекс коморбидности Чарлсона</h5>
                        </div>
                    </Col>
                    <Col className="mb-4" xs={12}>
                        <Table className={"m-0"} bordered>
                            <tbody>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI1"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "CHI1_1"},
                                                {value: 0, label: "Нет", id: "CHI1_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Инфаркт миокарда</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI2"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "CHI2_1"},
                                                {value: 0, label: "Нет", id: "CHI2_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Застойная сердечная недостаточность</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI3"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "CHI3_1"},
                                                {value: 0, label: "Нет", id: "CHI3_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Болезнь периферических артерий</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI4"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "CHI4_1"},
                                                {value: 0, label: "Нет", id: "CHI4_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Цереброваскулярное заболевание</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI5"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "CHI5_1"},
                                                {value: 0, label: "Нет", id: "CHI5_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Деменция</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI6"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "CHI6_1"},
                                                {value: 0, label: "Нет", id: "CHI6_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Хроническое заболевание легких</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI7"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "CHI7_1"},
                                                {value: 0, label: "Нет", id: "CHI7_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Болезнь соединительной ткани</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI8"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "CHI8_1"},
                                                {value: 0, label: "Нет", id: "CHI8_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Язвенная болезнь</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI9"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "CHI9_1"},
                                                {value: 0, label: "Нет", id: "CHI9_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Легкое поражение печени</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI10"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "CHI10_1"},
                                                {value: 0, label: "Нет", id: "CHI10_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Диабет</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI11"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 2, label: "Да", id: "CHI11_1"},
                                                {value: 0, label: "Нет", id: "CHI11_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Гемиплегия</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI12"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 2, label: "Да", id: "CHI12_1"},
                                                {value: 0, label: "Нет", id: "CHI12_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Умеренная или тяжелая болезнь почек</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI13"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 2, label: "Да", id: "CHI13_1"},
                                                {value: 0, label: "Нет", id: "CHI13_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Диабет с поражением органов</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI14"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 2, label: "Да", id: "CHI14_1"},
                                                {value: 0, label: "Нет", id: "CHI14_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Злокачественная опухоль без метастазов</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI15"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 2, label: "Да", id: "CHI15_1"},
                                                {value: 0, label: "Нет", id: "CHI15_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Лейкемия</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI16"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 2, label: "Да", id: "CHI16_1"},
                                                {value: 0, label: "Нет", id: "CHI16_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Лимфомы</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI17"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 3, label: "Да", id: "CHI17_1"},
                                                {value: 0, label: "Нет", id: "CHI17_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Умеренное или тяжелое поражение печени</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI18"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 6, label: "Да", id: "CHI18_1"},
                                                {value: 0, label: "Нет", id: "CHI18_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Метастазирующие злокачественные опухоли</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CHI19"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 6, label: "Да", id: "CHI19_1"},
                                                {value: 0, label: "Нет", id: "CHI19_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>СПИД (болезнь, а не только виремия)</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </form>
        )
    }
}

IndexInfoForm = reduxForm({
    form: 'visit1_index_info_form'
})(IndexInfoForm);

const mapStateToProps = (store, props) => ({
    initialValues: {
        CHI1: getStoreData(() => props.data.chi1),
        CHI2: getStoreData(() => props.data.chi2),
        CHI3: getStoreData(() => props.data.chi3),
        CHI4: getStoreData(() => props.data.chi4),
        CHI5: getStoreData(() => props.data.chi5),
        CHI6: getStoreData(() => props.data.chi6),
        CHI7: getStoreData(() => props.data.chi7),
        CHI8: getStoreData(() => props.data.chi8),
        CHI9: getStoreData(() => props.data.chi9),
        CHI10: getStoreData(() => props.data.chi10),
        CHI11: getStoreData(() => props.data.chi11),
        CHI12: getStoreData(() => props.data.chi12),
        CHI13: getStoreData(() => props.data.chi13),
        CHI14: getStoreData(() => props.data.chi14),
        CHI15: getStoreData(() => props.data.chi15),
        CHI16: getStoreData(() => props.data.chi16),
        CHI17: getStoreData(() => props.data.chi17),
        CHI18: getStoreData(() => props.data.chi18),
        CHI19: getStoreData(() => props.data.chi19),
    },
    enableReinitialize: true
});

export default connect (mapStateToProps)(IndexInfoForm);
