import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Label, CustomInput} from 'reactstrap';

const RadioGroupWithValidation = ({input ,name, options, className="", disabled, label, readOnly}) => (
    <FormGroup className={"m-0"}>
        {label && <Label for={name}>{label}</Label>}
            <div className={className}>
                {options && options.map(item =>
                    <CustomInput key={item.id} {...input} type="radio" value={item.value} id={item.id} name={`${name}_${item.id}`} label={item.label} disabled={readOnly} checked={`${input.value}` == `${item.value}`}/>)
                }
            </div>
        </FormGroup>
);

RadioGroupWithValidation.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]).isRequired,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  radioValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  spanClass: PropTypes.string,
  id:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};

RadioGroupWithValidation.defaultProps = {
  label: '',
  defaultChecked: false,
  radioValue: '',
  disabled: false,
  className: '',
};

const renderRadioGroupWithValidation = props => (
    <div>
        <RadioGroupWithValidation
            {...props.input}
            label={props.label}
            input={props.input}
            className={props.className}
            onChange={props.input.onChange}
            options={props.options}
            id={props.id}
            readOnly={props.readOnly}
        />
        {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
    </div>
);

renderRadioGroupWithValidation.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  radioValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  className: PropTypes.string,
  spanClass: PropTypes.string,
  id:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

renderRadioGroupWithValidation.defaultProps = {
  label: '',
  defaultChecked: false,
  disabled: false,
  radioValue: '',
  className: '',
  id: ''
};

export default renderRadioGroupWithValidation;
