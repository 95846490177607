import React, {PureComponent} from 'react';
import {Button, Col, Container, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import debounce from 'debounce';
import classnames from "classnames";

//utils
import {getStoreData} from "../../utils/getStoreData";

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchVisit2Data} from "../../redux/actions/visit2/visit2Action";
import {fetchPatientData} from "../../redux/actions/patients/patientsAction";

//components
import IconButton from "../_commonComponents/buttons/IconButton";
import {Visit2} from './components/Visit2';

//constants
import {GET, SET, SUB_VISIT2_OBJECT} from "../../config/requestConstants";
import {INV_SUB_PATH} from "../../config/baseConstants";
import Loading from "../_commonComponents/Loading";

const SCROLL_LIMIT = 300;

const Title = React.forwardRef((props, ref)=>{
    return (
        <section ref={ref} />
    );
});

class Visit2Form extends PureComponent {
    static _onChange(callback) {
        callback();
    }

    static _onCheckboxChange = (callback) => {
        callback();
    };

    constructor(props) {
        super(props);

        this.state = {
            formId: null,
            formData: {},
            windowTopPosition: 0,
            isLoading: false
        };

        this.scrollRef = React.createRef();

        Visit2Form._onChange = debounce(Visit2Form._onChange, 600);
        Visit2Form._onCheckboxChange = debounce(Visit2Form._onCheckboxChange, 50);
    }

    componentDidMount() {
        this.getInitialData();
        this.getWindowTopPosition();
        window.addEventListener('scroll', this.getWindowTopPosition);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.getWindowTopPosition);
    }

    getWindowTopPosition = () => {
        const windowTopPosition = window.pageYOffset;

        this.setState({
            windowTopPosition
        });
    };

    getInitialData = async() => {
        const {
            fetchVisit2Data
        } = this.props;

        const id = getStoreData(() => this.props.match.params.id);
        const filter =  [{name: "sub", value: id}];

        this.setState({
            isLoading: true
        });

        await fetchVisit2Data(null, filter, {}, true);
        this.setState({
            formId: getStoreData(() => this.props.formData.id),
            formData: getStoreData(() => this.props.formData),
            isLoading: false
        }, () => {
            this.getPatientData();
        });
    };

    getPatientData = () => {
        const {
            fetchPatientData
        } =this.props;

        const id = getStoreData(() => this.props.match.params.id);
        const filter = [{name: "id", value: id}];

        fetchPatientData(GET, filter, null, null, null, {}, false)
    };

    onSubmit = async(values) => {
        const {
            fetchVisit2Data,
        } =this.props;

        const {
            formId
        } = this.state;

        const data={...values, ...{id: formId}};
        await fetchVisit2Data(SET, [], data, false, SUB_VISIT2_OBJECT);
        this.setState({
            formData: getStoreData(() => this.props.formData)
        })
    };

    goToPrevPage = () => this.props.history.push({pathname: INV_SUB_PATH, state: {row: this.props.rowData}});

    scrollToContent = () => {
        this.scrollRef.current.scrollIntoView({behavior: 'smooth'});
    };

    render() {
        const {
            name,
            status
        } = this.props;

        const {
            formData,
            windowTopPosition,
            isLoading
        } = this.state;

        return (
            <Row className={"patients"}>
                {isLoading && <Loading className="position-absolute"/>}
                <Title ref={this.scrollRef}/>
                <Col xs={12}>
                    <div className="tableHeader d-flex justify-content-between">
                        <div className="d-flex justify-content-start align-items-center">
                            <div className={"tableHeader__icon"}>
                                <FontAwesomeIcon icon={"clipboard-list"}/>
                            </div>
                            <h3 className="tableHeader__title mb-0 ml-2">Визит 2</h3>
                        </div>
                        <div>
                            <IconButton
                                icon={"users"}
                                className={"tableHeader__button"}
                                color={'secondary'}
                                onClick={this.goToPrevPage}
                                outline
                            />
                        </div>
                    </div>
                </Col>
                <Col xs={12} className={"py-4"}>
                    <Container className="visitContainer">
                        <Visit2
                            id={getStoreData(() => this.props.match.params.id)}
                            name={name}
                            onChange={Visit2Form._onChange}
                            onCheckboxChange={Visit2Form._onCheckboxChange}
                            onSubmit={this.onSubmit}
                            data={formData}
                            readOnly={status === 4}
                        />
                    </Container>
                </Col>
                <div className="d-block d-xl-none">
                    <Button
                        color="primary"
                        className={classnames('btn-scroll p-3', windowTopPosition > SCROLL_LIMIT ? 'btn-scroll_show' : '')}
                        onClick={this.scrollToContent}
                    >
                        <FontAwesomeIcon color="white" icon="chevron-up"/>
                    </Button>
                </div>
            </Row>
        )
    }
}

const mapStateToProps = (store) => ({
    status: getStoreData(() => store.patients.formData[0].status_visit_2),
    name: getStoreData(() => store.patients.formData[0].name),
    rowData: getStoreData(() => store.patients.formData[0]),
    formData: getStoreData(() => store.visit2.formData),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchVisit2Data,
    fetchPatientData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Visit2Form);
