import React, {PureComponent} from 'react';
import {Col, Row} from 'reactstrap';

//utils
import {getStoreData} from "../../utils/getStoreData";

//redux
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {fetchUsersData} from "../../redux/actions/management/usersAction";
import {reset} from 'redux-form';
import {selectRow} from "../../redux/actions/selectedRowAction";
import {fetchResetPassData} from "../../redux/actions/resetPassAction";

//components
import {FormContent} from './components/UsersForm';
import Table from "../_commonComponents/table/Table";
import Form from "../_commonComponents/table/Form";
import ModalForm from "../_commonComponents/form/ModalForm";

//constants
import {
    BASE_INV_FILTER,
    SM_SCREEN_WIDTH,
    LG_SCREEN_WIDTH,
    INV_USERS_FILTER,
    ADD_INV_ADMIN_FORM,
    EDIT_INV_ADMIN_FORM
} from "../../config/baseConstants";
import {ADD, DEL, SET, USERS_ADD_FUNCTION, ROLE_INV} from "../../config/requestConstants";


/**
 * Панель управления пользователями с ролью "Исследователь"
 */
class UsersInv extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tableKey: false,
            order: null,
            formData: null,
            isMobile: false,
            isFormVisible: false,
            isActionVisible: false,
            isModalOpen: false,
        }
    }

    componentDidMount() {
        this.isMobile();
        window.addEventListener('resize', this.isMobile);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.isMobile);
    }

    isMobile = () => {
        const isMobile = window.innerWidth < SM_SCREEN_WIDTH;
        const isActionVisible = window.innerWidth < LG_SCREEN_WIDTH;
        const isFormVisible = window.innerWidth > LG_SCREEN_WIDTH;

        this.setState({
            isMobile,
            isFormVisible,
            isActionVisible
        });
    };

    /**
     * @param formData {Object}
     */
    getRowData = (formData) => {
        const {
            isActionVisible,
        } = this.state;

        const {
            selectRow
        } = this.props;

        const isFormVisible = isActionVisible ? !this.state.isFormVisible : false;
        this.setState({
            formData: formData.original,
            isFormVisible
        }, () => selectRow(formData.original))
    };

    onCloseForm = () => {
        this.setState(prevState => ({
            isFormVisible: !prevState.isFormVisible
        }))
    };

    /**
     * @return {Promise<void>}
     */
    onDelete = async () => {
        const {
            fetchUsersData,
            selectRow
        } = this.props;

        const {
            formData
        } = this.state;

        const requestData = {
            id: getStoreData(() => formData.id)
        };

        await fetchUsersData(DEL, [], null, null, null, requestData, false);
        selectRow(getStoreData(() => this.props.tableData[0]));
        this.setState(prevState => ({
            tableKey: !prevState.tableKey,
            formData: getStoreData(() => this.props.tableData[0]),
        }))
    };

    modalToggle = () => {
        const {
            reset
        } = this.props;

        this.setState(prevState => ({
            isModalOpen: !prevState.isModalOpen
        }), () => {
            reset(ADD_INV_ADMIN_FORM)
        })
    };

    /**
     * @param values {Object}
     * @return {Promise<void>}
     */
    onSubmit = async (values) => {
        const {
            fetchUsersData,
            selectRow
        } = this.props;

        const isNew = !values.id && !values.vers;
        const action = isNew ? ADD : SET;

        const data = isNew ? {...values, ...{role: ROLE_INV}} : values;
        const func = isNew ? USERS_ADD_FUNCTION : null;

        await fetchUsersData(action, [], null, null, null, data, false, func);
        selectRow(this.props.formData);

        this.setState(prevState => ({
            tableKey: !prevState.tableKey,
            formData: this.props.formData
        }))
    };

    onReset = () => {
        const {
            selectRow
        } = this.props;

        this.setState({
            formData: getStoreData(() => this.props.tableData[0]),
        }, () => selectRow(this.props.tableData[0]))
    };

    resetPass = () => {
        const {
            selectedRow,
            fetchResetPassData
        } = this.props;

        const data = {
            phone: selectedRow && selectedRow.phone
        };

        fetchResetPassData(data);
    };

    get selectedRow() {
        const {
            selectedRow,
            tableData
        } = this.props;

        return selectedRow ? selectedRow : getStoreData(() => tableData[0])
    }

    render() {
        const {
            total,
            fetchUsersData,
            tableData,
            selectRow
        } = this.props;

        const {
            isMobile,
            isFormVisible,
            isActionVisible,
            formData,
            tableKey,
            isModalOpen
        } = this.state;

        const columns = [
            {
                ...!isMobile && {
                    Header: '',
                    accessor: 'name',
                    id: 'name',
                    filterable: false,
                    sortable: false,
                    isFilterable: true,
                    isSortable: true,
                    isVisible: !isMobile,
                    placeholder: 'Id',
                    width: isMobile ? '100%' : 144,
                    maxWidth: isMobile ? '100%' : 144,
                }
            },
            {
                Header: '',
                accessor: 'title',
                id: 'title',
                filterable: false,
                sortable: false,
                isFilterable: true,
                isSortable: true,
                isVisible: true,
                placeholder: 'Имя'
            },
            {
                Header: '',
                accessor: 'phone_view',
                id: 'phone_search',
                filterable: false,
                sortable: false,
                isFilterable: true,
                isSortable: true,
                isVisible: true,
                placeholder: 'Телефон',
                width: isMobile ? 125 : 200,
                maxWidth: isMobile ? 125 : 200,
            },
            {
                ...!isMobile && {
                    Header: '',
                    accessor: 'dsc',
                    id: 'dsc',
                    filterable: false,
                    sortable: false,
                    isFilterable: true,
                    isSortable: true,
                    isVisible: !isMobile,
                    placeholder: 'Город',
                    width: isMobile ? 125 : 200,
                    maxWidth: isMobile ? 125 : 200,
                }
            }
        ].filter(el => Object.keys(el).length > 0);

        const isTableShow = isActionVisible ? !isFormVisible : true;
        const isFormShow = isActionVisible ? isFormVisible : true;

        return (
            <Row className="wrapper">
                {isTableShow
                && <Col xs={12} xl={6} className="wrapper__column wrapper__column_border pr-xl-0">
                    <Table
                        columns={columns}
                        headerTitle="Исследователи"
                        headerIcon={"user-md"}
                        onToggle={this.modalToggle}
                        fetchAction={fetchUsersData}
                        tableData={tableData}
                        filterForm={INV_USERS_FILTER}
                        total={total}
                        selected={this.selectedRow}
                        initFilter={BASE_INV_FILTER}
                        onRowClick={this.getRowData}
                        tableKey={tableKey}
                        onReset={this.onReset}
                        count={total}
                        selectRow={selectRow}
                    />
                </Col>
                }
                {isFormShow
                && <Col xs={12} xl={6} className="wrapper__column pl-xl-0">
                    <Form
                        onToggle={this.onCloseForm}
                        onSubmit={this.onSubmit}
                        onDelete={this.onDelete}
                        form={EDIT_INV_ADMIN_FORM}
                        resetPass={this.resetPass}
                        initialValues={{
                            id: getStoreData(() => this.selectedRow.id),
                            vers: getStoreData(() => this.selectedRow.vers),
                            name: getStoreData(() => this.selectedRow.name),
                            title: getStoreData(() => this.selectedRow.title),
                            phone: getStoreData(() => this.selectedRow.phone),
                            dsc: getStoreData(() => this.selectedRow.dsc),
                            phone_send: getStoreData(() => this.selectedRow.phone_send),
                            test: getStoreData(() => this.selectedRow.test),
                            rejection: getStoreData(() => this.selectedRow.rejection),
                        }
                        }>
                        <FormContent id={getStoreData(() => this.selectedRow.id)}/>
                    </Form>
                </Col>
                }
                <ModalForm
                    isOpen={isModalOpen}
                    toggle={this.modalToggle}
                    onSubmit={this.onSubmit}
                    form={ADD_INV_ADMIN_FORM}
                    submitText={"Создать пользователя"}
                    title={"Новый пользователь"}
                    icon={"user-plus"}
                >
                    <FormContent
                        isNew
                    />
                </ModalForm>
            </Row>
        )
    }
}

const mapStateToProps = (store) => ({
    tableData: getStoreData(() => store.adminUsr.initialData.data),
    formData: getStoreData(() => store.adminUsr.formData.data),
    total: getStoreData(() => store.adminUsr.initialData.total),
    selectedRow: store.selectedRow.row,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUsersData,
    selectRow,
    reset,
    fetchResetPassData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UsersInv);
