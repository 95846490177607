import { combineReducers } from 'redux';
import { loginReducer } from "./loginReducer";
import { usersReducer } from './management/usersReducer';
import {linkedUsersReducer} from "./management/linkedUsersReducer";
import {patientsReducer} from "./patients/patientsReducer";
import {visit1Reducer} from "./visit1/visit1Reducer";
import {visit1TherapyReducer} from "./visit1/visit1TherapyReducer";
import {visit1DiseasesReducer} from "./visit1/visit1DiseasesReducer";
import {visit2Reducer} from "./visit2/visit2Reducer";
import {visit2TherapyReducer} from "./visit2/visit2TherapyReducer";
import {visit2EventsReducer} from "./visit2/visit2EventsReducer";
import {patientsMRReducer} from './patients/patientsMRReducer';
import {resetPassReducer} from "./resetPassReducer";
import {selectedRowReducer} from "./selectedRowReducer";
import { reducer as reduxFormReducer } from 'redux-form';

export const rootReducer = combineReducers({
    form: reduxFormReducer,
    profile: loginReducer,
    resetPass: resetPassReducer,
    adminUsr: usersReducer,
    linkedUsr: linkedUsersReducer,
    patients: patientsReducer,
    patientsMR: patientsMRReducer,
    visit1: visit1Reducer,
    visit1Therapy: visit1TherapyReducer,
    visit1Diseases: visit1DiseasesReducer,
    visit2: visit2Reducer,
    visit2Therapy: visit2TherapyReducer,
    visit2Events: visit2EventsReducer,
    selectedRow: selectedRowReducer,
});
