import {ROW_IS_SELECTED} from "../actions/selectedRowAction";

const initialState = {
    row: null
};

export function selectedRowReducer(state = initialState, action) {
    switch (action.type) {
        case ROW_IS_SELECTED:
            return {...state, row: action.payload};
        default:
            return state;
    }
}
