import 'cross-fetch/polyfill';
import {UrlCreator, formDataCreator} from '../../../utils/requestCreator';
import {getStoreData} from "../../../utils/getStoreData";
import {USR_OBJECT, GET} from "../../../config/requestConstants";
import showNotification  from "../../../containers/_commonComponents/notification/showErrorNotification";

export const USERS_DATA_GET = 'USER_DATA_GET';
export const USERS_DATA_LOADING = 'USER_DATA_LOADING';
export const USERS_DATA_EDITED = 'USERS_DATA_EDITED';
export const USERS_DATA_ERROR = 'USERS_DATA_ERROR';

function dataIsLoading() {
    return {
        type: USERS_DATA_LOADING
    }
}

function dataIsFailed() {
    return {
        type: USERS_DATA_ERROR
    }
}

function dataIsEdited(data) {
    return {
        type: USERS_DATA_EDITED,
        payload: data
    }
}

function dataIsGet(data) {
    return {
        type: USERS_DATA_GET,
        payload: data
    }
}

export function fetchUsersData(action = GET, filter = [], limit = 100, offset = null, order= null, data={}, isInitial=true, functionName) {
    return (dispatch, getState) => {
        const key = getStoreData(() => getState().profile.key);
        const URL = UrlCreator();
        const requestData = formDataCreator(action, USR_OBJECT, data, key, filter, limit, offset, order, functionName);

        dispatch(dataIsLoading());
        return fetch(URL, {
            method: 'POST',
            body: requestData
        })
            .then((response) => {
                if (response.status !== 200) {
                    dispatch(dataIsFailed());
                    throw Error(response.statusText);
                }

                dispatch(dataIsLoading());
                return response.json();
            })
            .then((data) => {
                // eslint-disable-next-line
                if (data.status != 0) {
                    showNotification(data);
                    dispatch(dataIsFailed());
                    return;
                }

                isInitial
                    ? dispatch(dataIsGet(data))
                    : dispatch(dataIsEdited(data))
            })
            .catch(e => console.error(e))
    }
}
