import 'cross-fetch/polyfill';
import {UrlCreator, formDataCreator} from '../../utils/requestCreator';
import {SERVICE, LOGIN_OBJECT, PROFILE_OBJECT} from "../../config/requestConstants";
import {getStoreData} from "../../utils/getStoreData";

export const dataIsLoading = 'dataIsLoading';
export const dataIsFailed = 'dataIsFailed';
export const dataIsInitial = 'dataIsInitial';


function isLoading() {
    return {
        type: dataIsLoading
    }
}

function isFailed() {
    return {
        type: dataIsFailed
    }
}

function isInitial(data, key) {
    return {
        type: dataIsInitial,
        payload: data,
        key
    }
}

export function fetchLoginData(data={}, isLogin = false) {
    const URL = UrlCreator(SERVICE);
    return (dispatch, getState) => {
        let initialKey = localStorage.token;
        const object = isLogin ? LOGIN_OBJECT: PROFILE_OBJECT;
        const requestData = formDataCreator(null, object, data, initialKey);
        dispatch(isLoading());
        return fetch(
            URL, {
                method: 'POST',
                body: requestData,
            }
        ).then((response) => {
            if (response.status !== 200) {
                localStorage.clear();
                dispatch(isFailed());
                throw Error(response.statusText);
            }
            dispatch(isLoading());
            return response.json();
        }).then((data) => {

            if (data.status !== "0") {
                dispatch(isFailed());
                return;
            }

            const key =  getStoreData(() => data.skey);
            localStorage.token = key;
            dispatch(isInitial(data, key));
        }).catch((e) => {
            dispatch(isFailed());
            console.error(e);
        })
    };
}
