import React, {PureComponent} from 'react';
import {Row, Col} from "reactstrap";
import {getStoreData} from "../../utils/getStoreData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classnames from 'classnames';
import propTypes from 'prop-types';

//utils
import {getVisitCellClass} from "../../utils/getVisitCellClass";

//component
import Table from '../_commonComponents/table/Table';
import Tabs from './components/Tabs';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchPatientData} from "../../redux/actions/patients/patientsAction";
import {selectRow} from "../../redux/actions/selectedRowAction";

//constants
import {LG_SCREEN_WIDTH, SM_SCREEN_WIDTH, WIDE_DESK, USERS_SUB_ADMIN_FILTER} from "../../config/baseConstants";
import {SET} from '../../config/requestConstants';
import Loading from "../_commonComponents/Loading";

/**
 * Общий компонент для отображения информации о пациентах
 */
class SubManagementContainer extends PureComponent {
    static propTypes = {
        fetchAction: propTypes.func.isRequired,
        patients: propTypes.array,
    };

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            isActionVisible: false,
            isFormVisible: true,
            selectedRow: null,
            tableKey: false,
            verified: false,
            activeTab: '1',
        }
    }

    componentDidMount() {
        this._isMounted = true;

        this.isMobile();
        window.addEventListener('resize', this.isMobile);
    }

/*    componentDidUpdate() {
        try {
            this.setInitialSelectedRow();
        } catch (e) {
            console.error(e);
        }
    }*/

    componentWillUnmount() {
        window.removeEventListener('resize', this.isMobile);
    }

    isMobile = () => {
        const isMobile = window.innerWidth < SM_SCREEN_WIDTH;
        const isWideDesk = window.innerWidth > WIDE_DESK;

        const isActionVisible = window.innerWidth < LG_SCREEN_WIDTH;
        const isFormVisible = window.innerWidth > LG_SCREEN_WIDTH;

        this.setState({
            isMobile,
            isActionVisible,
            isFormVisible,
            isWideDesk
        });
    };

    /**
     * @param info {Object}
     * @return {Promise<void>}
     */
    getFormData = async (info) => {
        const {
            isActionVisible
        } = this.state;

        const isFormVisible = isActionVisible ? !this.state.isFormVisible : false;

        this.getInitialData(info.original);

        this.setState({
            isFormVisible
        })
    };

    /**
     * @param data {Object}
     */
    getInitialData = async (data) => {
        const {
            selectRow
        } = this.props;

        this.setState({
            selectedRow: data,
        }, () => {
            selectRow(data);
        })
    };

    /**
     * @param tab {String}
     */
    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
        }
    };

    /**
     * @param info {Object}
     * @param status {Number}
     * @return {*}
     */
    renderVisitCell = (info, status) => (
        <div key={info.id} className="d-flex justify-content-center">
            <FontAwesomeIcon className={classnames("patients__file", getVisitCellClass(status))} icon="file-alt"/>
        </div>
    );

    onCloseForm = () => {
        this.setState(prevState => ({
            isFormVisible: !prevState.isFormVisible
        }))
    };

    /**
     * @param data {Object}
     * @return {Promise<void>}
     */
    verifyUser = async (data) => {
        const {
            fetchPatientData,
        } = this.props;



        await fetchPatientData(SET, [], null, null, null, data, false);

        this.setState(prevState => ({
            selectedRow: this.props.verifiedRow,
            tableKey: !prevState.tableKey
        }))
    };

    /**
     * @param id {Number}
     * @param blocked {Boolean}
     * @return {Promise<void>}
     */
    blockUser = async (id, blocked) => {
        const {
            fetchPatientData,
        } = this.props;

        const data = {
            id,
            blocked: !blocked
        };

        await fetchPatientData(SET, [], null, null, null, data, false);

        this.setState(prevState => ({
            selectedRow: this.props.verifiedRow,
            tableKey: !prevState.tableKey
        }))
    };

    onReset = () => {
        this.getInitialData(getStoreData(() => this.props.patients[0]));
    };

    get columns() {
        const {
            isMobile
        } = this.state;

        return [
            {
                Header: "",
                accessor: 'name',
                id: 'name',
                filterable: false,
                sortable: false,
                isFilterable: true,
                isSortable: true,
                isVisible: true,
                placeholder: 'Пациент',
                width: isMobile ? '100%' : 110,
                maxWidth: 110,
            },
            {
                ...!isMobile && {
                    Header: "",
                    accessor: 'inv_name',
                    id: 'inv_name',
                    filterable: false,
                    sortable: false,
                    isFilterable: true,
                    isSortable: true,
                    isVisible: !isMobile,
                    placeholder: 'Id Исследователя',
                    width: 167,
                    maxWidth: 167,
                }
            },
            {
                ...!isMobile && {
                    Header: "",
                    accessor: 'inv_title',
                    id: 'inv_title',
                    filterable: false,
                    sortable: false,
                    isFilterable: true,
                    isSortable: true,
                    isVisible: !isMobile,
                    placeholder: 'ФИО исследователя',
                }
            },
            {
                Header: '',
                accessor: 'status_visit_1',
                id: 'status_visit_1',
                Cell: (info) => this.renderVisitCell(info, getStoreData(() => info.original.status_visit_1)),
                filterable: false,
                sortable: false,
                isFilterable: false,
                isSortable: false,
                isVisible: true,
                placeholder: 'В1',
                width: 60,
                maxWidth: 60,
                position: 'center'
            },
            {
                Header: '',
                accessor: 'status_visit_2',
                id: 'status_visit_2',
                Cell: (info) => this.renderVisitCell(info, getStoreData(() => info.original.status_visit_2)),
                filterable: false,
                sortable: false,
                isFilterable: false,
                isSortable: false,
                isVisible: true,
                placeholder: 'В2',
                width: 60,
                maxWidth: 60,
                position: 'center'
            }
        ].filter(el => Object.keys(el).length > 0);
    }

    get selectedRow() {
        const {
            selectedRow,
            tableData
        } = this.props;

        return selectedRow ? selectedRow : getStoreData(() => tableData[0])
    }

    render() {
        const {
            fetchAction,
            patients,
            total,
            selectRow,
            isFilterIconVisible
        } = this.props;

        const {
            isActionVisible,
            isFormVisible,
            selectedRow,
            tableKey,
            activeTab,
            isLoading
        } = this.state;


        const isTableShow = isActionVisible ? !isFormVisible : true;
        const isFormShow = isActionVisible ? isFormVisible : true;
        const selectedFormData = selectedRow ? selectedRow : (patients ? patients[0] : null);

        return (
            <Row className="wrapper wrapper_scroll">
                {
                    isTableShow
                    && <Col xs={12} xl={6} className="wrapper__column wrapper__column_border pr-xl-0">
                        {isLoading && <Loading className="position-absolute"/>}
                        <Table
                            fetchAction={fetchAction}
                            isFilterIconVisible={isFilterIconVisible}
                            headerTitle={"Пациенты"}
                            headerIcon={"sad-tear"}
                            tableKey={tableKey}
                            prefix={"far"}
                            initFilter={[]}
                            columns={this.columns}
                            tableData={patients}
                            total={total}
                            selected={this.selectedRow}
                            onRowClick={this.getFormData}
                            filterForm={USERS_SUB_ADMIN_FILTER}
                            isAddIconVisible={false}
                            onReset={this.onReset}
                            selectRow={selectRow}
                        />
                    </Col>
                }
                {
                    isFormShow
                    && <Col xs={12} xl={6} className="pl-xl-0">
                        {patients && patients.length > 0
                            ? <Tabs
                                id={getStoreData(() => this.selectedRow.id)}
                                verified1={getStoreData(() => this.selectedRow.verified)}
                                verified2={getStoreData(() => this.selectedRow.verified_1)}
                                blocked={getStoreData(() => this.selectedRow.blocked)}
                                onToggle={this.onCloseForm}
                                verifyUser={this.verifyUser}
                                blockUser={this.blockUser}
                                activeTab={activeTab}
                                toggle={this.toggle}
                                selectedRow={this.selectedRow}
                            />
                            : <div className="p-3">Нет пациентов</div>
                        }
                    </Col>
                }
            </Row>
        )
    }
}

const mapStateToProps = (store) => ({
    //patients: getStoreData(() => store.patientsMR.initialData.data),
    isLoading: getStoreData(() => store.patientsMR.isLoading),
    total: getStoreData(() => store.patientsMR.initialData.total),
    rowData: getStoreData(() => store.patientsMR.formData),
    verifiedRow: getStoreData(() => store.patients.formData),
    selectedRow: store.selectedRow.row,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchPatientData,
    selectRow
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SubManagementContainer);

