import React, {PureComponent} from 'react';
import {getStoreData} from "../../utils/getStoreData";

//components
import SubManagementContainer from '../subManagementContainer/index';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchPatientData} from "../../redux/actions/patients/patientsAction";


/**
 * Форма просмотра карт пациентов для администратора.
 */
class SubUsers extends PureComponent {
    render() {
        const {
            fetchPatientData,
            patients,
        } = this.props;

        return (
            <SubManagementContainer
                patients={patients}
                fetchAction={fetchPatientData}
                isFilterIconVisible
            />
        )
    }
}

const mapStateToProps = (store) => ({
    patients: getStoreData(() => store.patients.initialData.data),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchPatientData,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SubUsers);

