import {
    dataIsLoading,
    dataIsFailed,
    dataIsInitial
} from "../actions/loginAction";

const initialState = {
   isLoading: true,
    isError: false,
    initialData: null,
    key: null,
    isAuthenticated: false
};

export function loginReducer(state = initialState, action) {
    switch (action.type) {
        case dataIsLoading:
            return {...state, isLoading: true, isError: false, isAuthenticated: false};
        case dataIsFailed:
            return {...state, isLoading: false, isError: true, isAuthenticated: false};
        case dataIsInitial:
            return {...state, isLoading: false, isError: false, initialData: action.payload, key: action.key, isAuthenticated: true};
        default:
            return state;
    }
}
