//router
export const LOGIN = '/login';
export const ADMIN_PATH = '/admin';
export const USERS_INV_PATH = '/users_inv';
export const INV_SUB_PATH = '/inv/subs';
export const MP_PATH = '/users_mp';
export const ADMIN_USERS_PATH = '/users_admin';
export const SUB_USER_PATH = '/users_sub';

export const LIMIT = 100;
export const SM_SCREEN_WIDTH = 767;
export const MD_SCREEN_WIDTH = 992;
export const LG_SCREEN_WIDTH = 1200;
export const WIDE_DESK = 1600;

export const BASE_ADMIN_FILTER =  [{name: "role_name", value:"ROLES.ADMIN"}];
export const BASE_INV_FILTER  = [{name:"role_name",  value:"ROLES.INV"}];
export const BASE_MP_FILTER = [{name:"role_name",  value:"ROLES.MP"}];

export const PHONE_MASK = '+7(999) 999-99-99';
export const DATE_MASK = '99.99.9999';

//forms
export const MR_FILTER_FORM = 'mr_table_filter_form'; //фильтр таблицы МП
export const ADD_NEW_MR_FORM = 'mr_add_new_mr_form';//Добавить нового МП:
export const EDIT_NEW_MR_FORM = 'mr_edit_new_mr_form';//Добавить нового МП:
export const NESTED_MR_TABLE_FILTER = 'mr_nested_filter_name_form'; //Вложенная таблица с исследователями закрепленными за мед.представителем
export const USERS_SUB_ADMIN_FILTER = 'users_sub_admin_filter_form'; //Таблица карт пациентов для администратора.
export const ADD_USER_ADMIN_FORM = 'add_admin_users_form'; //Добавить нового пользователя с ролью "Администратор"
export const EDIT_USER_ADMIN_FORM = 'edit_admin_users_form'; //Редактировать пользователя с ролью "Администратор"
export const ADD_INV_ADMIN_FORM = 'add_inv_users_form'; //Добавить нового пользователя с ролью "Исследователь"
export const EDIT_INV_ADMIN_FORM = 'edit_inv_users_form'; //Редактировать пользователя с ролью "Исследователь"
export const ADD_PATIENTS_FORM = 'add_patients_form'; //Создание пациента
export const SET_PATIENTS_FORM = 'set_patients_form'; //Изменение комментария для пациента

//filters
export const ADMIN_USERS_FILTER = 'users_table_filter_admin_form';
export const INV_USERS_FILTER = 'users_table_filter_inv_form';
export const PATIENTS_FILTER_FORM = 'patients_filter_form';
