import React, {PureComponent} from 'react';
import {Col, Row} from "reactstrap";

//utils
import {getStoreData} from "../../utils/getStoreData";

//components
import Table from '../_commonComponents/table/Table';
import ModalForm from "../_commonComponents/form/ModalForm";
import Tabs from './components/Tabs';
import FormComponent from './components/FormComponent';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchUsersData} from "../../redux/actions/management/usersAction";
import {reset} from 'redux-form';
import {selectRow} from "../../redux/actions/selectedRowAction";
import {fetchResetPassData} from "../../redux/actions/resetPassAction";

//constants
import {
    MR_FILTER_FORM,
    BASE_MP_FILTER,
    ADD_NEW_MR_FORM,
    LG_SCREEN_WIDTH,
    SM_SCREEN_WIDTH,
    WIDE_DESK
} from "../../config/baseConstants";

import {
    ADD,
    DEL,
    SET,
    USERS_ADD_FUNCTION,
    MP_ROLE,
} from "../../config/requestConstants";

/**
 * Панель управления пользователями с ролью "Мед. представитель"
 */
class UsersMR extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            isWideDesk: false,
            isPopupOpen: false,
            isRerender: false,
            isActionVisible: false,
            isFormVisible: true,
            isTableRerender: false,
            selectedFormData: null
        }
    }

    componentDidMount() {
        this.isMobile();
        window.addEventListener('resize', this.isMobile);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.isMobile);
    }

    isMobile = () => {
        const isMobile = window.innerWidth < SM_SCREEN_WIDTH;
        const isWideDesk = window.innerWidth > WIDE_DESK;

        const isActionVisible = window.innerWidth < LG_SCREEN_WIDTH;
        const isFormVisible = window.innerWidth > LG_SCREEN_WIDTH;

        this.setState({
            isMobile,
            isActionVisible,
            isFormVisible,
            isWideDesk
        });

    };

    openFormPopup = () => {
        const {
            reset
        } = this.props;

        this.setState(prevState => ({
            isPopupOpen: !prevState.isPopupOpen
        }), () => {
            reset(ADD_NEW_MR_FORM)
        })
    };

    /**
     * @param values {Object}
     * @return {Promise<void>}
     */
    onSubmit = async (values) => {
        const {
            fetchUsersData,
            selectRow,
            reset
        } = this.props;

        const isNew = !values.id && !values.vers;
        const action = isNew? ADD : SET;

        const data = isNew ? {...values, ...{role: MP_ROLE}} : values;
        const func = isNew ? USERS_ADD_FUNCTION : null;

        await fetchUsersData(action, [], null, null, null, data, false, func);

        this.setState(prevState => ({
            isRerender: !prevState.isRerender,
            formData: this.props.formData,
        }), () => {
            isNew && reset(ADD_NEW_MR_FORM);
            selectRow(this.props.formData)
        })
    };

    /**
     * @param formData {Object}
     */
    getRowData = (formData) => {
        const {
            isActionVisible
        } = this.state;

        const {
            selectRow
        } = this.props;

        const isFormVisible = isActionVisible ? !this.state.isFormVisible : false;

        this.setState(prevState => ({
            formData: formData.original,
            isFormVisible,
            isTableRerender: !prevState.isTableRerender
        }), () => {
            selectRow(formData.original)
        })
    };

    /**
     * @return {Promise<void>}
     */
    onDelete = async () => {
        const {
            fetchUsersData,
            selectRow
        } = this.props;

        const {
            formData
        } = this.state;

        const requestData = {
            id: getStoreData(() => formData.id)
        };

        await fetchUsersData(DEL, [], null, null, null, requestData, false);

        this.setState(prevState => ({
            isRerender: !prevState.isRerender,
            formData: getStoreData(() => this.props.tableData[0]),
        }), () => {
            selectRow(getStoreData(() => this.props.tableData[0]))
        })
    };

    onCloseForm = () => {
        this.setState(prevState => ({
            isFormVisible: !prevState.isFormVisible
        }))
    };

    onReset = () => {
        const {
            selectRow,
        } = this.props;

        this.setState({
            formData: getStoreData(() => this.props.tableData[0]),
        }, () => {
            selectRow(getStoreData(() => this.props.tableData[0]))
        })
    };

    resetPass = () => {
        const {
            selectedRow,
            fetchResetPassData
        } = this.props;

        const data = {
            phone: selectedRow && selectedRow.phone
        };

        fetchResetPassData(data);
    };

    get selectedRow() {
        const {
            selectedRow,
            tableData
        } = this.props;

        return selectedRow ? selectedRow : getStoreData(() => tableData[0])
    }

    render() {
        const {
            fetchUsersData,
            tableData,
            total,
            isLoading,
            selectRow,
            linkedTotal
        } = this.props;

        const {
            isMobile,
            isPopupOpen,
            isRerender,
            isWideDesk,
            isActionVisible,
            isFormVisible,
            isTableRerender
        } = this.state;

        const columns = [
            {
                ...!isMobile && {
                    Header: "",
                    accessor: 'name',
                    id: 'name',
                    filterable: false,
                    sortable: false,
                    isFilterable: true,
                    isSortable: true,
                    isVisible: !isMobile,
                    placeholder: 'Id',
                    width: 127,
                    maxWidth: 127,
                }
            },
            {
                Header: "",
                accessor: 'title',
                id: 'title',
                filterable: false,
                sortable: false,
                isFilterable: true,
                isSortable: true,
                isVisible: true,
                placeholder: 'Имя',
            },
            {
                ...!isMobile && {
                    Header: "",
                    accessor: 'phone_view',
                    id: 'phone_search',
                    filterable: false,
                    sortable: false,
                    isFilterable: true,
                    isSortable: true,
                    isVisible: !isMobile,
                    placeholder: 'Телефон',
                    width: 180,
                    maxWidth: 180,
                }
            },
            {
                ...!isMobile && {
                    Header: "",
                    accessor: 'dsc',
                    id: 'dsc',
                    filterable: false,
                    sortable: false,
                    isFilterable: true,
                    isSortable: true,
                    isVisible: !isMobile,
                    placeholder: 'Регион',
                    width: isWideDesk ? 250 : isMobile ? 250 : 175,
                    maxWidth: isWideDesk ? 250 : isMobile ? 250 : 175,
                }
            }
        ].filter(el => Object.keys(el).length > 0);

        const isTableShow = isActionVisible ? !isFormVisible : true;
        const isFormShow = isActionVisible ? isFormVisible : true;

        return (
            <Row className="wrapper">
                {isTableShow
                && <Col xs={12} xl={6} className="wrapper__column wrapper__column_border pr-xl-0">
                     <Table
                        tableKey={isRerender}
                        headerTitle="Мед. представители"
                        headerIcon={"user-astronaut"}
                        onToggle={this.openFormPopup}
                        fetchAction={fetchUsersData}
                        tableData={tableData}
                        columns={columns}
                        filterForm={MR_FILTER_FORM}
                        total={total}
                        loading={isLoading}
                        selected={this.selectedRow}
                        initFilter={BASE_MP_FILTER}
                        onRowClick={this.getRowData}
                        onReset={this.onReset}
                        count={total}
                        selectRow={selectRow}
                    />
                </Col>}
                {isFormShow
                && <Col xs={12} xl={6} className="pl-xl-0">
                    <Tabs
                        onSubmit={this.onSubmit}
                        onDelete={this.onDelete}
                        data={this.selectedRow}
                        isWideDesk={isWideDesk}
                        isMobile={isMobile}
                        onToggle={this.onCloseForm}
                        isTableRerender={isTableRerender}
                        resetPass={this.resetPass}
                        linkedTotal={linkedTotal}
                    />
                </Col>}
                <ModalForm
                    isOpen={isPopupOpen}
                    toggle={this.openFormPopup}
                    onSubmit={this.onSubmit}
                    form={ADD_NEW_MR_FORM}
                    submitText={"Добавить нового МП"}
                    title={"Новый МП"}
                    icon={"user-plus"}
                >
                    <FormComponent
                        isNew
                    />
                </ModalForm>
            </Row>
        )
    }
}

const mapStateToProps = (store) => ({
    tableData: getStoreData(() => store.adminUsr.initialData.data),
    formData: getStoreData(() => store.adminUsr.formData.data),
    total: getStoreData(() => store.adminUsr.initialData.total),
    isLoading: getStoreData(() => store.adminUsr.isLoading),
    selectedRow: store.selectedRow.row,
    linkedTotal: store.linkedUsr.linkedTotal,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchUsersData,
    reset,
    selectRow,
    fetchResetPassData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UsersMR);
