import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import renderInputValidationField from "../../_commonComponents/form/InputWithValidation";
import {PHONE_MASK} from "../../../config/baseConstants";
import {Button} from "reactstrap";
import {fetchResetPassData} from "../../../redux/actions/resetPassAction";
import {bindActionCreators} from 'redux';

class ResetForm extends PureComponent {
    onSubmit = (values) => {
        const {
            fetchResetPassData,
            onClick
        } = this.props;

        return fetchResetPassData(values)
            .then(() => {
                    !this.props.isError && onClick()
                }
            );
    };

    render() {
        const {
            handleSubmit,
            onClick
        } = this.props;

        return (
            <form className="login-form" onSubmit={handleSubmit(this.onSubmit)}>
                <h4>Восстановление пароля</h4>
                <Field
                    type="text"
                    component={renderInputValidationField}
                    name={"phone"}
                    label={'Номер мобильного телефона'}
                    mask={PHONE_MASK}
                />
                <Button color="success" className="btn-lg btn-block mt-4 text-uppercase">ВОССТАНОВИТЬ ПАРОЛЬ</Button>
                <div className="text-right mt-4">
                    <div className="login-form__link" onClick={onClick}>Я помню свой пароль</div>
                </div>
            </form>
        )
    }
}

ResetForm = reduxForm({
    form: 'reset_pass_form'
})(ResetForm);

const mapStateToProps = (store) => ({
    enableReinitialize: true,
    isError: store.resetPass.isError
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchResetPassData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResetForm);
