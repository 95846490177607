import 'cross-fetch/polyfill';
import {formDataCreator, UrlCreator} from "../../../utils/requestCreator";
import {GET, SUB_MR_OBJECT} from "../../../config/requestConstants";
import {getStoreData} from "../../../utils/getStoreData";
import showNotification  from "../../../containers/_commonComponents/notification/showErrorNotification";

export const PATIENTS_MR_DATA_IS_LOGIN = 'PATIENTS_DATA_IS_LOGIN';
export const PATIENTS_MR_DATA_IS_GET = 'PATIENTS_DATA_IS_GET';
export const PATIENTS_MR_DATA_IS_ERROR = 'PATIENTS_DATA_IS_ERROR';
export const PATIENTS_MR_DATA_IS_EDIT = 'PATIENTS_DATA_IS_EDIT';

function dataIsLoading () {
    return {
        type: PATIENTS_MR_DATA_IS_LOGIN
    }
}

function dataIsGet (data) {
    return {
        type: PATIENTS_MR_DATA_IS_GET,
        payload: data
    }
}

function dataIsError () {
    return {
        type: PATIENTS_MR_DATA_IS_ERROR
    }
}

function dataIsEdit(data) {
    return {
        type: PATIENTS_MR_DATA_IS_EDIT,
        payload: data
    }
}

export function fetchPatientMRData(action = GET, filter = [], limit = 100, offset = null, order = null, data = {}, isInitial = true, functionName) {
    return (dispatch, getState) => {
        const key = getStoreData(() => getState().profile.key);
        const URL = UrlCreator();
        const requestData = formDataCreator(action, SUB_MR_OBJECT, data, key, filter, limit, offset, order, functionName);

        dispatch(dataIsLoading());
        return fetch(URL, {
            method: 'POST',
            body: requestData
        }).then((response) => {
            if (response.status !== 200) {
                dispatch(dataIsError());
                throw Error(response.statusText);
            }

            dispatch(dataIsLoading());
            return response.json();
        }).then((data) => {
            // eslint-disable-next-line
            if (data.status != 0) {
                showNotification(data);
                dispatch(dataIsError());
                return;
            }
            isInitial
                ? dispatch(dataIsGet(data))
                : dispatch(dataIsEdit(getStoreData(() => data.data)));

        }).catch(e => console.error(e));
    }
}
