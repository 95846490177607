export const required = value => (value ? undefined : 'Поле не может быть пустым');

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Некорректный email'
    : undefined;

export const phone = (value) =>
   value && value.match(/\d/g).length < 11
    ? 'Некорректный номер телефона'
    : undefined;


export const number = value => value && isNaN(Number.parseInt(value)) ? 'Значение должно быть числом' : undefined;
export const minValue = value =>
 value && value < 0 ? 'Значение не может быть отрицательным' : undefined;

export const maxWeightValue = value =>
    value && value >= 300 ? 'Укажите вес в килограммах' : undefined;

export const notZero = value =>
    // eslint-disable-next-line
 value == 0 ? 'Значение не может быть равно 0' : undefined;

export const dateLengthValidation = value =>
    value && value.length < 10 ? 'Пожалуйста, введите корректную дату' : undefined;

export const maxValue = value => value && value.length > 3 ? 'ФИО (инициалы - 3 буквы)' : '';

