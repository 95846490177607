import React, {PureComponent} from 'react';

//utils
import {getStoreData} from "../../utils/getStoreData";

//redux
import {reset} from "redux-form";
import {bindActionCreators} from "redux";

//components
import {connect} from 'react-redux';
import Table from './components/Table';

/**
 * Основное рабочее окно исследователя.
 */
class Patients extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            rowData: null,
        };
    }

    render() {
        const {
            title,
            dsc,
            total,
            history
        } = this.props;

        return (
            <div className={"patients"}>
                <Table
                    history={history}
                    count={total}
                    title={title}
                    dsc={dsc}
                />
            </div>
        )
    }
}

const mapStateToProps = (store) => ({
    tableData: getStoreData(() => store.patients.initialData.data),
    total: getStoreData(() => store.patients.initialData.total),
    id: getStoreData(() => store.profile.initialData.data[0].id),
    title: getStoreData(() => store.profile.initialData.data[0].title),
    dsc: getStoreData(() => store.profile.initialData.data[0].dsc),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    reset
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
