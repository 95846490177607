import React, {PureComponent} from 'react';
import {CustomInput,  FormGroup} from 'reactstrap';

class CheckBoxField extends PureComponent {
    state = {
        value: ''
    };

    onChange = (e) => {
        if (e.target.checked) {
            this.props.onChange(this.props.defaultChecked)
        } else {
            this.props.onChange(null)
        }
    };

    render() {
        const {
           name, label, id, value, defaultChecked,readOnly
        } = this.props;

        return (
            <FormGroup className={"m-0"}>
                <CustomInput
                  type="checkbox"
                  value={this.state.value}
                  name={`${name}${id}`}
                  checked={value === defaultChecked}
                  disabled={readOnly}
                  onChange={this.onChange}
                  id={id}
                  label={label}/>
            </FormGroup>
        )
    }
}

const renderCheckBoxField = props => {
    return (
      <CheckBoxField
        {...props.input}
        name={props.name}
        id={props.id}
        label={props.label}
        defaultChecked={props.defaultChecked}
        readOnly={props.readOnly}
      />
    )
};

export default renderCheckBoxField;
