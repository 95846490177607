import React from 'react';
import IconButton from "../../buttons/IconButton";

const FromHeader = ({onToggle, onReset, onDelete, resetPass=null}) => (
    <div className="tableHeader d-flex justify-content-between justify-content-xl-end">
        <div className="d-block d-xl-none">
            <IconButton
                icon={"chevron-left"}
                className={"tableHeader__button"}
                color={'secondary'}
                onClick={onToggle}
                outline
            />
        </div>
        <div>
            {resetPass && <IconButton
                icon={"key"}
                className={"tableHeader__button"}
                color={'secondary'}
                onClick={resetPass}
                outline
            />}
            <IconButton
                icon={"save"}
                type={"submit"}
                className={"tableHeader__button"}
                color={'secondary'}
                outline
            />
            <IconButton
                icon={"undo"}
                className={"tableHeader__button"}
                color={'secondary'}
                onClick={onReset}
                outline
            />
            <IconButton
                icon={"trash"}
                className={"tableHeader__button"}
                color={'secondary'}
                onClick={onDelete}
                outline
            />
        </div>
    </div>
);

export default FromHeader;
