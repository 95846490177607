import React, {PureComponent} from 'react';
import {Col, Row} from "reactstrap";

//utils
import {getStoreData} from "../../../../utils/getStoreData";

//redux
import {connect} from 'react-redux';
import {Field, reduxForm} from "redux-form";

//components
import renderCheckbox from "../../../_commonComponents/form/CheckBox";

class EventsInfoFrom extends PureComponent {
    static defaultProps = {
        readOnly: false
    };

    onCheckboxChange = () => this.props._onCheckboxChange(this.props.handleSubmit(this.props.onSubmit));

    render() {
        const {
            readOnly
        } = this.props;

        return (
            <form className={"form form_small"}>
                <Row>
                    <Col xs={12} className="mb-2">
                        <div
                            className="visitContainer__header visitContainer__header_success d-flex justify-content-between">
                            <h5>В ходе приема препарата Тенотен были зарегистрированы нежелательные явления?</h5>
                            <Field
                                name={"AEYN"}
                                component={renderCheckbox}
                                id={"AEYN_visit2"}
                                defaultChecked={77}
                                label={"Нет"}
                                onChange={this.onCheckboxChange}
                                readOnly={readOnly}
                            />
                        </div>
                    </Col>
                </Row>
            </form>
        )
    }
}

EventsInfoFrom = reduxForm({
    form: 'visit_2_adverse_info_events_form'
})(EventsInfoFrom);

const mapStateToProps = (store, props) => ({
    initialValues: {
        AEYN: getStoreData(() => props.data.aeyn)
    },
    enableReinitialize: true
});

export default connect(mapStateToProps)(EventsInfoFrom)
