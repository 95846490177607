import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import {getStoreData} from "../../utils/getStoreData";
import {bindActionCreators} from 'redux';
import {fetchLoginData} from "../../redux/actions/loginAction";
import Loading from '../_commonComponents/Loading';
import Header from '../header/index';
import {COMPONENTS} from "../../config/components";
import {LOGIN} from "../../config/baseConstants";

class Router extends PureComponent {
    componentDidMount() {
        this.getInitialData();
    }

    getInitialData = async () => {
        const {
            fetchLoginData
        } = this.props;

        fetchLoginData();
    };

    getComponent(name) {
        const componentObj = COMPONENTS.find((page, index, self) => index === self.findIndex(({title}) => title === name));
        return componentObj ? componentObj.component : '/';
    };

    get topbarMenu() {
        let topbarMenu = [...this.props.menu];
        topbarMenu = topbarMenu && topbarMenu.filter(({menu}) =>
            menu !== 0
        );
        return topbarMenu;
    }

    wrappedRoutes = () => {
        const {
            menu,
            defaultPath,
            defaultPage,
        } = this.props;

        return (
            <main className="w-100 h-100">
                {defaultPath !== LOGIN && <Header
                    menu={this.topbarMenu}
                    defaultPath={defaultPath}
                />}
                <Switch>
                    {menu && menu.map(item => {
                            return <Route
                                exact
                                key={item.id}
                                path={item.script}
                                component={this.getComponent(item.name)}
                            />
                        }
                    )}
                    <Redirect to={defaultPath}/>
                    <Route
                        component={this.getComponent(defaultPage)}/>
                </Switch>
            </main>
        )
    };

    render() {
        const {
            isLoading
        } = this.props;
        return (
            isLoading
                ? <Loading/>
                : <Route component={this.wrappedRoutes}/>

        )
    }
}

const mapStateToProps = (store) => ({
    menu: getStoreData(() => store.profile.initialData.menu),
    isLoading: store.profile.isLoading,
    defaultPage: getStoreData(() => store.profile.initialData.data[0].default_view_name),
    defaultPath: getStoreData(() => store.profile.initialData.data[0].default_view_path),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchLoginData
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Router));
