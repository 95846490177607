import React, {PureComponent} from 'react';
import {Col} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "./Modal";
import propTypes from 'prop-types';

export default class DeleteModal extends PureComponent {
    static propTypes = {
        onDelete: propTypes.func.isRequired,
        name: propTypes.string
    };

    static defaultProps = {
        name: '',
        readOnly: true
    };

    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false
        }
    }

    onToggle = () => {
        this.setState(prevState => ({
            isModalOpen: !prevState.isModalOpen
        }))
    };

    render() {
        const {
            onDelete,
            name
        } = this.props;

        const {
            isModalOpen
        } = this.state;

        return (
            <Col xs={12} className="d-flex justify-content-end align-items-center">
                <div className="visitCard__delete" onClick={this.onToggle}>
                    <FontAwesomeIcon icon="trash"/>
                </div>
                <Modal
                    color={'danger'}
                    isOpen={isModalOpen}
                    toggle={this.onToggle}
                    submit={onDelete}
                    submitText={"Подтвердить"}
                    title={""}
                    message={`Вы уверенны, что хотите удалить карточку № ${name}?`}
                />
            </Col>
        )
    }
}
