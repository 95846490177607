export const getVisitCellClass = (status) => {
    let className = '';
    switch (status) {
        case 1:
            className = 'patients__file_primary';
            break;
        case 2:
            className = 'patients__file_danger';
            break;
        case 3:
            className = 'patients__file_warning';
            break;
        case 4:
            className = 'patients__file_success';
            break;
        default:
            className = 'patients__file_primary';
    }

    return className;
};
