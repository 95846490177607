import React from 'react';
import {render} from 'react-dom';
import './styles/index.scss';
import App from './containers/_app/App.jsx';
import * as serviceWorker from './serviceWorker';
import Provider from "react-redux/es/components/Provider";
import store from "./redux/store";
import {library} from '@fortawesome/fontawesome-svg-core';

import {
    faUserCog,
    faUserAstronaut,
    faUserMd,
    faSyncAlt,
    faUserPlus,
    faUsersCog,
    faStepBackward,
    faStepForward,
    faFastBackward,
    faFastForward,
    faSortDown,
    faSortUp,
    faSort,
    faSave,
    faUndo,
    faTrash,
    faChevronLeft,
    faInfoCircle,
    faUsers,
    faFileAlt,
    faPen,
    faClipboardList,
    faUser,
    faPlus,
    faList,
    faChevronUp,
    faLock,
    faCheck,
    faLockOpen,
    faTimes,
    faKey,
    faFilter
} from '@fortawesome/free-solid-svg-icons';

import {
    faSadTear as farFaSadTear,
    faComment as farFaComment
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faUserCog,
    faUserAstronaut,
    faUserMd,
    farFaSadTear,
    farFaComment,
    faSyncAlt,
    faUserPlus,
    faUsersCog,
    faStepBackward,
    faStepForward,
    faFastBackward,
    faFastForward,
    faSortDown,
    faSortUp,
    faSort,
    faSave,
    faUndo,
    faTrash,
    faChevronLeft,
    faInfoCircle,
    faUsers,
    faFileAlt,
    faPen,
    faClipboardList,
    faUser,
    faPlus,
    faList,
    faChevronUp,
    faLock,
    faCheck,
    faLockOpen,
    faTimes,
    faKey,
    faFilter
);

render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
