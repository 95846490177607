import {
    PATIENTS_MR_DATA_IS_EDIT,
    PATIENTS_MR_DATA_IS_ERROR,
    PATIENTS_MR_DATA_IS_GET,
    PATIENTS_MR_DATA_IS_LOGIN
} from "../../actions/patients/patientsMRAction";

const initialData = {
    initialData: null,
    formData: null,
    isLoading: false,
    isError: false
};

export function patientsMRReducer(state = initialData, action) {
    switch (action.type) {
        case PATIENTS_MR_DATA_IS_LOGIN:
            return {...state, isLoading: true, isError: false};
        case PATIENTS_MR_DATA_IS_ERROR:
            return {...state, isLoading: false, isError: true};
        case PATIENTS_MR_DATA_IS_GET:
            return {...state, isLoading: false, isError: false, initialData: action.payload};
        case PATIENTS_MR_DATA_IS_EDIT:
            return {...state, isLoading: false, isError: false, formData: action.payload};
        default:
            return state;
    }
}
