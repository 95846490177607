import 'cross-fetch/polyfill';
import {UrlCreator, formDataCreator} from '../../../utils/requestCreator';
import {getStoreData} from "../../../utils/getStoreData";
import {LINKED_USERS_OBJECT, GET} from "../../../config/requestConstants";
import showNotification  from "../../../containers/_commonComponents/notification/showErrorNotification";

export const LINKED_USERS_DATA_GET = 'LINKED_USERS_DATA_GET';
export const LINKED_USERS_DATA_LOADING = 'LINKED_USERS_DATA_LOADING';
export const LINKED_USERS_DATA_EDITED = 'LINKED_USERS_DATA_EDITED';
export const LINKED_USERS_DATA_ERROR = 'LINKED_USERS_DATA_ERROR';
export const LINKED_USERS_IS_CHANGES = 'LINKED_USERS_IS_CHANGES';

function dataIsLoading() {
    return {
        type: LINKED_USERS_DATA_LOADING
    }
}

function dataIsFailed() {
    return {
        type: LINKED_USERS_DATA_ERROR
    }
}

function dataIsEdited(data) {
    return {
        type: LINKED_USERS_DATA_EDITED,
        payload: data
    }
}

function dataIsGet(data) {
    return {
        type: LINKED_USERS_DATA_GET,
        payload: data
    }
}

export function setLinkedUsers(data) {
   return {
       type: LINKED_USERS_IS_CHANGES,
       payload: data
   }
}

export function fetchLinkedUsersData(action = GET, filter = [], limit = 100, offset = null, order= null, data={}, isInitial=true, object = LINKED_USERS_OBJECT, functionName) {
    return (dispatch, getState) => {
        const key = getStoreData(() => getState().profile.key);
        const URL = UrlCreator();
        const requestData = formDataCreator(action, object, data, key, filter, limit, offset, order, functionName);

        dispatch(dataIsLoading());
        return fetch(URL, {
            method: 'POST',
            body: requestData
        })
            .then((response) => {
                if (response.status !== 200) {
                    dispatch(dataIsFailed());
                    throw Error(response.statusText);
                }

                dispatch(dataIsLoading());
                return response.json();
            })
            .then((data) => {
                // eslint-disable-next-line
                if (data.status != 0) {
                    showNotification(data);
                    dispatch(dataIsFailed());
                    return;
                }

                isInitial
                    ? dispatch(dataIsGet(data))
                    : dispatch(dataIsEdited(getStoreData(() => data.data)))
            }).catch(e => {
                console.error(e);
                dispatch(dataIsFailed());
            })
    }
}
