import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';

const Loading = ({className}) => (
    <div className={classnames("loading w-100 h-100 d-flex justify-content-center align-items-center", className)}>

    </div>
);

Loading.propTypes = {
    className: propTypes.string,
};

Loading.defaultProps = {
    className: '',
};

export default Loading;
