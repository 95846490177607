import React from 'react';
import {Row, Col} from 'reactstrap';

//utils
import {required} from '../../../utils/formValidation';

//redux
import {Field} from 'redux-form';

//components
import renderInputField from '../../_commonComponents/form/InputWithValidation';

//constants
import {PHONE_MASK} from "../../../config/baseConstants";

export const FormContent = ({isNew = false}) => (
    <Row>
        {!isNew && <Col xs={6} md={2}>
            <Field
                name={"id"}
                component={renderInputField}
                label={"sys id"}
                readOnly
            />
        </Col>}
        {!isNew && <Col xs={6} md={2}>
            <Field
                name={"vers"}
                component={renderInputField}
                label={"vers"}
                readOnly
            />
        </Col>}
        {!isNew && <Col xs={12} md={8}>
            <Field
                name={"name"}
                validate={[required]}
                label={"Id"}
                component={renderInputField}
            />
        </Col>}
        <Col xs={12}>
            <Field
                name={"title"}
                label={"Имя"}
                validate={[required]}
                component={renderInputField}
            />
        </Col>
        <Col xs={12}>
            <Field
                name={"phone"}
                label={"Номер телефона"}
                validate={[required]}
                component={renderInputField}
                mask={PHONE_MASK}
            />
        </Col>
    </Row>
);
