import React, {PureComponent} from 'react';
import {Col, Row} from "reactstrap";

//utils
import {getStoreData} from "../../../../utils/getStoreData";

//redux
import {connect} from 'react-redux';
import {Field, reduxForm} from "redux-form";

//constants
import {DATE_MASK} from "../../../../config/baseConstants";
import {dateLengthValidation, minValue, number} from "../../../../utils/formValidation";

//components
import renderInput from "../../../_commonComponents/form/InputWithValidation";
import renderCheckbox from "../../../_commonComponents/form/CheckBox";

class InfoForm extends PureComponent {
    static defaultProps = {
        readOnly: false
    };

    onChange = () => this.props._onChange(this.props.handleSubmit(this.props.onSubmit));
    onCheckboxChange = () => this.props._onCheckboxChange(this.props.handleSubmit(this.props.onSubmit));

    render() {
        const {
            name,
            readOnly
        } = this.props;

        return (
            <form className={"form form_small"}>
                <Row>
                    <Col xs={12} className="mb-2">
                        <div className="visitContainer__header visitContainer__header_success">
                            <h5>Пациент № <span className="text-uppercase">{name}</span></h5>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <Field
                            name={"DSPENDT"}
                            component={renderInput}
                            label={"Дата окончания приема препарата Тенотен:"}
                            subtext={"дд.мм.гггг"}
                            mask={DATE_MASK}
                            validate={[dateLengthValidation]}
                            onChange={this.onChange}
                            readOnly={readOnly}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <Field
                            name={"DATAKEN"}
                            component={renderInput}
                            label={"Количество принятых таблеток препарата Тенотен:"}
                            validate={[number, minValue]}
                            subtext={"штук"}
                            onChange={this.onChange}
                            readOnly={readOnly}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Field
                            name={"HR"}
                            component={renderInput}
                            label={"ЧСС:"}
                            validate={[number, minValue]}
                            subtext={"уд/мин"}
                            onChange={this.onChange}
                            readOnly={readOnly}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Field
                            name={"SYSBP"}
                            component={renderInput}
                            label={"Систолическое давление:"}
                            subtext={"мм.рт.ст."}
                            validate={[number, minValue]}
                            onChange={this.onChange}
                            readOnly={readOnly}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Field
                            name={"DIABP"}
                            component={renderInput}
                            label={"Диастолическое давление:"}
                            subtext={"мм.рт.ст."}
                            validate={[number, minValue]}
                            onChange={this.onChange}
                            readOnly={readOnly}
                        />
                    </Col>
                    <Col  xs={12} className="mb-2">
                        <div className="visitContainer__header visitContainer__header_success d-flex justify-content-between">
                            <h5>Изменения в терапии основного заболевания (АГ)</h5>
                            <Field
                                name={"MDMYN"}
                                component={renderCheckbox}
                                id={"MDMYN_visit2"}
                                defaultChecked={77}
                                label={"Не изменялась"}
                                onChange={this.onCheckboxChange}
                                readOnly={readOnly}
                            />
                        </div>
                    </Col>
                </Row>
            </form>
        )
    }
}

InfoForm = reduxForm({
    form: 'visit2_patient_info_form'
})(InfoForm);

const mapStateToProps = (store, props) => ({
    initialValues: {
        DSPENDT: getStoreData(() => props.data.dspendt),
        DATAKEN: getStoreData(() => props.data.dataken),
        HR: getStoreData(() => props.data.hr),
        SYSBP: getStoreData(() => props.data.sysbp),
        DIABP: getStoreData(() => props.data.diabp),
        MDMYN: getStoreData(() => props.data.mdmyn),
    },
    enableReinitialize: true
});

export default connect(mapStateToProps)(InfoForm);
