import React, {PureComponent} from 'react';
import {Col, Row} from "reactstrap";
import debounce from "debounce";

//redux
import { connect } from "react-redux";
import {Field, reduxForm} from "redux-form";
import {bindActionCreators} from "redux";
import {fetchVisit1DiseasesData} from "../../../redux/actions/visit1/visit1DiseasesAction";

//utils
import {getStoreData} from "../../../utils/getStoreData";
import {dateLengthValidation} from "../../../utils/formValidation";

//components
import renderInput from "../../_commonComponents/form/InputWithValidation";
import renderRadio from '../../_commonComponents/form/RadioGroupWithValidation';

//constants
import {SET} from "../../../config/requestConstants";
import {DATE_MASK} from "../../../config/baseConstants";
import DeleteModal from "../../_commonComponents/DeleteModal";

class DiseasesForm extends PureComponent {
    static _onChange = (callback) => {
        callback();
    };

    static _onCheckboxChange = (callback) => {
        callback();
    };

    constructor(props) {
        super(props);

        DiseasesForm._onChange = debounce(DiseasesForm._onChange, 600);
    }

    onChange = async (values) => {
        const {
            fetchVisit1DiseasesData,
        } = this.props;

        await fetchVisit1DiseasesData(SET, [], values, false);
    };

    onHandleChange = () => DiseasesForm._onChange(this.props.handleSubmit(this.onChange));
    onCheckboxChange = () => DiseasesForm._onCheckboxChange(this.props.handleSubmit(this.onChange));

    render() {
        const {
            form,
            isControlled,
            readOnly,
            handleSubmit,
            onDelete,
            initialValues
        } =this.props;

        return (
            <Col xs={12} md={6} className={"mb-4"}>
                <form className={"form form_small h-100"}>
                    <div className={"visitCard"}>
                        <Row>
                            {!readOnly && <DeleteModal
                                onDelete={handleSubmit(onDelete)}
                                name={`${getStoreData(() => initialValues.num)}`}
                            />}
                            <Col xs={2} className={"pr-0"}>
                                <Field
                                    name={"num"}
                                    component={renderInput}
                                    label={"№"}
                                    className={"p-0 text-center"}
                                    readOnly
                                />
                            </Col>
                            <Col xs={10}>
                                <Field
                                    name={"mhterm"}
                                    component={renderInput}
                                    label={"Описание"}
                                    onChange={this.onHandleChange}
                                    readOnly={readOnly}
                                />
                            </Col>
                            <Col xs={6}>
                                <Field
                                    name={"mhstdt"}
                                    component={renderInput}
                                    label={"Дата начала:"}
                                    mask={DATE_MASK}
                                    validate={[dateLengthValidation]}
                                    onChange={this.onHandleChange}
                                    subtext={"дд.мм.гггг"}
                                    readOnly={readOnly}
                                />
                            </Col>
                            <Col xs={6}>
                                <Field
                                    name={"mhendt"}
                                    component={renderInput}
                                    label={"Дата завершения:"}
                                    mask={DATE_MASK}
                                    validate={[dateLengthValidation]}
                                    onChange={this.onHandleChange}
                                    subtext={"дд.мм.гггг"}
                                    readOnly={readOnly}
                                />
                            </Col>
                            <Col xs={12} className="mb-2">
                                <Field
                                    name={"mhcontr"}
                                    component={renderRadio}
                                    options={[{id: `mhcontr_${form}1`, value: 1, label: "Да"}, {id: `mhcontr_${form}2`, value: 77, label: "Нет"}]}
                                    label={"Контролируемое?"}
                                    onChange={this.onCheckboxChange}
                                    readOnly={readOnly}
                                />
                            </Col>
                            {isControlled && <Col xs={12}>
                                <Field
                                    name={"mhtrt"}
                                    component={renderInput}
                                    label={"Если «Да», укажите торговое название терапии"}
                                    onChange={this.onHandleChange}
                                    readOnly={readOnly}
                                />
                            </Col>}
                        </Row>
                    </div>
                </form>
            </Col>
        )
    }
}

DiseasesForm = reduxForm({
    destroyOnUnmount: true
})(DiseasesForm);

const mapStateToProps = (store, props) => ({
    form: props.form,
    // eslint-disable-next-line
    isControlled: props.selector(store, 'mhcontr') && props.selector(store, 'mhcontr') == 1,
    initialValues: {
        id: getStoreData(() => props.data.id),
        num: getStoreData(() => props.data.num),
        mhcontr: getStoreData(() => props.data.mhcontr),
        mhendt: getStoreData(() => props.data.mhendt),
        mhstdt: getStoreData(() => props.data.mhstdt),
        mhterm: getStoreData(() => props.data.mhterm),
        mhtrt: getStoreData(() => props.data.mhtrt),
        sub: getStoreData(() => props.data.sub),
        vers: getStoreData(() => props.data.vers),
    },
    enableReinitialize: true
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchVisit1DiseasesData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DiseasesForm);
