import 'cross-fetch/polyfill';
import {getStoreData} from "../../../utils/getStoreData";
import {formDataCreator, UrlCreator} from "../../../utils/requestCreator";
import {SUB_VISIT2_THERAPY_OBJECT} from "../../../config/requestConstants";
import showNotification  from "../../../containers/_commonComponents/notification/showErrorNotification";

export const VISIT2_THERAPY_IS_LOADING = 'VISIT2_THERAPY_IS_LOADING';
export const VISIT2_THERAPY_IS_ERROR = 'VISIT2_THERAPY_IS_ERROR';
export const VISIT2_THERAPY_IS_GET = 'VISIT2_THERAPY_IS_GET';
export const VISIT2_THERAPY_IS_EDITED = 'VISIT2_THERAPY_IS_EDITED';

function dataIsLoading() {
    return {
        type: VISIT2_THERAPY_IS_LOADING
    }
}

function dataIsError() {
    return {
        type: VISIT2_THERAPY_IS_ERROR
    }
}

function dataIsGet(data) {
    return {
        type: VISIT2_THERAPY_IS_GET,
        payload: data
    }
}

function dataIsEdited(data) {
    return {
        type: VISIT2_THERAPY_IS_EDITED,
        payload: data
    }
}


export function fetchVisit2TherapyData(action = null, filter = [], data = {}, isInitial = true, functionName) {
    return (dispatch, getState) => {
        const key = getStoreData(() => getState().profile.key);
        const URL = UrlCreator();
        const requestData = formDataCreator(action, SUB_VISIT2_THERAPY_OBJECT, data, key, filter, null, null, null, functionName);

        dispatch(dataIsLoading());

        return fetch(URL, {
            method: 'POST',
            body: requestData
        }).then((response) => {
            if (response.status !== 200) {
                dispatch(dataIsError());
                throw Error(response.statusText)
            }

            dispatch(dataIsLoading());
            return response.json();
        }).then((data) => {
            // eslint-disable-next-line
            if (data.status != 0) {
                showNotification(data);
                dispatch(dataIsError());
                return;
            }

            isInitial
                ? dispatch(dataIsGet(data))
                : dispatch(dataIsEdited(getStoreData(() => data.data)))
        })
    }
}
