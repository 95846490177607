import React, {Fragment} from 'react';
import InfoForm from "./infoCard/InfoForm";
import TherapyCard from "./TherapyCard";
import EventsInfoFrom from "./infoCard/EventsInfoFrom";
import EventsCard from "./EventsCard";
import DiseasesInfoFrom from "./infoCard/DiseasesInfoFrom";


export const Visit2 = ({onChange, onCheckboxChange, name, onSubmit, data, readOnly = false, id }) => (
    <Fragment>
        <InfoForm
            name={name}
            _onChange={onChange}
            _onCheckboxChange={onCheckboxChange}
            onSubmit={onSubmit}
            data={data}
            readOnly={readOnly}
        />
        <TherapyCard
            id={id}
            _onChange={onChange}
            _onCheckboxChange={onCheckboxChange}
            readOnly={readOnly}
        />
        <EventsInfoFrom
            _onCheckboxChange={onCheckboxChange}
            onSubmit={onSubmit}
            data={data}
            readOnly={readOnly}
        />
        <EventsCard
            id={id}
            _onChange={onChange}
            _onCheckboxChange={onCheckboxChange}
            readOnly={readOnly}
        />
        <DiseasesInfoFrom
            _onCheckboxChange={onCheckboxChange}
            onSubmit={onSubmit}
            data={data}
            readOnly={readOnly}
        />
    </Fragment>
);
