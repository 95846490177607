import {HOMEPAGE, SERVICE} from '../config/requestConstants';

export function UrlCreator(query = SERVICE) {
    const URL = `${HOMEPAGE}/${query}`;
    return URL;
}

export function formDataCreator(
    action,
    object = null,
    data = {},
    skey = null,
    filter = [],
    limit = 100,
    offset = null,
    order = null,
    functionName = null,
)
{
    const requestData = {
        ...{
            object,
        },
        ...action && {
            action
        },
        ...{
            filter,
            data,
            limit,
            offset,
            order
        },
        ...skey && {
            skey
        },
    };

    let formData = new FormData();
    formData.append('data', JSON.stringify(requestData));
    formData.append('function', functionName);
    return formData;
}
