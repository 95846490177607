import React, {PureComponent} from 'react';
import {Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//utils
import {returnBootstrapColor} from "../../../utils/returnBootstrapColor";
import {getStoreData} from "../../../utils/getStoreData";

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchVisit2EventsData} from "../../../redux/actions/visit2/visit2EventsAction";
import {ADD, DEL, GET} from "../../../config/requestConstants";

//compoents
import EventsForm from './EventsForm';

class EventsCard extends PureComponent{
    static defaultProps = {
        readOnly: false
    };

    componentDidMount() {
        this.getInitialData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.getInitialData();
        }
    }

    getInitialData = () => {
        const {
            fetchVisit2EventsData,
            id
        } = this.props;
        if (!id) return;

        const filter = [{name:"sub", value: id}];
        fetchVisit2EventsData(GET, filter);
    };

    addNewCard = async () => {
        const {
            fetchVisit2EventsData,
            id
        } = this.props;

        const data = {
            sub: id
        };

        await fetchVisit2EventsData(ADD, [], data, false);
        this.getInitialData();
    };

    onDelete = async (values) => {
        const {
            fetchVisit2EventsData,
        } = this.props;

        const data = {
            id: values.id
        };

        await fetchVisit2EventsData(DEL, [], data, false);
        this.getInitialData();
    };

    render() {
        const {
            events,
            _onChange,
            _onCheckboxChange,
            readOnly
        } = this.props;

        return(
            <Row>
                {
                    events && events.map(item =>
                        <EventsForm
                            key={item.id}
                            form={`visit2_events_form_${item.id}`}
                            _onChange={_onChange}
                            _onCheckboxChange={_onCheckboxChange}
                            data={item}
                            onDelete={this.onDelete}
                            readOnly={readOnly}
                        />
                    )
                }
                {!readOnly && <Col xs={12} md={6} className={"mb-4"}>
                    <div className="visitCard visitCard_pointer d-flex justify-content-center align-items-center">
                        <Row>
                            <Col xs={12} className="visitCard__add d-flex justify-content-center align-items-center"
                                 onClick={this.addNewCard}>
                                <FontAwesomeIcon icon={"plus"} color={returnBootstrapColor('success')}/>
                            </Col>
                        </Row>
                    </div>
                </Col>}
            </Row>
        )
    }
}

const mapStateToProps = (store) => ({
    events: getStoreData(() => store.visit2Events.initialData.data)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchVisit2EventsData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EventsCard);
