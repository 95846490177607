import {
    USERS_DATA_LOADING,
    USERS_DATA_ERROR,
    USERS_DATA_GET,
    USERS_DATA_EDITED
} from '../../actions/management/usersAction';

const initialState = {
    isLoading: false,
    isError: false,
    initialData: null,
    formData: null
};

export function usersReducer(state = initialState, action) {
    switch (action.type) {
        case USERS_DATA_LOADING:
            return {...state, isLoading: true, isError: false, initialData: null};
        case USERS_DATA_ERROR:
            return {...state, isLoading: false, isError: true};
        case USERS_DATA_GET:
            return {...state, isLoading: false, isError: false, initialData: action.payload};
        case USERS_DATA_EDITED:
            return {...state, isLoading: false, isError: false, formData: action.payload};
        default:
            return state;
    }
}
