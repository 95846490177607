import React, {PureComponent} from 'react';
import poster from '../../img/poster.jpg';

class Training extends PureComponent {
    render() {
        return (
            <div className="wrapper d-flex align-items-center justify-content-center">
                <div style={{height: 'calc(100vh - 60px)'}}>
                    <video
                        autoPlay
                        style={{width: '100%'}}
                        poster={poster}
                        controls
                        src={'https://teatr.scithis.com/teatr/files/training_inv.mp4'}
                    >
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                </div>
            </div>
        )
    }
}

export default Training;
