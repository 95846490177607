import React from 'react';
import {Row, Col, FormGroup, Label, Input} from 'reactstrap';

//utils
import {required} from '../../../utils/formValidation';

//redux
import {Field} from 'redux-form';

//components
import renderInputField from '../../_commonComponents/form/InputWithValidation';
import renderCheckBoxField from '../../_commonComponents/form/CheckBox';

//constants
import {PHONE_MASK} from "../../../config/baseConstants";

const checkboxField = (props) => {
  return (
  <FormGroup check>
    <Label check for={props.id}>
      <Input {...props.input} checked={props.defaultValue === props.input.value} id={props.id} type="checkbox" />
      {props.label}
    </Label>
  </FormGroup>
)};

export const FormContent = ({isNew = false, id}) => (
    <Row>
        {!isNew && <Col xs={6} md={2}>
            <Field
                name={"id"}
                component={renderInputField}
                label={"sys id"}
                readOnly
            />
        </Col>}
        {!isNew && <Col xs={6} md={2}>
            <Field
                name={"vers"}
                component={renderInputField}
                label={"vers"}
                readOnly
            />
        </Col>}
        {!isNew && <Col xs={12} md={8}>
            <Field
                name={"name"}
                validate={[required]}
                label={"Id"}
                component={renderInputField}
            />
        </Col>}
        <Col xs={12}>
            <Field
                name={"title"}
                label={"Имя"}
                validate={[required]}
                component={renderInputField}
            />
        </Col>
        <Col xs={12}>
            <Field
                name={"phone"}
                label={"Номер телефона"}
                validate={[required]}
                component={renderInputField}
                mask={PHONE_MASK}
            />
        </Col>
        <Col xs={12}>
            <Field
                name={"dsc"}
                label={"Город"}
                validate={[required]}
                component={renderInputField}
            />
        </Col>
        <Col xs={12}>
            <Field
                name={"phone_send"}
                label={"Номер телефона для отправки сообщений"}
                component={renderInputField}
                mask={PHONE_MASK}
            />
        </Col>
        <Col xs={12}>
            <Field
                name={"test"}
                label={"Тестовый аккаунт"}
                component={checkboxField}
                defaultValue={true}
                id={`test_${id}`}
            />
        </Col>
        <Col xs={12}>
            <Field
                name={"rejection"}
                label={"Отказ"}
                component={checkboxField}
                defaultValue={true}
                id={`rejection_${id}`}
            />
        </Col>
    </Row>
);
