import React from 'react';
import {Col} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import propTypes from 'prop-types';

const Card = ({title, icon, prefix = null, onClick}) => (
    <Col xs={12} md={6} lg={3} onClick={onClick} className="mb-4 mb-lg-0 d-flex justify-content-center">
        <div className="card">
            <div className={"card__header"}>
                <FontAwesomeIcon icon={prefix ? [prefix, icon] : icon}/>
            </div>
            <h4 className="card__title">{title}</h4>
        </div>
    </Col>
);

Card.propTypes = {
    title: propTypes.string.isRequired,
    icon: propTypes.string.isRequired,
    prefix: propTypes.string,
    onClick: propTypes.func.isRequired
};

export default Card;
