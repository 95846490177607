import {
   PASS_IS_FAILED,
    PASS_IS_LOADING,
    PASS_IS_SUCCESS
} from "../actions/resetPassAction";

const initialState = {
    isLoading: true,
    isError: false,
    initialData: null,
    key: null,
    isAuthenticated: false
};

export function resetPassReducer(state = initialState, action) {
    switch (action.type) {
        case PASS_IS_LOADING:
            return {...state, isLoading: true, isError: false, isAuthenticated: false};
        case PASS_IS_FAILED:
            return {...state, isLoading: false, isError: true, isAuthenticated: false};
        case PASS_IS_SUCCESS:
            return {...state, isLoading: false, isError: false, initialData: action.payload, key: action.key, isAuthenticated: true};
        default:
            return state;
    }
}
