import React from 'react';
import Notification from './Notification';
import NotificationSystem from 'rc-notification';

export default function showNotification(color, title, message) {
  NotificationSystem.newInstance({}, notification => {
    notification.notice({
      content: <Notification color={color} title={title} message={message} />,
      duration: 10,
      closable: true,
      style: { top: 60, left: 'calc(100vw - 100%)' },
      className: 'right-up',
    });
  });
}
