import React from 'react';
import {Field} from "redux-form";
import renderInput from "../../_commonComponents/form/InputWithValidation";
import {DATE_MASK} from "../../../config/baseConstants";
import renderTextAreaWithValidation from "../../_commonComponents/form/textareaWithValidation";
import {required, dateLengthValidation, maxValue} from "../../../utils/formValidation";

export const FormContent = () => (
    <div>
        <Field
            name={"title"}
            component={renderInput}
            validate={[required, maxValue]}
            label={"ФИО (Инициалы)"}
        />
        <Field
            name={"birth"}
            component={renderInput}
            validate={[required, dateLengthValidation]}
            label={"Дата рождения"}
            placeholder={"ДД.ММ.ГГГГ"}
            mask={DATE_MASK}
        />
        <Field
            name={"reg"}
            component={renderInput}
            validate={[required, dateLengthValidation]}
            label={"Дата регистрации"}
            placeholder={"ДД.ММ.ГГГГ"}
            mask={DATE_MASK}
        />
    </div>
);

export const FormCommentContent = () => (
    <div>
        <Field
            name={"dsc"}
            component={renderTextAreaWithValidation}
            label={"Комментарий"}
        />
    </div>
);
