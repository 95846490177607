import React, {PureComponent} from 'react';
import {Col, Row, Table} from "reactstrap";

//redux
import {connect} from 'react-redux';
import {Field, reduxForm} from "redux-form";

//utils
import {minValue, number} from "../../../../utils/formValidation";
import {getStoreData} from "../../../../utils/getStoreData";

//components
import renderRadio from "../../../_commonComponents/form/RadioGroupWithValidation";
import renderInput from "../../../_commonComponents/form/InputWithValidation";
import renderCheckbox from "../../../_commonComponents/form/CheckBox";

export const AnxietyForm = ({onChange, readOnly = false}) => (
    <Row>
        <Col  xs={12} className="mb-2">
            <div className="visitContainer__header visitContainer__header_success d-flex justify-content-between">
                <h5>Шкала HADS (тревога – белые карточки, депрессия – серые)</h5>
            </div>
        </Col>
        <Col xs={12} lg={6} className="mb-4">
            <div  className={"patients__hadsCard"}>
                <Field
                    name={"HADS1"}
                    component={renderRadio}
                    options={
                        [{value: 3, label: "3 - Большую часть времени", id: "HADS1_1"},
                            {value: 2, label: "2 - Много времени", id: "HADS1_2"},
                            {value: 1, label: "1 - Время от времени", id: "HADS1_3"},
                            {value: 0, label: "0 - Совсем нет", id: "HADS1_4"}
                        ]
                    }
                    label={"Я напряжен, мне не по себе:"}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            </div>
        </Col>
        <Col xs={12} lg={6} className="mb-4">
            <div className={"patients__hadsCard patients__hadsCard_gray"}>
                <Field
                    name={"HADS2"}
                    component={renderRadio}
                    options={
                        [{value: 3, label: "3 - Почти все время", id: "HADS2_1"},
                            {value: 2, label: "2 - Очень часто", id: "HADS2_2"},
                            {value: 1, label: "1 – Иногда", id: "HADS2_3"},
                            {value: 0, label: "0 - Совсем нет", id: "HADS2_4"}
                        ]
                    }
                    label={"Мне не кажется, что я все делаю очень медленно:"}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            </div>
        </Col>
        <Col xs={12} lg={6} className="mb-4">
            <div className={"patients__hadsCard patients__hadsCard_gray"}>
                <Field
                    name={"HADS3"}
                    component={renderRadio}
                    options={
                        [{value: 0, label: "0 - Определённо это так", id: "HADS3_1"},
                            {value: 1, label: "1 - Наверное, это так", id: "HADS3_2"},
                            {value: 2, label: "2 - Лишь в очень малой степени это так", id: "HADS3_3"},
                            {value: 3, label: "3 - Это совсем не так", id: "HADS3_4"}
                        ]
                    }
                    label={"То, что приносило удовольствие, и сейчас вызывает такое же чувство:"}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            </div>
        </Col>
        <Col xs={12} lg={6} className="mb-4">
            <div className={"patients__hadsCard"}>
                <Field
                    name={"HADS4"}
                    component={renderRadio}
                    options={
                        [{value: 0, label: "0 - Совсем нет", id: "HADS4_1"},
                            {value: 1, label: "1 - Иногда", id: "HADS4_2"},
                            {value: 2, label: "2 - Часто", id: "HADS4_3"},
                            {value: 3, label: "3 - Очень часто", id: "HADS4_4"}
                        ]
                    }
                    label={"Я испытываю внутреннее напряжение:"}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            </div>
        </Col>
        <Col xs={12} lg={6} className="mb-4">
            <div className={"patients__hadsCard"}>
                <Field
                    name={"HADS5"}
                    component={renderRadio}
                    options={
                        [{value: 3, label: " 3 - Определённо так, и страх очень сильный", id: "HADS5_1"},
                            {value: 2, label: "2 - Это так, но страх не очень сильный", id: "HADS5_2"},
                            {value: 1, label: "1 - Иногда, но это меня не беспокоит", id: "HADS5_3"},
                            {value: 0, label: "0 - Совсем нет", id: "HADS5_4"}
                        ]
                    }
                    label={"Я испытываю страх, кажется, будто что-то ужасное может вот-вот случиться:"}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            </div>
        </Col>
        <Col xs={12} lg={6} className="mb-4">
            <div className={"patients__hadsCard  patients__hadsCard_gray"}>
                <Field
                    name={"HADS6"}
                    component={renderRadio}
                    options={
                        [{value: 3, label: "3 - Определенно это так", id: "HADS6_1"},
                            {value: 2, label: "2 - Я не уделяю столько времени, сколько нужно", id: "HADS6_2"},
                            {value: 1, label: "1 - Возможно, стал/а меньше уделять времени", id: "HADS6_3"},
                            {value: 0, label: "0 - Я слежу за собой так же, как и раньше", id: "HADS6_4"}
                        ]
                    }
                    label={"Я не слежу за своей внешностью:"}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            </div>
        </Col>
        <Col xs={12} lg={6} className="mb-4">
            <div className={"patients__hadsCard  patients__hadsCard_gray"}>
                <Field
                    name={"HADS7"}
                    component={renderRadio}
                    options={
                        [{value: 0, label: "0 - Определённо это так", id: "HADS7_1"},
                            {value: 1, label: "1 - Наверное, это так", id: "HADS7_2"},
                            {value: 2, label: "2 - Лишь в очень малой степени это так", id: "HADS7_3"},
                            {value: 3, label: "3 - Совсем нет", id: "HADS7_4"}
                        ]
                    }
                    label={"Я способен рассмеяться и видеть смешное:"}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            </div>
        </Col>
        <Col xs={12} lg={6} className="mb-4">
            <div className={"patients__hadsCard"}>
                <Field
                    name={"HADS8"}
                    component={renderRadio}
                    options={
                        [{value: 3, label: "3 - Определённо это так", id: "HADS8_1"},
                            {value: 2, label: "2 - Наверное, это так", id: "HADS8_2"},
                            {value: 1, label: "1 - Лишь в некоторой степени это так", id: "HADS8_3"},
                            {value: 0, label: "0 - Совсем нет", id: "HADS8_4"}
                        ]
                    }
                    label={"Я испытываю неусидчивость, словно мне постоянно нужно двигаться:"}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            </div>
        </Col>
        <Col xs={12} lg={6} className="mb-4">
            <div className={"patients__hadsCard"}>
                <Field
                    name={"HADS9"}
                    component={renderRadio}
                    options={
                        [{value: 3, label: "3 - Постоянно", id: "HADS9_1"},
                            {value: 2, label: "2 - Большую часть времени", id: "HADS9_2"},
                            {value: 1, label: "1 - Время от времени и не так часто", id: "HADS9_3"},
                            {value: 0, label: "0 - Только иногда", id: "HADS9_4"}
                        ]
                    }
                    label={"Беспокойные мысли крутятся в голове:"}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            </div>
        </Col>
        <Col xs={12} lg={6} className="mb-4">
            <div className={"patients__hadsCard patients__hadsCard_gray"}>
                <Field
                    name={"HADS10"}
                    component={renderRadio}
                    options={
                        [{value: 0, label: "0 - Точно так же, как и обычно", id: "HADS10_1"},
                            {value: 1, label: "1 - Но не в такой степени, как раньше", id: "HADS10_2"},
                            {value: 2, label: "2 - Определенно меньше, чем обычно", id: "HADS10_3"},
                            {value: 3, label: "3 - Совсем так не считаю", id: "HADS10_4"}
                        ]
                    }
                    label={"Я считаю, что мои дела принесут удовлетворение:"}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            </div>
        </Col>
        <Col xs={12} lg={6} className="mb-4">
            <div className={"patients__hadsCard patients__hadsCard_gray"}>
                <Field
                    name={"HADS11"}
                    component={renderRadio}
                    options={
                        [{value: 3, label: "3 - Совсем не испытываю", id: "HADS11_1"},
                            {value: 2, label: "2 - Очень редко", id: "HADS11_2"},
                            {value: 1, label: "1 - Иногда", id: "HADS11_3"},
                            {value: 0, label: "0 - Большую часть времени", id: "HADS11_4"}
                        ]
                    }
                    label={"Я испытываю бодрость:"}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            </div>
        </Col>
        <Col xs={12} lg={6} className="mb-4">
            <div className={"patients__hadsCard"}>
                <Field
                    name={"HADS12"}
                    component={renderRadio}
                    options={
                        [{value: 3, label: "3 - Очень часто", id: "HADS12_1"},
                            {value: 2, label: "2 - Довольно часто", id: "HADS12_2"},
                            {value: 1, label: " 1 - Не так уж часто", id: "HADS12_3"},
                            {value: 0, label: " 0 - Совсем нет", id: "HADS12_4"}
                        ]
                    }
                    label={"У меня бывает внезапное чувство паники:"}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            </div>
        </Col>
        <Col xs={12} lg={6} className="mb-4">
            <div className={"patients__hadsCard"}>
                <Field
                    name={"HADS13"}
                    component={renderRadio}
                    options={
                        [{value: 0, label: "0 - Определённо это так", id: "HADS13_1"},
                            {value: 1, label: "1 - Наверное, это так", id: "HADS13_2"},
                            {value: 2, label: "2 - Нечасто", id: "HADS13_3"},
                            {value: 3, label: "3 - Совсем нет", id: "HADS13_4"}
                        ]
                    }
                    label={"Я легко могу сесть и расслабиться:"}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            </div>
        </Col>
        <Col xs={12} lg={6} className="mb-4">
            <div className={"patients__hadsCard patients__hadsCard_gray"}>
                <Field
                    name={"HADS14"}
                    component={renderRadio}
                    options={
                        [{value: 0, label: "0 - Часто", id: "HADS14_1"},
                            {value: 1, label: "1 - Иногда", id: "HADS14_2"},
                            {value: 2, label: "2 - Редко", id: "HADS14_3"},
                            {value: 3, label: "3 - Очень редко", id: "HADS14_4"}
                        ]
                    }
                    label={"Я получаю удовольствие от хорошей книги, радиопередачи или ТВ:"}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            </div>
        </Col>
        <Col xs={12} lg={6} className="mb-4">
            <div className="patients__hadsCard patients__hadsCard_beforeNone patients__hadsCard_gray justify-content-between">
                <Field
                    name={"D_RES"}
                    component={renderInput}
                    label={"Количество баллов по подшкале Депрессии:"}
                    subtext={"рассчитывается автоматически"}
                    readOnly
                />
            </div>
        </Col>
        <Col xs={12} lg={6} className="mb-4">
            <div className={"patients__hadsCard patients__hadsCard_beforeNone"}>
                <Field
                    name={"A_RES"}
                    component={renderInput}
                    label={"Количество баллов по подшкале Тревоги:"}
                    subtext={"рассчитывается автоматически"}
                    readOnly
                />
            </div>
        </Col>
    </Row>
);

export const CGISForm = ({onChange, readOnly = false}) => (
    <Row>
        <Col xs={12}>
            <div className="visitContainer__header visitContainer__header_success mb-0">
                <h5>Шкала тяжести заболевания общего клинического впечатления (CGI–S)</h5>
            </div>
        </Col>
        <Col className="mb-4" xs={12}>
            <Table className={"m-0"} bordered>
                <thead>
                <tr>
                    <th width="141" className="text-center">
                        Баллы
                    </th>
                    <th>
                        Оценка состояния
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td width="141" className="text-center">
                        <Field
                            name={"CGIS"}
                            component={renderRadio}
                            className="d-flex align-items-center justify-content-center"
                            options={
                                [
                                    {value: 1, label: "1", id: "CGIS1"},
                                ]
                            }
                            onChange={onChange}
                            readOnly={readOnly}
                        />
                    </td>
                    <td>Здоров</td>
                </tr>
                <tr>
                    <td width="141" className="text-center">
                        <Field
                            name={"CGIS"}
                            component={renderRadio}
                            className="d-flex align-items-center justify-content-center"
                            options={
                                [
                                    {value: 2, label: "2", id: "CGIS2"},
                                ]
                            }
                            onChange={onChange}
                            readOnly={readOnly}
                        />
                    </td>
                    <td>Пограничное расстройство</td>
                </tr>
                <tr>
                    <td width="141" className="text-center">
                        <Field
                            name={"CGIS"}
                            component={renderRadio}
                            className="d-flex align-items-center justify-content-center"
                            options={
                                [
                                    {value: 3, label: "3", id: "CGIS3"},
                                ]
                            }
                            onChange={onChange}
                            readOnly={readOnly}
                        />
                    </td>
                    <td>Легкое расстройство</td>
                </tr>
                <tr>
                    <td width="141" className="text-center">
                        <Field
                            name={"CGIS"}
                            component={renderRadio}
                            className="d-flex align-items-center justify-content-center"
                            options={
                                [
                                    {value: 4, label: "4", id: "CGIS4"},
                                ]
                            }
                            onChange={onChange}
                            readOnly={readOnly}
                        />
                    </td>
                    <td>Умеренно выраженное расстройство</td>
                </tr>
                <tr>
                    <td width="141" className="text-center">
                        <Field
                            name={"CGIS"}
                            component={renderRadio}
                            className="d-flex align-items-center justify-content-center"
                            options={
                                [
                                    {value: 5, label: "5", id: "CGIS5"},
                                ]
                            }
                            onChange={onChange}
                            readOnly={readOnly}
                        />
                    </td>
                    <td>Выраженное расстройство</td>
                </tr>
                <tr>
                    <td width="141" className="text-center">
                        <Field
                            name={"CGIS"}
                            component={renderRadio}
                            className="d-flex align-items-center justify-content-center"
                            options={
                                [
                                    {value: 6, label: "6", id: "CGIS6"},
                                ]
                            }
                            onChange={onChange}
                            readOnly={readOnly}
                        />
                    </td>
                    <td>Тяжелое расстройство</td>
                </tr>
                <tr>
                    <td width="141" className="text-center">
                        <Field
                            name={"CGIS"}
                            component={renderRadio}
                            className="d-flex align-items-center justify-content-center"
                            options={
                                [
                                    {value: 7, label: "7", id: "CGIS7"},
                                ]
                            }
                            onChange={onChange}
                            readOnly={readOnly}
                        />
                    </td>
                    <td>Крайне тяжелое расстройство</td>
                </tr>
                </tbody>
            </Table>
        </Col>
    </Row>
);

class DiseasesInfoForm extends PureComponent {
    static defaultProps = {
        readOnly: false
    };

    onChange = () => this.props._onChange(this.props.handleSubmit(this.props.onSubmit));
    onCheckboxChange = () => this.props._onCheckboxChange(this.props.handleSubmit(this.props.onSubmit));

    render() {
        const {
            readOnly
        } = this.props;

        return (
            <form className={"form form_small"}>
                <Row>
                    <Col xs={12} className={"visitContainer__separator"}/>
                </Row>
                <Row>
                    <Col xs={12} className={"mb-3"}>
                        <Field
                            name={"NRDIAG"}
                            component={renderRadio}
                            options={
                                [{value: 1, label: "Тревожное", id: "NRDIAG_1"},
                                    {value: 2, label: "Депрессивное", id: "NRDIAG_2"},
                                    {value: 3, label: "Тревожно-депрессивное", id: "NRDIAG_3"}]
                            }
                            label={"Диагноз невротических реакций тревожно–депрессивного спектра:"}
                            onChange={this.onCheckboxChange}
                            readOnly={readOnly}
                        />
                    </Col>
                </Row>
                <AnxietyForm
                    onChange={this.onCheckboxChange}
                    readOnly={readOnly}
                />
                <Row>
                    <Col xs={12}>
                        <div className="visitContainer__header visitContainer__header_success mb-0">
                            <h5>Факторы риска сердечно-сосудистых заболеваний (ССЗ)</h5>
                        </div>
                    </Col>
                    <Col className="mb-4" xs={12}>
                        <Table bordered>
                            <tbody>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CD1"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "CD1_1"},
                                                {value: 77, label: "Нет", id: "CD1_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Гиперхолестеринемия</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CD2"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "CD2_1"},
                                                {value: 77, label: "Нет", id: "CD2_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>{'Семейный анамнез ранних ССЗ (у мужчин <55 лет; у женщин <65 лет)'}</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CD3"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "CD3_1"},
                                                {value: 77, label: "Нет", id: "CD3_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Сахарный диабет</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"CD4"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "CD4_1"},
                                                {value: 77, label: "Нет", id: "CD4_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Метаболический синдром </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={12}>
                        <div className="visitContainer__header visitContainer__header_success mb-0">
                            <h5>Поражение органов мишеней</h5>
                        </div>
                    </Col>
                    <Col className="mb-4" xs={12}>
                        <Table className={"m-0"} bordered>
                            <tbody>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"TOD1"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "TOD1_1"},
                                                {value: 77, label: "Нет", id: "TOD1_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Гипертрофия левого желудочка</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"TOD2"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "TOD2_1"},
                                                {value: 77, label: "Нет", id: "TOD2_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Атеросклеротическое поражение магистральных сосудов (УЗ-признаки)</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"TOD3"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "TOD3_1"},
                                                {value: 77, label: "Нет", id: "TOD3_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>{'Гиперкреатининемия (115–133 мкмоль/л (1,3–1,5 мг/дл) для мужчин или 107–124 мкмоль/л (1,2–1,4 мг/дл) для женщин)'}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={12} className={"visitContainer__separator"}/>
                    <Col xs={12} md={4}>
                        <Field
                            name={"LBCR"}
                            component={renderInput}
                            label={"Уровень креатинина в сыворотке крови:"}
                            validate={[number, minValue]}
                            subtext={"мкмоль/л"}
                            onChange={this.onChange}
                            readOnly={readOnly}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="visitContainer__header visitContainer__header_success mb-0">
                            <h5>Ассоциированные клинические состояния</h5>
                        </div>
                    </Col>
                    <Col className="mb-4" xs={12}>
                        <Table className={"m-0"} bordered>
                            <tbody>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"ACC1"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "ACC1_1"},
                                                {value: 77, label: "Нет", id: "ACC1_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Цереброваскулярная болезнь (ЦВБ)</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"ACC2"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "ACC2_1"},
                                                {value: 77, label: "Нет", id: "ACC2_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Перенесенный инсульт</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"ACC3"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "ACC3_1"},
                                                {value: 77, label: "Нет", id: "ACC3_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Ишемическая болезнь сердца (ИБС)</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"ACC4"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "ACC4_1"},
                                                {value: 77, label: "Нет", id: "ACC4_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Стенокардия</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"ACC5"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "ACC5_1"},
                                                {value: 77, label: "Нет", id: "ACC5_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Постинфарктный кардиосклероз (ПИКС)</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"ACC6"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "ACC6_1"},
                                                {value: 77, label: "Нет", id: "ACC6_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Нарушения ритма и проводимости</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"ACC7"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "ACC7_1"},
                                                {value: 77, label: "Нет", id: "ACC7_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Хроническая сердечная недостаточность (ХСН)</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"ACC8"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "ACC8_1"},
                                                {value: 77, label: "Нет", id: "ACC8_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Безболевая ишемия миокарда</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"ACC9"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "ACC9_1"},
                                                {value: 77, label: "Нет", id: "ACC9_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Почечная недостаточность</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"ACC10"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "ACC10_1"},
                                                {value: 77, label: "Нет", id: "ACC10_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Заболевания периферических артерий</td>
                            </tr>
                            <tr>
                                <td width="141">
                                    <Field
                                        name={"ACC11"}
                                        component={renderRadio}
                                        className="d-flex align-items-center justify-content-between"
                                        options={
                                            [
                                                {value: 1, label: "Да", id: "ACC11_1"},
                                                {value: 77, label: "Нет", id: "ACC11_2"},
                                            ]
                                        }
                                        onChange={this.onCheckboxChange}
                                        readOnly={readOnly}
                                    />
                                </td>
                                <td>Гипертоническая ретинопатия</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <CGISForm
                    onChange={this.onCheckboxChange}
                    readOnly={readOnly}
                />
                <Row>
                    <Col xs={12}>
                        <div
                            className="visitContainer__header visitContainer__header_success d-flex justify-content-between align-items-center flex-nowrap">
                            <h5>Сопутствующие заболевания</h5>
                            <Field
                                name={"MHYN"}
                                component={renderCheckbox}
                                id={"MHYN"}
                                defaultChecked={77}
                                label={"Не зарегистрированы"}
                                onChange={this.onChange}
                                readOnly={readOnly}
                            />
                        </div>
                    </Col>
                </Row>
            </form>
        )
    }
}

DiseasesInfoForm = reduxForm({
    form: 'visit1_diseases_info_form'
})(DiseasesInfoForm);

const mapStateToProps = (store, props) => ({
    initialValues: {
        NRDIAG: getStoreData(() => props.data.nrdiag),
        HADS1: getStoreData(() => props.data.hads1),
        HADS2: getStoreData(() => props.data.hads2),
        HADS3: getStoreData(() => props.data.hads3),
        HADS4: getStoreData(() => props.data.hads4),
        HADS5: getStoreData(() => props.data.hads5),
        HADS6: getStoreData(() => props.data.hads6),
        HADS7: getStoreData(() => props.data.hads7),
        HADS8: getStoreData(() => props.data.hads8),
        HADS9: getStoreData(() => props.data.hads9),
        HADS10: getStoreData(() => props.data.hads10),
        HADS11: getStoreData(() => props.data.hads11),
        HADS12: getStoreData(() => props.data.hads12),
        HADS13: getStoreData(() => props.data.hads13),
        HADS14: getStoreData(() => props.data.hads14),
        CD1: getStoreData(() => props.data.cd1),
        CD2: getStoreData(() => props.data.cd2),
        CD3: getStoreData(() => props.data.cd3),
        CD4: getStoreData(() => props.data.cd4),
        TOD1: getStoreData(() => props.data.tod1),
        TOD2: getStoreData(() => props.data.tod2),
        TOD3: getStoreData(() => props.data.tod3),
        LBCR: getStoreData(() => props.data.lbcr),
        ACC1: getStoreData(() => props.data.acc1),
        ACC2: getStoreData(() => props.data.acc2),
        ACC3: getStoreData(() => props.data.acc3),
        ACC4: getStoreData(() => props.data.acc4),
        ACC5: getStoreData(() => props.data.acc5),
        ACC6: getStoreData(() => props.data.acc6),
        ACC7: getStoreData(() => props.data.acc7),
        ACC8: getStoreData(() => props.data.acc8),
        ACC9: getStoreData(() => props.data.acc9),
        ACC10: getStoreData(() => props.data.acc10),
        ACC11: getStoreData(() => props.data.acc11),
        CGIS: getStoreData(() => props.data.cgis),
        MHYN: getStoreData(() => props.data.mhyn),
        A_RES: getStoreData(() => props.data.a_res),
        D_RES: getStoreData(() => props.data.d_res),
    },
    enableReinitialize: true
});

export default connect(mapStateToProps)(DiseasesInfoForm);
