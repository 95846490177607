import React from 'react';
import {Container, Row} from 'reactstrap';
import Card from './components/Card';
import './styles/styles.scss';
import propTypes from 'prop-types';

import {USERS_INV_PATH, ADMIN_PATH, MP_PATH, SUB_USER_PATH, ADMIN_USERS_PATH} from "../../config/baseConstants";

//главная страница пользователей с ролью ADMIN, SYS_ADMIN
export default function Admin({history}) {
    function redirect(path = '/') {
        history.push(path)
    }

    return (
        <Container className="pt-4 pb-2 mt-lg-5 h-100">
            <Row className="pt-md-5">
                <Card
                    title={'Администраторы'}
                    icon="user-cog"
                    onClick={() => redirect(ADMIN_PATH + ADMIN_USERS_PATH)}
                />
                <Card
                    title={'МП'}
                    icon={"user-astronaut"}
                    onClick={() => redirect(ADMIN_PATH + MP_PATH)}
                />
                <Card
                    icon={"user-md"}
                    title={'Исследователи'}
                    onClick={() => redirect(ADMIN_PATH + USERS_INV_PATH)}
                />
                <Card
                    icon={"sad-tear"}
                    title={'Пациенты'}
                    prefix={'far'}
                    onClick={() => redirect(ADMIN_PATH + SUB_USER_PATH)}
                />
            </Row>
        </Container>
    )
}

Admin.propTypes = {
    history: propTypes.object.isRequired
};
