import React, {PureComponent} from 'react';
import {Col, Row} from "reactstrap";
import {Field, formValueSelector, reduxForm} from "redux-form";

//constants
import {DATE_MASK} from "../../../../config/baseConstants";
import {dateLengthValidation} from "../../../../utils/formValidation";

//utils
import {getStoreData} from "../../../../utils/getStoreData";
import {minValue, maxWeightValue, number} from "../../../../utils/formValidation";

//components
import renderRadio from "../../../_commonComponents/form/RadioGroupWithValidation";
import renderCheckbox from "../../../_commonComponents/form/CheckBox";
import renderInput from "../../../_commonComponents/form/InputWithValidation";

//redux
import {connect} from 'react-redux';

class PatientsInfoForm extends PureComponent {
    static defaultProps = {
        readOnly: false
    };

    onChange = () => this.props._onChange(this.props.handleSubmit(this.props.onSubmit));
    onCheckboxChange = () => this.props._onCheckboxChange(this.props.handleSubmit(this.props.onSubmit));

    render() {
        const {
            name,
            isTobacco,
            initialValues,
            readOnly
        } = this.props;

        return (
            <form className={"form form_small"}>
                <Row>
                    <Col xs={12} className="mb-2">
                        <div className="visitContainer__header visitContainer__header_success">
                            <h5>Пациент № <span className="text-uppercase">{name}</span></h5>
                        </div>
                    </Col>
                    <Col xs={12} md={8}>
                        <Field
                            name={"VISDTC"}
                            component={renderInput}
                            label={"Дата начала приема препарата Тенотен (по 2 таблетки 3 раза в день):"}
                            subtext={"дд.мм.гггг"}
                            mask={DATE_MASK}
                            validate={[dateLengthValidation]}
                            onChange={this.onChange}
                            readOnly={readOnly}
                        />
                    </Col>
                    <Col xs={12} md={4}/>
                    <Col xs={12} md={4}>
                        <Field
                            name={"CITY"}
                            component={renderInput}
                            label={"Город проживания:"}
                            onChange={this.onChange}
                            readOnly={readOnly}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Field
                            name={"AGE"}
                            component={renderInput}
                            label={"Возраст:"}
                            validate={[number, minValue]}
                            subtext={"полных лет"}
                            onChange={this.onChange}
                            readOnly={readOnly}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Field
                            name={"SEX"}
                            component={renderRadio}
                            options={[{value: 1, label: "Мужской", id: "sex_1"}, {
                                value: 2,
                                label: "Женский",
                                id: "sex_2"
                            }]}
                            label={"Пол:"}
                            onChange={this.onCheckboxChange}
                            readOnly={readOnly}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Field
                            name={"HEIGHT"}
                            component={renderInput}
                            label={"Рост:"}
                            validate={[number, minValue]}
                            subtext={"в метрах"}
                            mask={"9.99"}
                            onChange={this.onChange}
                            readOnly={readOnly}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Field
                            name={"WEIGHT"}
                            component={renderInput}
                            label={"Вес:"}
                            validate={[number, minValue, maxWeightValue]}
                            subtext={"в кг"}
                            onChange={this.onChange}
                            readOnly={readOnly}
                        />
                    </Col>
                    <Col xs={12} md={4} key={getStoreData(() => initialValues.bmi)}>
                        <Field
                            name={"bmi"}
                            component={renderInput}
                            label={"ИМТ:"}
                            subtext={"кг/м2 (рассчитывается автоматически)"}
                            readOnly
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Field
                            name={"HR"}
                            component={renderInput}
                            label={"ЧСС:"}
                            validate={[number, minValue]}
                            subtext={"уд/мин"}
                            onChange={this.onChange}
                            readOnly={readOnly}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Field
                            name={"SYSBP"}
                            component={renderInput}
                            label={"Систолическое давление:"}
                            subtext={"мм.рт.ст."}
                            validate={[number, minValue]}
                            onChange={this.onChange}
                            readOnly={readOnly}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Field
                            name={"DIABP"}
                            component={renderInput}
                            label={"Диастолическое давление:"}
                            subtext={"мм.рт.ст."}
                            validate={[number, minValue]}
                            onChange={this.onChange}
                            readOnly={readOnly}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Field
                            name={"TOBACCO"}
                            component={renderRadio}
                            options={[{
                                value: 1,
                                label: "Да, укажите количество сигарет в день",
                                id: "TOBACCO_1"
                            }, {value: 77, label: "Нет", id: "TOBACCO_2"}]}
                            label={"Пациент курит:"}
                            onChange={this.onCheckboxChange}
                            readOnly={readOnly}
                        />
                    </Col>
                    {isTobacco && <Col xs={12} md={4}>
                        <Field
                            name={"SUDOSE"}
                            component={renderInput}
                            label={"Количество сигарет в день:"}
                            validate={[number, minValue]}
                            subtext={"штук"}
                            onChange={this.onChange}
                            readOnly={readOnly}
                        />
                    </Col>}
                    <Col xs={12} className="mb-2 mt-4">
                        <div className="visitContainer__header visitContainer__header_success">
                            <h5>Диагноз основного заболевания (АГ)</h5>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className={"mb-3"}>
                        <Field
                            name={"MDDIAG"}
                            component={renderRadio}
                            options={[
                                {value: 1, label: "1 степень (САД 140–159 и/или ДАД 90–99)", id: "MDDIAG_1"},
                                {value: 2, label: "2 степень (САД 160–179 и/или ДАД 100–109)", id: "MDDIAG_2"},
                                {value: 3, label: "3 степень (САД≥180 и/или ДАД≥110)", id: "MDDIAG_3"},
                            ]}
                            label={"Эссенциальная (первичная) гипертензия"}
                            onChange={this.onCheckboxChange}
                            readOnly={readOnly}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Field
                            name={"MDDAT"}
                            component={renderInput}
                            label={"Дата установления диагноза:"}
                            mask={DATE_MASK}
                            validate={[dateLengthValidation]}
                            subtext={"дд.мм.гггг"}
                            onChange={this.onChange}
                            readOnly={readOnly}
                        />
                    </Col>
                    <Col xs={12} md={2}/>
                    <Col xs={12} className={"mb-3"}>
                        <Field
                            name={"MDCONTR"}
                            component={renderRadio}
                            label={"Контролируемая?"}
                            options={[
                                {value: 1, label: "Да", id: "MDCONTR_1"},
                                {value: 77, label: "Нет", id: "MDCONTR_2"},
                            ]}
                            onChange={this.onCheckboxChange}
                            readOnly={readOnly}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col  xs={12} className="mb-2">
                        <div className="visitContainer__header visitContainer__header_success d-flex justify-content-between">
                            <h5>Терапия основного заболевания (АГ)</h5>
                            <Field
                                name={"MDMYN"}
                                component={renderCheckbox}
                                id={"MDMYN"}
                                defaultChecked={77}
                                label={"не проводится"}
                                onChange={this.onChange}
                                readOnly={readOnly}
                            />
                        </div>
                    </Col>
                </Row>
            </form>
        )
    }
}

PatientsInfoForm = reduxForm({
    form: 'visit1_patients_info_form'
})(PatientsInfoForm);

const selector = formValueSelector('visit1_patients_info_form');

const mapDispatchToProps = (store, props) => ({
    // eslint-disable-next-line
    isTobacco: selector(store, 'TOBACCO') && selector(store, 'TOBACCO') == 1,
    initialValues: {
        VISDTC: getStoreData(() => props.data.visdtc),
        CITY: getStoreData(() => props.data.city),
        AGE: getStoreData(() => props.data.age),
        SEX: getStoreData(() => props.data.sex),
        HEIGHT: getStoreData(() => props.data.height),
        WEIGHT: getStoreData(() => props.data.weight),
        HR: getStoreData(() => props.data.hr),
        SYSBP: getStoreData(() => props.data.sysbp),
        DIABP: getStoreData(() => props.data.diabp),
        TOBACCO: getStoreData(() => props.data.tobacco),
        SUDOSE: getStoreData(() => props.data.sudose),
        MDDIAG: getStoreData(() => props.data.mddiag),
        MDDAT: getStoreData(() => props.data.mddat),
        MDMYN: getStoreData(() => props.data.mdmyn),
        bmi: getStoreData(() => props.data.bmi),
        MDCONTR: getStoreData(() => props.data.mdcontr),
    },
    enableReinitialize: true
});


export default connect(mapDispatchToProps)(PatientsInfoForm);
