import React from 'react';
import PropTypes from 'prop-types';

const renderTextAreaWithValidation = ({
                                          height, input, style, placeholder, readOnly, type, meta: { touched, error },label
                                      }) => (
    <div className="form-group">
        {label && <label >{label}</label>}
        <textarea style={{ 'height': height ? height : 'auto' }} {...input} placeholder={placeholder} readOnly={readOnly} className={"form-control"} rows="10" cols="40"/>
        {touched && error && <span className="invalid-feedback">{error}</span>}
    </div>
);

renderTextAreaWithValidation.propTypes = {
    input: PropTypes.shape().isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    readOnly: PropTypes.bool,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
};

renderTextAreaWithValidation.defaultProps = {
    placeholder: '',
    meta: null,
    type: 'text',
    readOnly: false,
};

export default renderTextAreaWithValidation;
