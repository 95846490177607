import React, {PureComponent} from 'react';
import {Col, Row} from "reactstrap";
import {Field, reduxForm} from "redux-form";
import debounce from 'debounce';

//utils
import {getStoreData} from "../../../utils/getStoreData";
import {dateLengthValidation} from "../../../utils/formValidation";

//redux
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import {fetchVisit1TherapyData} from "../../../redux/actions/visit1/visit1TherapyAction";

//components
import renderInput from "../../_commonComponents/form/InputWithValidation";
import renderCheckbox from '../../_commonComponents/form/CheckBox';

//constants
import {SET} from '../../../config/requestConstants';
import {DATE_MASK} from "../../../config/baseConstants";
import DeleteModal from "../../_commonComponents/DeleteModal";

class TherapyForm extends PureComponent {

    static _onChange = (callback) => {
        callback();
    };

    static _onCheckboxChange = (callback) => {
        callback();
    };

    constructor(props) {
        super(props);

        TherapyForm._onChange = debounce(TherapyForm._onChange, 600);
        TherapyForm._onCheckboxChange = debounce(TherapyForm._onCheckboxChange, 50);
    }

    onChange = async (values) => {
        const {
            fetchVisit1TherapyData
        } = this.props;

        await fetchVisit1TherapyData(SET, [], values, false);
    };



    onHandleChange = () => TherapyForm._onChange(this.props.handleSubmit(this.onChange));
    onCheckboxChange = () => TherapyForm._onCheckboxChange(this.props.handleSubmit(this.onChange));

    render() {
        const {
            form,
            readOnly,
            handleSubmit,
            onDelete,
            initialValues
        } = this.props;

        return (
                <Col xs={12} md={6} className={"mb-4"}>
                    <form className={"form form_small"}>
                    <div className={"visitCard"}>
                        <Row>
                            {!readOnly && <DeleteModal
                                onDelete={handleSubmit(onDelete)}
                                name={`${getStoreData(() => initialValues.num)}`}
                            />}
                            <Col xs={2} className={"pr-0"}>
                                <Field
                                    name={"num"}
                                    component={renderInput}
                                    label={"№"}
                                    className={"p-0 text-center"}
                                    readOnly
                                />
                            </Col>
                            <Col xs={10}>
                                <Field
                                    name={"mdmtrt"}
                                    component={renderInput}
                                    label={"Торговое название:"}
                                    onChange={this.onHandleChange}
                                    readOnly={readOnly}
                                />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    name={"mdmdose"}
                                    component={renderInput}
                                    label={"Суточная доза:"}
                                    onChange={this.onHandleChange}
                                    readOnly={readOnly}
                                />
                            </Col>
                            <Col xs={6}>
                                <Field
                                    name={"mdmstdat"}
                                    component={renderInput}
                                    label={"Дата начала:"}
                                    mask={DATE_MASK}
                                    validate={[dateLengthValidation]}
                                    onChange={this.onHandleChange}
                                    subtext={"дд.мм.гггг"}
                                    readOnly={readOnly}
                                />
                            </Col>
                            <Col xs={6}>
                                <Field
                                    name={"mdmendat"}
                                    component={renderInput}
                                    label={"Дата завершения:"}
                                    mask={DATE_MASK}
                                    validate={[dateLengthValidation]}
                                    onChange={this.onHandleChange}
                                    subtext={"дд.мм.гггг"}
                                    readOnly={readOnly}
                                />
                            </Col>
                            <Col xs={12} className="d-flex justify-content-end">
                                <Field
                                    name={"mdmongo"}
                                    component={renderCheckbox}
                                    id={`mdmongo${form}`}
                                    defaultChecked={1}
                                    label={"Продолжается"}
                                    onChange={this.onCheckboxChange}
                                    readOnly={readOnly}
                                />
                            </Col>
                        </Row>
                    </div>
                    </form>
                </Col>
        )
    }

}

TherapyForm = reduxForm({
    destroyOnUnmount: true,
    enableReinitialize: true
})(TherapyForm);

const mapStateToProps = (store, props) => ({
    form: props.form,
    initialValues: {
        id: getStoreData(() => props.data.id),
        vers: getStoreData(() => props.data.vers),
        num: getStoreData(() => props.data.num),
        mdmtrt: getStoreData(() => props.data.mdmtrt),
        mdmdose: getStoreData(() => props.data.mdmdose),
        mdmendat: getStoreData(() => props.data.mdmendat),
        mdmongo: getStoreData(() => props.data.mdmongo),
        mdmstdat: getStoreData(() => props.data.mdmstdat),
        sub: getStoreData(() => props.data.sub),
    }
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchVisit1TherapyData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TherapyForm);
