import 'cross-fetch/polyfill';
import {UrlCreator, formDataCreator} from '../../utils/requestCreator';
import {SERVICE, RESET_PASS_OBJECT, PASS_RESET_FUNCTION} from '../../config/requestConstants';
import {getStoreData} from '../../utils/getStoreData';
import showErrorNotification from '../../containers/_commonComponents/notification/showErrorNotification';
import showNotification from '../../containers/_commonComponents/notification/showNotification';

export const PASS_IS_LOADING = 'PASS_IS_LOADING';
export const PASS_IS_FAILED= 'PASS_IS_FAILED';
export const PASS_IS_SUCCESS= 'PASS_IS_SUCCESS';


function isLoading() {
    return {
        type: PASS_IS_LOADING
    }
}

function isFailed() {
    return {
        type: PASS_IS_FAILED
    }
}

function isSuccess(data, key) {
    return {
        type: PASS_IS_SUCCESS,
        payload: data,
        key
    }
}

export function fetchResetPassData(data={}) {
    const URL = UrlCreator(SERVICE);
    return (dispatch) => {
        let initialKey = localStorage.token;
        const requestData = formDataCreator(null, RESET_PASS_OBJECT, data, initialKey, [], null, null, null, PASS_RESET_FUNCTION);
        dispatch(isLoading());
        return fetch(
            URL, {
                method: 'POST',
                body: requestData,
            }
        ).then((response) => {
            if (response.status !== 200) {
                showErrorNotification(data);
                localStorage.clear();
                dispatch(isFailed());
                throw Error(response.statusText);
            }
            dispatch(isLoading());
            return response.json();
        }).then((data) => {

            if (data.status !== "0") {
                showErrorNotification(data);
                dispatch(isFailed());
                return;
            }

            const key =  getStoreData(() => data.skey);
            localStorage.token = key;
            dispatch(isSuccess(data, key));
            showNotification('primary', ' ', 'Новый пароль отправлен на указанный номер')
        }).catch((e) => {
            dispatch(isFailed());
            console.error(e);
        })
    };
}
