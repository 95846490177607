import React, {PureComponent} from 'react';
import FromHeader from './components/FormHeader';
import {reduxForm, reset} from "redux-form";
import {bindActionCreators} from "redux";
import { connect } from "react-redux";
import Modal from "../Modal";
import {getStoreData} from "../../../utils/getStoreData";
import TableHeader from "./components/TableHeader";


class From extends PureComponent {
    static defaultProps = {
        resetPass: null
    };

    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false
        }
    }

    onReset = () => {
        const {
            reset,
            form
        } = this.props;

        reset(form);
    };

    onDeletePopupOpen = () => {
        this.setState(prevState => ({
            isModalOpen: !prevState.isModalOpen
        }))
    };

    onDelete = async () => {
        await this.props.onDelete();
        this.onDeletePopupOpen();
    };

    render() {
        const {
            children,
            onToggle,
            handleSubmit,
            onSubmit,
            form,
            initialValues,
            resetPass
        } = this.props;

        const {
            isModalOpen
        } = this.state;

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <FromHeader
                    onToggle={onToggle}
                    onReset={this.onReset}
                    onDelete={this.onDeletePopupOpen}
                    resetPass={resetPass}
                />
                <div className="tableForm">
                    {children}
                </div>
            </div>
                <Modal
                    color={'danger'}
                    isOpen={isModalOpen}
                    form={`delete_inv_usrt_form_${getStoreData(() => form.id)}`}
                    toggle={this.onDeletePopupOpen}
                    submit={this.onDelete}
                    submitText={"Подтвердить"}
                    title={"Подтвердите удаление пользователя"}
                    message={`Вы уверенны, что хотите удалить пользователя ${getStoreData(() => initialValues.title) ? initialValues.title : ''}?`}
                />
            </form>
        )
    }
}

From = reduxForm({
    destroyOnUnmount: false,
})(From);

const mapStateToProps = (store, props) => ({
    form: props.form,
    initialValues: props.initialValues,
    enableReinitialize: true,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    reset
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(From);
