import React from 'react';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classnames from 'classnames';

const IconButton = ({className, icon, style, outline, color, onClick, disabled, type = "button", text = null}) => (
    <Button
        type={type}
        outline={outline}
        color={color}
        className={classnames('p-2',className)}
        style={{style}}
        onClick={onClick}
        disabled={disabled}
    >
        {text ? text : <FontAwesomeIcon icon={icon} />}
    </Button>
);

export default IconButton;
