import Admin from "../containers/admin/index";
import Login from "../containers/login/index";
import Management from "../containers/adminManagement/index";
import UsersInv from '../containers/invManagement/index';
import Patients from "../containers/investigator/index";
import Visit1 from '../containers/visit1/index';
import Visit2 from '../containers/visit2/index';
import UsersMR from '../containers/mrManagement/index';
import SubUsers from '../containers/subManagement/index';
import SubUsersMR from '../containers/subManagementMR/index'
import Training from '../containers/training/index';

export const COMPONENTS = [
    {title: '/', component: Login},
    {title: 'login', component: Login},
    {title: 'admin', component: Admin},
    {title: 'users_admin', component: Management},
    {title: 'users_inv', component: UsersInv},
    {title: 'inv_subs', component: Patients},
    {title: 'visit_1', component: Visit1},
    {title: 'visit_2', component: Visit2},
    {title: 'users_mp', component: UsersMR},
    {title: 'users_sub', component: SubUsers},
    {title: 'mp_subs', component: SubUsersMR},
    {title: 'training', component: Training}
];
