import React, {PureComponent} from 'react';
import {Col, Row} from "reactstrap";

//utils
import {getStoreData} from "../../../utils/getStoreData";
import {dateLengthValidation} from "../../../utils/formValidation";

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Field, reduxForm} from 'redux-form';
import {fetchVisit2EventsData} from "../../../redux/actions/visit2/visit2EventsAction";

//compoentns
import renderCheckbox from "../../_commonComponents/form/CheckBox";
import renderTextarea from '../../_commonComponents/form/textareaWithValidation';
import renderRadio from "../../_commonComponents/form/RadioGroupWithValidation"
import renderInput from "../../_commonComponents/form/InputWithValidation";

//constants
import {SET} from "../../../config/requestConstants";
import {DATE_MASK} from "../../../config/baseConstants";
import DeleteModal from "../../_commonComponents/DeleteModal";

class EventsForm extends PureComponent {
    static defaultProps = {
        readOnly: false
    };

    onChange = async (values) => {
        const {
            fetchVisit2EventsData,
        } = this.props;

        await fetchVisit2EventsData(SET, [], values, false);
    };

    onHandleChange = () => this.props._onChange(this.props.handleSubmit(this.onChange));
    onCheckboxChange = () => this.props._onCheckboxChange(this.props.handleSubmit(this.onChange));

    render() {
        const {
            form,
            readOnly,
            handleSubmit,
            onDelete,
            initialValues
        } = this.props;

        return (
            <Col xs={12} md={6} className={"mb-4"}>
                <form className={"form form_small"}>
                    <div className={"visitCard"}>
                        <Row>
                            {!readOnly && <DeleteModal
                                onDelete={handleSubmit(onDelete)}
                                name={`${getStoreData(() => initialValues.num)}`}
                                readOnly={readOnly}
                            />}
                            <Col xs={2}>
                                <Field
                                    name={"num"}
                                    component={renderInput}
                                    label={"№"}
                                    className={"p-0 text-center"}
                                    readOnly
                                />
                            </Col>
                            <Col xs={5} className="pl-4">
                                <Field
                                    name={"AESEV"}
                                    component={renderRadio}
                                    label={"Степень тяжести"}
                                    options={
                                        [
                                            {id: `AESEV_${form}1`, label: "Легкая", value: 1},
                                            {id: `AESEV_${form}2`, label: "Средняя", value: 2},
                                            {id: `AESEV_${form}3`, label: "Тяжелая", value: 3},
                                        ]}
                                    onChange={this.onCheckboxChange}
                                    readOnly={readOnly}
                                />
                            </Col>
                            <Col xs={5}>
                                <Field
                                    name={"AERELYN"}
                                    component={renderRadio}
                                    label={"Связь с Тенотеном"}
                                    options={
                                        [
                                            {id: `AERELYN_${form}1`, label: "Связано", value: 1},
                                            {id: `AERELYN_${form}2`, label: "Не связано", value: 2},
                                        ]}
                                    onChange={this.onCheckboxChange}
                                    readOnly={readOnly}
                                />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    name={"AETERM"}
                                    component={renderTextarea}
                                    height={96}
                                    label={"Описание"}
                                    onChange={this.onHandleChange}
                                    readOnly={readOnly}
                                />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    name={"AEOUT"}
                                    component={renderTextarea}
                                    height={96}
                                    label={"Исход"}
                                    onChange={this.onHandleChange}
                                    readOnly={readOnly}
                                />
                            </Col>
                            <Col xs={6}>
                                <Field
                                    name={"AESTDAT"}
                                    component={renderInput}
                                    label={"Дата начала:"}
                                    mask={DATE_MASK}
                                    validate={[dateLengthValidation]}
                                    subtext={"дд.мм.гггг"}
                                    onChange={this.onHandleChange}
                                    readOnly={readOnly}
                                />
                            </Col>
                            <Col xs={6}>
                                <Field
                                    name={"AEENDAT"}
                                    component={renderInput}
                                    label={"Дата завершения:"}
                                    mask={DATE_MASK}
                                    validate={[dateLengthValidation]}
                                    onChange={this.onHandleChange}
                                    subtext={"дд.мм.гггг"}
                                    readOnly={readOnly}
                                />
                            </Col>
                            <Col xs={12} className="d-flex justify-content-end">
                                <Field
                                    name={"aeongo"}
                                    component={renderCheckbox}
                                    id={`aeongo${form}`}
                                    defaultChecked={1}
                                    label={"Продолжается"}
                                    onChange={this.onCheckboxChange}
                                    readOnly={readOnly}
                                />
                            </Col>
                        </Row>
                    </div>
                </form>
            </Col>
        )
    }
}

EventsForm = reduxForm({
    destroyOnUnmount: true,
    enableReinitialize: true
})(EventsForm);

const mapStateToProps = (state, props) => ({
    form: props.form,
    initialValues: {
        id: getStoreData(() => props.data.id),
        vers: getStoreData(() => props.data.vers),
        num: getStoreData(() => props.data.num),
        AESEV: getStoreData(() => props.data.aesev),
        AERELYN: getStoreData(() => props.data.aerelyn),
        AETERM: getStoreData(() => props.data.aeterm),
        AEOUT: getStoreData(() => props.data.aeout),
        AESTDAT: getStoreData(() => props.data.aestdat),
        AEENDAT: getStoreData(() => props.data.aeendat),
        aeongo: getStoreData(() =>props.data.aeongo),
        sub: getStoreData(() => props.data.sub)
    }
});

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchVisit2EventsData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EventsForm);
