import React, {PureComponent} from 'react';
import classnames from 'classnames';
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import Form from "../../_commonComponents/table/Form";
import {EDIT_NEW_MR_FORM} from "../../../config/baseConstants";
import {getStoreData} from "../../../utils/getStoreData";
import FormComponent from './FormComponent';
import NestedTable from './NestedTable';
import Table from "../../_commonComponents/table/Table";

export default class Tabs extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: '1',
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const {
            activeTab
        } = this.state;

        const {
            onSubmit,
            data,
            onDelete,
            isWideDesk,
            isMobile,
            onToggle,
            isTableRerender,
            resetPass,
            linkedTotal
        } = this.props;

        return (
      <div>
          <Nav tabs>
              <NavItem>
                  <NavLink
                      className={classnames({active: activeTab === '1'})}
                      onClick={() => {
                          this.toggle('1');
                      }}
                  >
                      МП
                  </NavLink>
              </NavItem>
              <NavItem>
                  <NavLink
                      className={classnames({active: activeTab === '2'})}
                      onClick={() => {
                          this.toggle('2');
                      }}
                  >
                      Исследователи
                      {linkedTotal ? <span className="tableHeader__count tableHeader__count_inline">{linkedTotal}</span> : <span/>}
                  </NavLink>
              </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                  <div >
                      <Form
                          onSubmit={onSubmit}
                          form={EDIT_NEW_MR_FORM}
                          initialValues={{
                              id: getStoreData(() => data.id),
                              vers: getStoreData(() => data.vers),
                              name: getStoreData(() => data.name),
                              title: getStoreData(() => data.title),
                              phone: getStoreData(() => data.phone),
                              dsc: getStoreData(() => data.dsc),
                          }}
                          onDelete={onDelete}
                          onToggle={onToggle}
                          resetPass={resetPass}
                      >
                          <FormComponent/>
                      </Form>
                  </div>
              </TabPane>
              <TabPane tabId="2">
                  <NestedTable
                      id={getStoreData(() => data.id)}
                      isWideDesk={isWideDesk}
                      isMobile={isMobile}
                      isRerender={isTableRerender}
                  />
              </TabPane>
          </TabContent>
      </div>

        )
    }
}
