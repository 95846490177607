export const returnBootstrapColor = (color) => {
  let bootstrapColor = '';
  if (color === 'success') {
    bootstrapColor = '#02B875'
  } else if (color === 'primary') {
    bootstrapColor = '#70bbfd'
  } else if (color === 'warning') {
    bootstrapColor = '#f0ad4e'
  } else if (color === 'danger') {
    bootstrapColor = '#d9534f'
  } else if (color === 'secondary') {
    bootstrapColor = '#adb5bd'
  } else {
    bootstrapColor = '#70bbfd'
  }
  return bootstrapColor;
};
