import React, {Fragment, PureComponent} from 'react';

//utils
import {getStoreData} from "../../../utils/getStoreData";

//component
import {Visit1} from "../../visit1/components/Visit1";

//constants
import Loading from "../../_commonComponents/Loading";

/**
 * Копмонент для отображения формы Визита 1
 */
class Visit1Container extends PureComponent {
    render() {
       const {
           selected,
           visitData,
           isLoading
       } = this.props;

       return (
           <Fragment>
               {isLoading && <Loading className="position-absolute"/>}
               <Visit1
                   id={getStoreData(() => selected.id)}
                   name={getStoreData(() => selected.name)}
                   data={visitData}
                   readOnly
               />
           </Fragment>
       )
   }
}

export default Visit1Container;
