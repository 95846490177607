import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import {HOMEPAGE} from "../../config/requestConstants";
import {connect} from 'react-redux';
import {getStoreData} from "../../utils/getStoreData";
import propTypes from 'prop-types';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';

class Header extends PureComponent {
    static propTypes = {
        menu: propTypes.array.isRequired,
        defaultPath: propTypes.string.isRequired,
    };

    static defaultProps = {
        defaultPath: '/'
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };
    }
    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };

    render() {
        const {menu, defaultPath, skey} = this.props;
        const {isOpen} = this.state;

        return (
            <Navbar color={"dark"} dark className="navbar-expand-lg">
                <Link className="navbar-brand text-uppercase" to={defaultPath}>Театр</Link>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        {menu && menu.map(item => {
                                if (item.type === 0) {
                                    return (
                                        <NavItem key={item.id} className="nav-item active">
                                            <Link className={"nav-link"} to={item.script}>{item.title}</Link>
                                        </NavItem>
                                    )
                                } else {
                                    return (
                                        <NavItem key={item.id} className="nav-item active">
                                            <NavLink key={item.id}
                                                     target={item.type === 2 ? '_blank' : '_self'}
                                                     href={`${HOMEPAGE}${item.script}?skey=${skey}`}>
                                                {item.title}
                                            </NavLink>
                                        </NavItem>
                                    )
                                }
                            }
                        )}
                    </Nav>
                </Collapse>
            </Navbar>
        )
    }
}

const mapStateToProps = (store) => ({
    skey: getStoreData(() => store.profile.key),
});

export default connect(mapStateToProps)(Header);
