import React, {PureComponent} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col} from "reactstrap";
import propTypes from 'prop-types';
import classnames from 'classnames';

//utils
import {returnBootstrapColor} from "../../../utils/returnBootstrapColor";
import {getStoreData} from "../../../utils/getStoreData";
import {getVisitCellClass} from "../../../utils/getVisitCellClass";

//redux
import {reset} from "redux-form";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {fetchPatientData} from "../../../redux/actions/patients/patientsAction";
import {selectRow} from "../../../redux/actions/selectedRowAction";

//components
import Table from '../../_commonComponents/table/Table';
import ModalForm from "../../_commonComponents/form/ModalForm";
import {FormContent, FormCommentContent} from './Form';

//constants
import {
    PATIENTS_FILTER_FORM,
    MD_SCREEN_WIDTH,
    SET_PATIENTS_FORM,
    ADD_PATIENTS_FORM
} from "../../../config/baseConstants";
import {ADD, SET} from "../../../config/requestConstants";

/**
 * Таблица рендеринга пациентов исследователя
 */
class PatientsTable extends PureComponent {
    static propTypes = {
        total: propTypes.number,
        fetchPatientData: propTypes.func.isRequired,
        tableData: propTypes.array,
        id: propTypes.number,
        dsc: propTypes.string,
        title: propTypes.string,
        history: propTypes.object.isRequired,
    };

    static defaultProps = {
        dsc: '',
        title: '',
        total: 0,
    };

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            tableKey: false,
            isPopupOpen: false,
            isAddPopupOpen: false,
        }
    }

    componentDidMount() {
        this.isMobile();
        window.addEventListener('resize', this.isMobile);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.isMobile);
    }

    isMobile = () => {
        const isMobile = window.innerWidth < MD_SCREEN_WIDTH;

        this.setState({
            isMobile
        });
    };

    /**
     * add new user
     * @param values {Object}
     * @return {Promise<void>}
     */
    onSubmit = async (values) => {
        const {
            fetchPatientData,
            id,
            reset,
            selectRow
        } = this.props;

        await fetchPatientData(ADD, [], null, null, null, {...values, ...{inv: id}}, false);

        this.setState(prevState => ({
            tableKey: !prevState.tableKey
        }), () => {
            selectRow(this.props.formData);
            reset(ADD_PATIENTS_FORM);
        })
    };

    /**
     * add comment to row
     * @param values {Object}
     * @return {Promise<void>}
     */
    addDescToRow = async (values) => {
        const {
            fetchPatientData,
            reset,
            selectedRow
        } = this.props;

        await fetchPatientData(SET, [], null, null, null, {...values, ...{id: getStoreData(() => selectedRow.id)}}, false);
        this.setState(prevState => ({
            tableKey: !prevState.tableKey}), () => {
            reset(SET_PATIENTS_FORM);
        })
    };

    /**
     * @param info {Object}
     */
    onDescPopupOpen = (info) => {
        const {
            selectRow
        } = this.props;

        selectRow(info.original);

        this.onPopupToggle(SET_PATIENTS_FORM);
    };

    onPopupToggle = () => {
        const {
            reset
        } = this.props;

        this.setState(prevState => ({
            isPopupOpen: !prevState.isPopupOpen
        }), () => {
            reset(SET_PATIENTS_FORM);
        })
    };

    /**
     * @param info {Object}
     */
    renderActionsComponent = (info) => (
        <FontAwesomeIcon className={"patients__action"} onClick={() => this.onDescPopupOpen(info)}
                         icon={["far", "comment"]}/>
    );

    /**
     * @param row {Object}
     * @param status {number}
     * @param path {string}
     * @return {*}
     */
    renderVisitCell = (status, path, row) => {
        return (
            <div key={path} className="d-flex justify-content-center">
                <FontAwesomeIcon className={classnames("patients__file", getVisitCellClass(status))}
                                 onClick={() => this.onVisitCellClick(status, path, row)} icon="file-alt"/>
            </div>
        )
    };

    /**
     * @param row {Object}
     * @param status {number}
     * @param path {string}
     */
    onVisitCellClick = (status, path, row) => {
        const {
            selectRow
        } = this.props;

        selectRow(row);
        const isLinkAvailable = status !== 2;
        isLinkAvailable && this.props.history.push({pathname: path, state: { row: row }})
    };

    openFormPopup = () => {
        const {
            reset
        } = this.props;

        this.setState(prevState => ({
            isAddPopupOpen: !prevState.isAddPopupOpen
        }), () => {
            reset(ADD_PATIENTS_FORM)
        })
    };

    get columns() {
        const {
            isMobile
        } = this.state;

        return [
            {
                ...!isMobile && {
                    Header: '',
                    accessor: 'num',
                    id: 'num',
                    filterable: false,
                    sortable: false,
                    isFilterable: false,
                    isSortable: false,
                    isVisible: true,
                    placeholder: '№',
                    visible: !isMobile,
                    width: 65,
                    maxWidth: 65,
                }
            },
            {
                Header: '',
                accessor: 'name',
                id: 'name',
                filterable: false,
                sortable: false,
                isFilterable: true,
                isSortable: true,
                isVisible: true,
                placeholder: 'Пациент',
                width: isMobile ? '100%' : 150,
                maxWidth: isMobile ? '100%' : 150,
            },
            {
                ...!isMobile && {
                    Header: '',
                    accessor: 'birth',
                    id: 'birth',
                    filterable: false,
                    sortable: false,
                    isFilterable: true,
                    isSortable: true,
                    isVisible: true,
                    placeholder: 'Дата рождения',
                    visible: !isMobile,
                    width: isMobile ? '100%' : 160,
                    maxWidth: isMobile ? '100%' : 160,
                }
            },
            {
                ...!isMobile && {
                    Header: '',
                    accessor: 'reg',
                    id: 'reg',
                    filterable: false,
                    sortable: false,
                    isFilterable: true,
                    isSortable: true,
                    isVisible: true,
                    placeholder: 'Дата регистрации',
                    visible: !isMobile,
                    width: isMobile ? '100%' : 175,
                    maxWidth: isMobile ? '100%' : 175,
                }
            },
            {
                Header: '',
                accessor: 'status_visit_1',
                id: 'status_visit_1',
                Cell: (info) => this.renderVisitCell(getStoreData(() => info.original.status_visit_1), `/inv/subs/visit_1/${getStoreData(() => info.original.id)}`, getStoreData(() => info.original)),
                filterable: false,
                sortable: false,
                isFilterable: false,
                isSortable: false,
                isVisible: true,
                placeholder: isMobile ? 'В1': 'Визит 1',
                width: isMobile ? 60 : 100,
                maxWidth: isMobile ? 60 : 100,
                position: 'center'
            },
            {
                Header: '',
                accessor: 'status_visit_2',
                id: 'status_visit_2',
                Cell: (info) => this.renderVisitCell(getStoreData(() => info.original.status_visit_2), `/inv/subs/visit_2/${getStoreData(() => info.original.id)}`, getStoreData(() => info.original)),
                filterable: false,
                sortable: false,
                isFilterable: false,
                isSortable: false,
                isVisible: true,
                placeholder: isMobile ? 'В2': 'Визит 2',
                width: isMobile ? 60 : 100,
                maxWidth: isMobile ? 60 : 100,
                position: 'center'
            },
            {
                ...!isMobile && {
                    Header: '',
                    accessor: 'dsc',
                    id: 'dsc',
                    filterable: false,
                    sortable: false,
                    isFilterable: false,
                    isSortable: false,
                    isVisible: true,
                    visible: !isMobile,
                    placeholder: 'Комментарий',
                }
            },
            {
                Header: '',
                accessor: 'actions',
                id: 'actions',
                Cell: this.renderActionsComponent,
                filterable: false,
                sortable: false,
                isFilterable: false,
                isSortable: false,
                isVisible: true,
                width: 50,
                maxWidth: 50,
            }
        ].filter(el => Object.keys(el).length > 0);
    }

    get filter() {
        const {
            id
        } = this.props;

        return [{name: "inv", value: id}]
    }

    get selectedRow() {
        const {
            selectedRow,
            tableData
        } = this.props;

        return selectedRow ? selectedRow : getStoreData(() => tableData[0])
    }

    render() {
        const {
            isPopupOpen,
            isAddPopupOpen,
            tableKey
        } = this.state;

        const {
            total,
            fetchPatientData,
            tableData,
            dsc,
            title,
            formData
        } = this.props;

        return (
            <div className={"tableWrapper d-flex justify-content-between w-100"}>
                <Table
                    columns={this.columns}
                    headerTitle="Пациенты"
                    headerIcon={"users"}
                    fetchAction={fetchPatientData}
                    tableData={tableData}
                    filterForm={PATIENTS_FILTER_FORM}
                    total={total}
                    selected={this.selectedRow}
                    initFilter={this.filter}
                    onRowClick={() => {}}
                    tableKey={tableKey}
                    icon={"users"}
                    isAddIconVisible={false}
                    title={"Пациенты"}
                    onToggle={this.openFormPopup}
                    count={total}
                    className="patients__column p-xl-0 col"
                    noDataText="Нет пациентов"
                >
                    <Col  className="patients__column patients__column_bg d-none d-xl-block pt-4 flex-grow-0 flex-shrink-0">
                        <div className={"cards m-0 mb-4"}>
                            <div className={"cards__block"}>
                                <h5 className={"cards__title"}>Вы вошли как:</h5>
                                <span className={"cards__content"}>{title}</span>
                            </div>
                            <div className={"cards__block"}>
                                <h5 className={"cards__title"}>Регион/город:</h5>
                                <span className={"cards__content"}>{dsc}</span>
                            </div>
                        </div>
                        <div className={"cards m-0"}>
                            <div className={"cards__row"}>
                                <FontAwesomeIcon color={returnBootstrapColor("primary")} icon="file-alt"/>
                                <span>Доступен для ввода данных</span>
                            </div>
                            <div className={"cards__row"}>
                                <FontAwesomeIcon color={returnBootstrapColor("danger")} icon="file-alt"/>
                                <span>Пациент заблокирован</span>
                            </div>
                            <div className={"cards__row"}>
                                <FontAwesomeIcon color={returnBootstrapColor("warning")} icon="file-alt"/>
                                <span>Данные введены</span>
                            </div>
                            <div className={"cards__row"}>
                                <FontAwesomeIcon color={returnBootstrapColor("success")} icon="file-alt"/>
                                <span>Данные подтверждены медицинским представителем</span>
                            </div>
                        </div>
                    </Col>
                </Table>
                <ModalForm
                    isOpen={isPopupOpen}
                    toggle={this.onPopupToggle}
                    onSubmit={this.addDescToRow}
                    form={SET_PATIENTS_FORM}
                    submitText={"Добавить комментарий"}
                    title={`Пациент номер ${getStoreData(() => this.selectedRow.name)}`}
                    icon={"user"}
                    initialValues={{dsc: this.selectedRow&&this.selectedRow.dsc}}
                >
                    <FormCommentContent/>
                </ModalForm>
                <ModalForm
                    isOpen={isAddPopupOpen}
                    toggle={this.openFormPopup}
                    onSubmit={this.onSubmit}
                    form={ADD_PATIENTS_FORM}
                    submitText={"Добавить пациента"}
                    title={"Новый пациент"}
                    icon={"user-plus"}
                >
                    <FormContent/>
                </ModalForm>
            </div>
        )
    }
}

const mapStateToProps = (store) => ({
    tableData: getStoreData(() => store.patients.initialData.data),
    total: getStoreData(() => store.patients.initialData.total),
    id: getStoreData(() => store.profile.initialData.data[0].id),
    formData: getStoreData(() => store.patients.formData),
    selectedRow: store.selectedRow.row,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchPatientData,
    selectRow,
    reset
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PatientsTable);
