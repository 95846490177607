import React, {PureComponent} from 'react';
import {Col, Row} from "reactstrap";

//utils
import {getStoreData} from "../../../utils/getStoreData";
import {dateLengthValidation, minValue, number} from "../../../utils/formValidation";

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Field, reduxForm} from 'redux-form';
import {fetchVisit2TherapyData} from "../../../redux/actions/visit2/visit2TherapyAction";

//compoentns
import renderCheckbox from "../../_commonComponents/form/CheckBox";
import renderInput from "../../_commonComponents/form/InputWithValidation";
import DeleteModal from '../../_commonComponents/DeleteModal';

//components
import {SET} from "../../../config/requestConstants";
import {DATE_MASK} from "../../../config/baseConstants";

class TherapyForm extends PureComponent {
    static defaultProps = {
        readOnly: false
    };

    onChange = async (values) => {
        const {
            fetchVisit2TherapyData,
        } = this.props;

        await fetchVisit2TherapyData(SET, [], values, false);
    };

    onHandleChange = () => this.props._onChange(this.props.handleSubmit(this.onChange));
    onCheckboxChange = () => this.props._onCheckboxChange(this.props.handleSubmit(this.onChange));

    render() {
        const {
            form,
            readOnly,
            handleSubmit,
            onDelete,
            initialValues
        } = this.props;

        return(
            <Col xs={12} md={6} className={"mb-4"}>
                <form className={"form form_small"}>
                    <div className={"visitCard"}>
                        <Row>
                            {!readOnly && <DeleteModal
                                onDelete={handleSubmit(onDelete)}
                                name={`${getStoreData(() => initialValues.num)}`}
                            />}
                            <Col xs={2} className={"pr-0"}>
                                <Field
                                    name={"num"}
                                    component={renderInput}
                                    label={"№"}
                                    className={"p-0 text-center"}
                                    readOnly
                                />
                            </Col>
                            <Col xs={10}>
                                <Field
                                    name={"mdmtrt"}
                                    component={renderInput}
                                    label={"Торговое название:"}
                                    onChange={this.onHandleChange}
                                    readOnly={readOnly}
                                />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    name={"mdmdose"}
                                    component={renderInput}
                                    validate={[number, minValue]}
                                    label={"Суточная доза:"}
                                    onChange={this.onHandleChange}
                                    readOnly={readOnly}
                                />
                            </Col>
                            <Col xs={6}>
                                <Field
                                    name={"mdmstdat"}
                                    component={renderInput}
                                    label={"Дата начала:"}
                                    mask={DATE_MASK}
                                    validate={[dateLengthValidation]}
                                    onChange={this.onHandleChange}
                                    subtext={"дд.мм.гггг"}
                                    readOnly={readOnly}
                                />
                            </Col>
                            <Col xs={6}>
                                <Field
                                    name={"mdmendat"}
                                    component={renderInput}
                                    label={"Дата завершения:"}
                                    mask={DATE_MASK}
                                    validate={[dateLengthValidation]}
                                    onChange={this.onHandleChange}
                                    subtext={"дд.мм.гггг"}
                                    readOnly={readOnly}
                                />
                            </Col>
                            <Col xs={12} className="d-flex justify-content-end">
                                <Field
                                    name={"mdmongo"}
                                    component={renderCheckbox}
                                    id={`mdmongo${form}`}
                                    defaultChecked={1}
                                    label={"Продолжается"}
                                    onChange={this.onCheckboxChange}
                                    readOnly={readOnly}
                                />
                            </Col>
                        </Row>
                    </div>
                </form>
            </Col>
        )
    }
}

TherapyForm = reduxForm({
    destroyOnUnmount: true,
    enableReinitialize: true
})(TherapyForm);

const mapStateToProps = (store, props) => ({
    form: props.form,
    initialValues: {
        id: getStoreData(() => props.data.id),
        vers: getStoreData(() => props.data.vers),
        num: getStoreData(() => props.data.num),
        mdmtrt: getStoreData(() => props.data.mdmtrt),
        mdmdose: getStoreData(() => props.data.mdmdose),
        mdmendat: getStoreData(() => props.data.mdmendat),
        mdmongo: getStoreData(() => props.data.mdmongo),
        mdmstdat: getStoreData(() => props.data.mdmstdat),
        sub: getStoreData(() => props.data.sub),
    }
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchVisit2TherapyData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TherapyForm);
