import React, {useState } from 'react';
import IconButton from '../../buttons/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TableHeader = ({title, onReset, onToggle, icon, count, prefix = null, saveIcon = "user-plus", resetIcon = "sync-alt", isAddIconVisible = true, isFilterIconVisible = false, filterIcon, onFilterIconClick, isTestHidden = false}) => {
    function onFilterClick() {
        onFilterIconClick(!isTestHidden);
    }

    return (
        <div className="tableHeader d-flex justify-content-between">
            <div className="d-flex justify-content-start align-items-center">
                <div className={"tableHeader__icon"}>
                    <FontAwesomeIcon icon={prefix ? [prefix, icon] : icon}/>
                </div>
                <h3 className="tableHeader__title mb-0 ml-2">{title}</h3>
                {count > 0 && <div className="tableHeader__count">{count}</div>}
            </div>
            <div>
                {isAddIconVisible && <IconButton
                    icon={saveIcon}
                    className={"tableHeader__button"}
                    color={'secondary'}
                    onClick={onToggle}
                    outline
                />}
                {isFilterIconVisible && <IconButton
                    icon={"filter"}
                    className={"tableHeader__button"}
                    color={isTestHidden ? 'success' : 'secondary'}
                    onClick={onFilterClick}
                    outline
                />
                }
                <IconButton
                    icon={resetIcon}
                    className={"tableHeader__button"}
                    color={'secondary'}
                    onClick={onReset}
                    outline
                />
            </div>
        </div>
    )
};

export default TableHeader;
