import React, {PureComponent} from 'react';
import {Col, Row} from 'reactstrap';

//utils
import {getStoreData} from "../../utils/getStoreData";

//redux
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {fetchUsersData} from "../../redux/actions/management/usersAction";
import {reset} from 'redux-form';

//components
import {FormContent} from './components/UsersForm';
import Table from '../_commonComponents/table/Table';
import Form from "../_commonComponents/table/Form";
import ModalForm from "../_commonComponents/form/ModalForm";

//constants
import {
    ADD,
    DEL,
    ROLE_ADMIN,
    SET,
    USERS_ADD_FUNCTION
} from "../../config/requestConstants";

import {
    BASE_ADMIN_FILTER,
    SM_SCREEN_WIDTH,
    LG_SCREEN_WIDTH,
    ADD_USER_ADMIN_FORM,
    EDIT_USER_ADMIN_FORM,
    ADMIN_USERS_FILTER
} from "../../config/baseConstants";

/**
 * Панель управления пользователями с ролью "Администратор"
 */
class Management extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tableKey: false,
            order: null,
            formData: null,
            isMobile: false,
            isFormVisible: false,
            isActionVisible: false,
            isModalOpen: false,
        }
    }

    componentDidMount() {
        this.isMobile();
        window.addEventListener('resize', this.isMobile);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.isMobile);
    }

    componentDidUpdate() {
        this.setInitialSelectedRow();
    }

    setInitialSelectedRow = () => {
        if (!this.state.formData) {
            if (this.props.tableData && this.props.tableData.length > 0) {
                this.setState({
                    formData: this.props.tableData && this.props.tableData[0]
                })
            }
        }
    };

    isMobile = () => {
        const isMobile = window.innerWidth < SM_SCREEN_WIDTH;
        const isActionVisible = window.innerWidth < LG_SCREEN_WIDTH;
        const isFormVisible = window.innerWidth > LG_SCREEN_WIDTH;

        this.setState({
            isMobile,
            isFormVisible,
            isActionVisible
        });
    };

    /**
     * @param formData {Object}
     */
    getRowData = (formData) => {
        const {
            isActionVisible
        } = this.state;

        const isFormVisible = isActionVisible ? !this.state.isFormVisible : false;

        this.setState({
            formData: formData.original,
            isFormVisible
        })
    };

    onCloseForm = () => {
        this.setState(prevState => ({
            isFormVisible: !prevState.isFormVisible
        }))
    };

    /**
     * @return {Promise<void>}
     */
    onDelete = async () => {
        const {
            fetchUsersData
        } = this.props;

        const {
            formData
        } = this.state;

        const requestData = {
            id: getStoreData(() => formData.id)
        };

        await fetchUsersData(DEL, [], null, null, null, requestData, false);

        this.setState(prevState => ({
            tableKey: !prevState.tableKey,
            formData: getStoreData(() => this.props.tableData[0]),
        }))
    };

    modalToggle = () => {
        const {
            reset
        } = this.props;

        this.setState(prevState => ({
            isModalOpen: !prevState.isModalOpen
        }), () => {
            reset(ADD_USER_ADMIN_FORM)
        })
    };

    /**
     * @param values {Object}
     * @return {Promise<void>}
     */
    onSubmit = async (values) => {
        const {
            fetchUsersData,
        } = this.props;

        const isNew = !values.id && !values.vers;
        const action = isNew ? ADD : SET;

        const data = isNew ? {...values, ...{role: ROLE_ADMIN}} : values;
        const func = isNew ? USERS_ADD_FUNCTION : null;

        await fetchUsersData(action, [], null, null, null, data, false, func);
        this.setState(prevState => ({
            tableKey: !prevState.tableKey,
            formData: this.props.formData
        }))
    };

    onReset = () => {
        this.setState({
            formData: getStoreData(() => this.props.tableData[0]),
        })
    };

    get columns() {
        const {
            isMobile
        } = this.state;

        return [
            {
                ...!isMobile && {
                    Header: '',
                    accessor: 'name',
                    id: 'name',
                    filterable: false,
                    sortable: false,
                    isFilterable: true,
                    isSortable: true,
                    isVisible: !isMobile,
                    placeholder: 'Id',
                    width: isMobile ? '100%' : 144,
                    maxWidth: isMobile ? '100%' : 144,
                }
            },
            {
                Header: '',
                accessor: 'title',
                id: 'title',
                filterable: false,
                sortable: false,
                isFilterable: true,
                isSortable: true,
                isVisible: true,
                placeholder: 'Имя'
            },
            {
                Header: '',
                accessor: 'phone_view',
                id: 'phone_search',
                filterable: false,
                sortable: false,
                isFilterable: true,
                isSortable: true,
                isVisible: true,
                placeholder: 'Телефон',
                width: isMobile ? 125 : 175,
                maxWidth: isMobile ? 125 : 175,
            }
        ].filter(el => Object.keys(el).length > 0);
    }

    get isTableShow() {
        const {
            isFormVisible,
            isActionVisible,
        } = this.state;

        return isActionVisible ? !isFormVisible : true;
    }

    get isFormShow() {
        const {
            isFormVisible,
            isActionVisible,
        } = this.state;

        return isActionVisible ? isFormVisible : true;
    }

    get selectedFormData() {
        const {
            formData
        } =this.state;

        const {
            tableData
        } = this.props;

        return formData ? formData : (tableData ? tableData[0] : null);
    }

    get initialValues() {
        return {
            id: getStoreData(() => this.selectedFormData.id),
            vers: getStoreData(() => this.selectedFormData.vers),
            name: getStoreData(() => this.selectedFormData.name),
            title: getStoreData(() => this.selectedFormData.title),
            phone: getStoreData(() => this.selectedFormData.phone),
        }
    }

    render() {
        const {
            total,
            fetchUsersData,
            tableData
        } = this.props;

        const {
            isModalOpen,
            tableKey
        } = this.state;

        return (
            <Row className="wrapper">
                {this.isTableShow
                && <Col xs={12} xl={6} className="wrapper__column wrapper__column_border pr-xl-0">
                    <Table
                        columns={this.columns}
                        headerTitle="Администраторы"
                        headerIcon={"user-cog"}
                        onToggle={this.modalToggle}
                        fetchAction={fetchUsersData}
                        tableData={tableData}
                        filterForm={ADMIN_USERS_FILTER}
                        total={total}
                        selected={this.selectedFormData}
                        initFilter={BASE_ADMIN_FILTER}
                        onRowClick={this.getRowData}
                        tableKey={tableKey}
                        onReset={this.onReset}
                    />
                </Col>
                }
                {this.isFormShow
                && <Col xs={12} xl={6} className="wrapper__column pl-xl-0">
                    <Form
                        onToggle={this.onCloseForm}
                        onSubmit={this.onSubmit}
                        onDelete={this.onDelete}
                        form={EDIT_USER_ADMIN_FORM}
                        initialValues={this.initialValues}
                    >
                        <FormContent/>
                    </Form>
                </Col>}
                <ModalForm
                    isOpen={isModalOpen}
                    toggle={this.modalToggle}
                    onSubmit={this.onSubmit}
                    form={ADD_USER_ADMIN_FORM}
                    submitText={"Создать пользователя"}
                    title={"Новый пользователь"}
                    icon={"user-plus"}
                >
                    <FormContent
                        isNew
                    />
                </ModalForm>
            </Row>
        )
    }
}

const mapStateToProps = (store) => ({
    tableData: getStoreData(() => store.adminUsr.initialData.data),
    formData: getStoreData(() => store.adminUsr.formData.data),
    total: getStoreData(() => store.adminUsr.initialData.total),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUsersData,
    reset
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Management);
