export const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = 'Поле не может быть пустым';
  } else {
    if (!/[0-9 -()+]+$/.test(values.username)) {
      errors.isPhone = false;
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
        errors.username = 'Некорректный email';
      }
    } else {
      errors.isPhone = true;
      if (values.username.match(/\d/g).length < 11) {

        errors.username = 'Некорректный номер телефона';
      }
    }
  }
  if (!values.password) {
    errors.password = 'Поле не может быть пустым';
  } else if (values.password.length > 30 ) {
    errors.password = 'Пароль слишком длинный';
  } else if (values.password.length < 4 ) {
    errors.password = 'Пароль слишком короткий';
  }
  return errors;
};
