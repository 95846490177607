import React, {PureComponent, Fragment} from 'react';
import {Button} from 'reactstrap';

//utils
import {validate} from "../../../utils/validate";
import {getStoreData} from "../../../utils/getStoreData";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import {fetchLoginData} from '../../../redux/actions/loginAction';

//components
import renderInputValidationField from '../../_commonComponents/form/InputWithValidation';
import ResetForm from './ResetForm';

//constants
import {MSG_ERR} from "../../../config/requestConstants";
import {PHONE_MASK} from "../../../config/baseConstants";

class LoginForm extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isResetFormShow: false
        }
    }

    onSubmit = (values) => {
        const {
            fetchLoginData,
            history
        } = this.props;

        return fetchLoginData(values, true)
            .then(() => {
                history.push('/');
                throw new SubmissionError({
                    name: MSG_ERR,
                    pass: MSG_ERR,
                })
            })
    };

    onToggleResetForm = () => {
        this.setState(prevState => ({
            isResetFormShow: !prevState.isResetFormShow
        }))
    };

    render() {
        const {
            handleSubmit,
            fieldMsg
        } = this.props;

        const {
            isResetFormShow
        } = this.state;

        return (
            <Fragment>
                {!isResetFormShow
                && <form className="login-form" onSubmit={handleSubmit(this.onSubmit)}>
                    <h4>Вход в систему</h4>
                        <Field
                            type="tel"
                            component={renderInputValidationField}
                            name={"name"}
                            fieldMsg={fieldMsg}
                            label={'Номер мобильного телефона'}
                            mask={PHONE_MASK}
                            autoComplete="name"
                        />
                        <Field
                            type="password"
                            component={renderInputValidationField}
                            name={"pass"}
                            fieldMsg={fieldMsg}
                            label={'Пароль'}
                            autoComplete="current-password"
                        />
                    <Button color="success" className="btn-lg btn-block mt-4 text-uppercase">Вход</Button>
                    <div className="text-right mt-4">
                        <div className="login-form__link" onClick={this.onToggleResetForm}>Забыли пароль?</div>
                    </div>
                </form>}
                {isResetFormShow
                && <ResetForm onClick={this.onToggleResetForm} />}
            </Fragment>
        )
    }
}

LoginForm = reduxForm({
    form: 'login-form',
    validate,
})(LoginForm);

const mapStateToProps = (store) => ({
    defaultPage: getStoreData(() => store.profile.initialData.data[0].default_view_path),
    data: getStoreData(() => store.profile.initialData.data[0]),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchLoginData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
