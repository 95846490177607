import React, {PureComponent} from 'react';
import {Row, Col} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchVisit1TherapyData} from "../../../redux/actions/visit1/visit1TherapyAction";

//utils
import {getStoreData} from "../../../utils/getStoreData";
import {returnBootstrapColor} from "../../../utils/returnBootstrapColor";

//components
import TherapyForm from './TherapyForm';

//constants
import {GET, ADD, DEL} from "../../../config/requestConstants";

class TherapyCard extends PureComponent {
    static defaultProps = {
        readOnly: false
    };

    componentDidMount() {
        this.getInitialData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.getInitialData();
        }
    }

    getInitialData = () => {
        const {
            fetchVisit1TherapyData,
            id
        } = this.props;

        const filter = [{name:"sub", value: id}];

        id && fetchVisit1TherapyData(GET, filter);
    };

    addNewTherapy = async () => {
        const {
            fetchVisit1TherapyData,
            id
        } = this.props;

        const data = {
            sub: id
        };

        await fetchVisit1TherapyData(ADD, [], data, false);
        this.getInitialData();
    };

    onDelete = async (values) => {
        const {
            fetchVisit1TherapyData,
        } = this.props;

        const data = {
            id: values.id
        };

        await fetchVisit1TherapyData(DEL, [], data, false);
        this.getInitialData();
    };

    render() {
        const {
            therapy,
            readOnly
        } = this.props;

        return (
            <Row>
                {
                    therapy && therapy.map((item) =>
                        <TherapyForm
                            form={`visit1_therapy_form_${item.id}`}
                            data={item}
                            key={item.id}
                            readOnly={readOnly}
                            onDelete={this.onDelete}
                        />
                    )
                }
                {!readOnly &&<Col xs={12} md={6} className={"mb-4"}>
                    <div className="visitCard visitCard_pointer d-flex justify-content-center align-items-center">
                        <Row>
                            <Col xs={12} className="visitCard__add d-flex justify-content-center align-items-center"
                                 onClick={this.addNewTherapy}>
                                <FontAwesomeIcon icon={"plus"} color={returnBootstrapColor('success')}/>
                            </Col>
                        </Row>
                    </div>
                </Col>}
            </Row>

        )
    }
}

const mapStateToProps = (store, props) => ({
    data: getStoreData(() => store.visit1Therapy.initialData.data),
    therapy: getStoreData(() => store.visit1Therapy.initialData.data),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchVisit1TherapyData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TherapyCard);
