import {
    VISIT2_EVENTS_IS_EDITED,
    VISIT2_EVENTS_IS_ERROR,
    VISIT2_EVENTS_IS_GET,
    VISIT2_EVENTS_IS_LOADING
} from '../../actions/visit2/visit2EventsAction';

const initialValues = {
    isLoading: false,
    isError: false,
    initialData: null,
    formData: null
};

export function visit2EventsReducer(state = initialValues, action) {
    switch (action.type) {
        case VISIT2_EVENTS_IS_LOADING:
            return {...state, isLoading: true, isError: false};
        case VISIT2_EVENTS_IS_ERROR:
            return {...state, isLoading: false, isError: true};
        case VISIT2_EVENTS_IS_GET:
            return {...state, isLoading: false, isError: false, initialData: action.payload};
        case VISIT2_EVENTS_IS_EDITED:
            return {...state, isLoading: false, isError: false, formData: action.payload};
        default:
            return state;
    }
}
