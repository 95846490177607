import React, {PureComponent} from 'react';
import {Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {returnBootstrapColor} from "../../../utils/returnBootstrapColor";
import {fetchVisit1DiseasesData} from "../../../redux/actions/visit1/visit1DiseasesAction";
import {formValueSelector} from "redux-form";

//constants
import {ADD, DEL, GET} from "../../../config/requestConstants";

//components
import DiseasesForm from './DiseasesForm';
import {getStoreData} from "../../../utils/getStoreData";

class DiseasesCard extends PureComponent{
    static defaultProps = {
        readOnly: false
    };

    componentDidMount() {
        this.getInitialData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.getInitialData();
        }
    }

    getInitialData = () => {
        const {
            fetchVisit1DiseasesData,
            id
        } = this.props;

        const filter = [{name:"sub", value: id}];
        id && fetchVisit1DiseasesData(GET, filter);
    };

    addNewDiseases = async () => {
        const {
            fetchVisit1DiseasesData,
            id
        } = this.props;

        const data = {
            sub: id
        };

        await fetchVisit1DiseasesData(ADD, [], data, false);
        this.getInitialData();
    };

    onDelete = async (values) => {
        const {
            fetchVisit1DiseasesData
        } = this.props;

        const data = {
            id: values.id
        };

        await fetchVisit1DiseasesData(DEL, [], data, false);
        this.getInitialData();
    };

    render() {
        const {
            diseases,
            readOnly
        } = this.props;

        return (
            <Row>
                {
                    diseases && diseases.map((item) => {
                        const selector = formValueSelector(`visit1_diseases_form_${item.id}`);
                         return (
                             <DiseasesForm
                                 form={`visit1_diseases_form_${item.id}`}
                                 selector={selector}
                                 data={item}
                                 key={item.id}
                                 readOnly={readOnly}
                                 onDelete={this.onDelete}
                             />
                         )
                    }
                    )
                }
                {!readOnly && <Col xs={12} md={6} className={"mb-4"}>
                    <div className="visitCard visitCard_pointer d-flex justify-content-center align-items-center">
                        <Row>
                            <Col xs={12} className="visitCard__add d-flex justify-content-center align-items-center"
                                 onClick={this.addNewDiseases}>
                                <FontAwesomeIcon icon={"plus"} color={returnBootstrapColor('success')}/>
                            </Col>
                        </Row>
                    </div>
                </Col>}
            </Row>
        )
    }
}

const mapStateToProps = (store) => ({
    diseases: getStoreData(() => store.visit1Diseases.initialData.data)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchVisit1DiseasesData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DiseasesCard);
