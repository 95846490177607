import {
   LINKED_USERS_DATA_EDITED,
    LINKED_USERS_DATA_ERROR,
    LINKED_USERS_DATA_GET,
    LINKED_USERS_DATA_LOADING,
    LINKED_USERS_IS_CHANGES
} from '../../actions/management/linkedUsersAction';
import {getStoreData} from "../../../utils/getStoreData";

const initialState = {
    isLoading: false,
    isError: false,
    initialData: null,
    formData: null,
    linkedTotal: null
};

export function linkedUsersReducer(state = initialState, action) {
    switch (action.type) {
        case LINKED_USERS_DATA_LOADING:
            return {...state, isLoading: true, isError: false};
        case LINKED_USERS_DATA_ERROR:
            return {...state, isLoading: false, isError: true};
        case LINKED_USERS_DATA_GET:
            return {...state, isLoading: false, isError: false, initialData: action.payload, linkedTotal: getStoreData(() => action.payload.total_linked)};
        case LINKED_USERS_DATA_EDITED:
            return {...state, isLoading: false, isError: false, formData: action.payload};
        case LINKED_USERS_IS_CHANGES:
            return {...state, linkedTotal: action.payload};
        default:
            return state;
    }
}
