import React, {PureComponent} from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import renderInput from '../../form/InputWithSortButton';
import debounce from 'debounce';

class FilterForm extends PureComponent {
    constructor(props) {
        super(props);

        this._onChange = debounce(this._onChange, 600);
    }

    _onChange = (callback) => {
        callback();
    };

    onSubmit = () => this._onChange(this.props.handleSubmit(this.props.onFilter));

    render() {
        const {
            columns,
            onSort,
            isSorted,
            filterName,
            form
        } = this.props;

        return (
            <form className="tableFilter form form_horizontal">
                    {columns && columns.map(column =>
                        column.isVisible &&
                         <Field
                            key={`${form}_${column.id}`}
                            name={column.id}
                            component={column.FilterCell ? column.FilterCell : renderInput}
                            onChange={this.onSubmit}
                            placeholder={column.placeholder}
                            isSortable={column.isSortable}
                            isFilterable={column.isFilterable}
                            onSort={() => onSort(column.id)}
                            isSorted={filterName === column.id ? isSorted : 'nosort' }
                            style={{maxWidth: column.width}}
                            position={column.position}
                        />
                    )}
            </form>
        )
    }
}

FilterForm = reduxForm({
    destroyOnUnmount: true,
    enableReinitialize: true
})(FilterForm);

const mapStateToProps = (store, props) => ({
    form: props.form,
});

export default connect(mapStateToProps)(FilterForm);
