import React, {PureComponent} from 'react';
import {CustomInput, FormGroup, Input} from "reactstrap";

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchLinkedUsersData, setLinkedUsers} from "../../../redux/actions/management/linkedUsersAction";
import {reset} from 'redux-form';

//utils
import {getStoreData} from "../../../utils/getStoreData";

//components
import Table from '../../_commonComponents/table/Table';
import {NESTED_MR_TABLE_FILTER, LIMIT} from "../../../config/baseConstants";

//constants
import {LINKED_CHANGE_USERS_OBJECT, SET, ROLE_NAME_INV} from "../../../config/requestConstants";

/**
 * Вложенная таблица с исследователями, закрепленными за мед.представителем
 */
class NestedTable extends PureComponent {
    /**
     * @param e {event}
     * @param usr {Number}
     * @param parent {Number}
     * @return {Promise<void>}
     */
    onChange = async (e, usr, parent) => {
        const {
            fetchLinkedUsersData,
            setLinkedUsers
        } = this.props;

        const data = {
            usr,
            parent,
            linked: e.target.checked
        };

        await fetchLinkedUsersData(SET, [], LIMIT, null, null, data, false, LINKED_CHANGE_USERS_OBJECT);
        const {isError, formData, linkedTotal} = this.props;
        if (isError) return;
        const total = formData && formData.linked ? linkedTotal + 1 : linkedTotal - 1;
        setLinkedUsers(total);
    };

    /**
     * @param info {Object}
     * @return {*}
     */
    renderCheckboxCell = (info) => {
        const {
            id
        } = this.props;

        return (
            <FormGroup key={`linked_${info.original.linked}_${id}`} className="m-0 d-flex justify-content-center">
                <CustomInput type="checkbox" defaultChecked={info.original.linked}
                             name={`linked_${info.original.name}_${id}`} id={`linked_${info.original.name}_${id}`}
                             onChange={(e) => this.onChange(e, info.original.usr, info.original.parent)}/>
            </FormGroup>
        )
    };

    /**
     * @param style {String}
     * @param input {Object}
     * @return {*}
     */
    renderFilterOptions = ({style, input}) => {
        return (<div style={style} className="form-group">
            <Input type={"select"} name={"linked"} onChange={input.onChange} value={input.value}>
                <option value="all">Все</option>
                <option value={true}>Прикрепленные</option>
                <option value={false}>Неприкрепленные</option>
            </Input>
        </div>)
    };

    render() {
        const {
            id,
            fetchLinkedUsersData,
            tableData,
            isMobile,
            isWideDesk,
            total,
            isRerender
        } = this.props;

        const  FILTER =   [{name:"parent", value: id}, {name:"role_name", value: ROLE_NAME_INV}];

        const columns = [
            {
                Header: "",
                accessor: 'name',
                id: 'name',
                filterable: false,
                sortable: false,
                isFilterable: true,
                isSortable: true,
                isVisible: true,
                placeholder: 'Id',
                width: isMobile ? '100%' : 126,
                maxWidth: isMobile ? '100%' : 126,
            },
            {
                ...!isMobile && {
                    Header: "",
                    accessor: 'title',
                    id: 'title',
                    filterable: false,
                    sortable: false,
                    isFilterable: true,
                    isSortable: true,
                    isVisible: !isMobile,
                    placeholder: 'Имя',
                }
            },
            {
                ...!isMobile && {
                    Header: "",
                    accessor: 'phone_view',
                    id: 'phone_search',
                    filterable: false,
                    sortable: false,
                    isFilterable: true,
                    isSortable: true,
                    isVisible: !isMobile,
                    placeholder: 'Телефон',
                    width: 178,
                    maxWidth: 178,
                }
            },
            {
                ...isWideDesk && {
                    Header: "",
                    accessor: 'dsc',
                    id: 'dsc',
                    filterable: false,
                    sortable: false,
                    isFilterable: true,
                    isSortable: true,
                    isVisible: isWideDesk,
                    placeholder: 'Регион',
                    width: 178,
                    maxWidth: 178,
                }
            },
            {
                Header: "",
                accessor: 'linked',
                id: 'linked',
                filterable: false,
                sortable: false,
                isFilterable: true,
                isSortable: false,
                isVisible: true,
                Cell: this.renderCheckboxCell,
                FilterCell: this.renderFilterOptions,
                placeholder: 'Все',
                width: 100,
                maxWidth: 100,
            }
        ].filter(el => Object.keys(el).length > 0);

        return (
            <div className="px-4 pt-4" key={isRerender}>
                <Table
                    tableKey={id}
                    initFilter={FILTER}
                    isHeader={false}
                    fetchAction={fetchLinkedUsersData}
                    tableData={tableData}
                    filterForm={NESTED_MR_TABLE_FILTER}
                    total={total}
                    columns={columns}
                    className="tableWrapper tableWrapper_mr p-0"
                    order={'{name}'}
                />
            </div>
        )
    }
}

const mapStateToProps = (store) => ({
    tableData: getStoreData(() => store.linkedUsr.initialData.data),
    linkedTotal: store.linkedUsr.linkedTotal,
    total: getStoreData(() => store.linkedUsr.initialData.total),
    isError: getStoreData(() => store.linkedUsr.isError),
    formData: getStoreData(() => store.linkedUsr.formData),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchLinkedUsersData,
    setLinkedUsers,
    reset
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NestedTable);
