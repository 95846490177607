import React from 'react';
import {Col, Row} from "reactstrap";
import {Field} from "redux-form";
import renderInputField from "../../_commonComponents/form/InputWithValidation";
import {required} from "../../../utils/formValidation";
import {PHONE_MASK} from "../../../config/baseConstants";

const FormComponent = ({isNew = false}) => (
    <Row>
        {!isNew && <Col xs={6} md={2}>
            <Field
                name={"id"}
                component={renderInputField}
                label={"sys id"}
                readOnly
            />
        </Col>}
        {!isNew && <Col xs={6} md={2}>
            <Field
                name={"vers"}
                component={renderInputField}
                label={"vers"}
                readOnly
            />
        </Col>}
        {!isNew && <Col xs={12} md={8}>
            <Field
                name={"name"}
                validate={[required]}
                label={"Id"}
                component={renderInputField}
            />
        </Col>}
        <Col xs={12}>
            <Field
                name={"title"}
                label={"Имя"}
                validate={[required]}
                component={renderInputField}
            />
        </Col>
        <Col xs={12}>
            <Field
                name={"phone"}
                label={"Номер телефона"}
                validate={[required]}
                component={renderInputField}
                mask={PHONE_MASK}
            />
        </Col>
        <Col xs={12}>
            <Field
                name={"dsc"}
                label={"Регион"}
                validate={[required]}
                component={renderInputField}
            />
        </Col>
    </Row>
);

export default FormComponent;
