import React, {PureComponent, Fragment} from 'react';

//utils
import {getStoreData} from "../../../utils/getStoreData";

//component
import {Visit2} from "../../visit2/components/Visit2";

//constants
import Loading from "../../_commonComponents/Loading";

/**
 * Копмонент для отображения формы Визита 2
 */
class Visit2Container extends PureComponent {
    render() {
        const {
            selected,
            visitData,
            isLoading
        } = this.props;

        return (
            <Fragment>
                {isLoading && <Loading className="position-absolute" />}
                <Visit2
                    id={getStoreData(() => selected.id)}
                    name={getStoreData(() => selected.name)}
                    data={visitData}
                    readOnly
                />
            </Fragment>
        )
    }
}

export default Visit2Container;
