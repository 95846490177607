import React, { PureComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';

class App extends PureComponent {
  render() {
    return (
        <BrowserRouter basename={'/teatr'}>
          <Router />
        </BrowserRouter>
    );
  }
}

export default App;
