import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {returnBootstrapColor} from "../../utils/returnBootstrapColor";
import propTypes from 'prop-types';

const ModalComponent = (     {isOpen,
                             toggle,
                             className,
                             title,
                             submitText,
                             closeText,
                             message,
                             color,
                             icon,
                             submit}
) => (
    <Modal color={color} isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>
            {icon &&
            <span className="mr-2" style={{'fontSize': 30}}>
                            <FontAwesomeIcon icon={icon} color={returnBootstrapColor(color)}/>
                        </span>
            }
            {title}
        </ModalHeader>
            <ModalBody>
                {message}
            </ModalBody>
            <ModalFooter>
                <Button type={"button"} color="secondary" onClick={toggle}>{closeText}</Button>{' '}
                <Button type={"button"}  color={color} onClick={submit}>{submitText}</Button>
            </ModalFooter>
    </Modal>
);

ModalComponent.propTypes = {
    isOpen: propTypes.bool.isRequired,
    toggle: propTypes.func.isRequired,
    submit: propTypes.func.isRequired,
    title: propTypes.string,
    submitText: propTypes.string,
    closeText: propTypes.string,
    color: propTypes.string,
    icon: propTypes.string,
    message: propTypes.string,
};

ModalComponent.defaultProps = {
    title: '',
    submitText: 'Создать',
    closeText: 'Отмена',
    color: 'success',
    icon: null
};

export default ModalComponent;
