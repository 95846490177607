import React, {PureComponent} from 'react';
import ReactTable from 'react-table';
import propTypes from 'prop-types';
import 'react-table/react-table.css';
import './styles/styles.scss';
import classNames from 'classnames';

//utils
import {getStoreData} from "../../../utils/getStoreData";

//components
import FilterForm from './components/FilterForm';
import TableHeader from "./components/TableHeader";
import TablePagination from './components/Pagination';

//redux
import {connect} from 'react-redux';
import {reset} from 'redux-form';

//constants
import {GET} from "../../../config/requestConstants";
import {LIMIT} from "../../../config/baseConstants";

/**
 * Компонент рендеринга таблицы с хедером и логикой для фильтрации, сортировки, пагинации и сброса параметров
 */
class Table extends PureComponent {
    _isMounted = false;

    static propTypes = {
        initFilter: propTypes.array,
        columns: propTypes.array.isRequired,
        fetchAction: propTypes.func.isRequired,
        tableData: propTypes.array,
        noDataText: propTypes.string,
        headerTitle: propTypes.string,
        headerIcon: propTypes.string,
        onRowClick: propTypes.func,
        selected: propTypes.object,
        className: propTypes.string,
        order: propTypes.string,
        isAddIconVisible: propTypes.bool,
        prefix: propTypes.string,
        total: propTypes.number,
        filterForm: propTypes.string.isRequired,
        isHeader: propTypes.bool,
        onToggle: propTypes.func,
        onReset: propTypes.func,
    };

    static defaultProps = {
        initFilter: [
            {name: 'role_name', value: 'UNKNOWN'},
            {name: 'id', value: 0, compare: '>'}
        ],
        columns: [],
        noDataText: 'Нет данных',
        loading: false,
        loadingText: '...загрузка',
        headerTitle: '',
        headerIcon: 'user',
        isHeader: true,
        onRowClick: () => {
        },
        selected: null,
        className: '',
        order: null,
        isAddIconVisible: true,
        onReset: () => {},
        selectRow: () => {},
        count: 0,
    };

    constructor(props) {
        super(props);

        this.state = {
            requestData: {},
            tableData: [],
            filter: null,
            offset: 0,
            order: this.props.order,
            tableKey: false,
            filterName: 'nosort',
            isSorted: false,
            page: 1,
            selected: null,
            isTestHidden: false
        }
    }

    componentDidMount() {
        this._isMounted = true;

        this.getInitialData();

        this.setState({
            selected: this.props.selected
        })
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            this.updateData(prevProps, prevState);
        } catch (e) {
            console.error(e)
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * меняем параметры запроса на исходные и вызываем функцию запроса со сбросом выделенной строки на первую
     */
    onReset = () => {
        const {
            filterForm,
            dispatch
        } = this.props;

        this.setState({
            offset: 0,
            order: null,
            filter: this.props.initFilter,
            page: 1,
            filterName: 'nosort',
        }, () => {
            this.resetSelectedData();
            dispatch(reset(filterForm));
        });
    };

    /**
     * вызываем функцию получения исходных данных, сбрасываем выделенное поле на первое
     * @return {Promise<void>}
     */
    resetSelectedData = async () => {
        await this.getInitialData();
        this.props.onReset();
    };

    updateData = (prevProps, prevState) => {
        if (prevState.offset !== this.state.offset) {
            this.getInitialData();
        } else if (prevState.tableKey !== this.state.tableKey) {
            this.getInitialData();
        } else if (prevProps.tableKey !== this.props.tableKey) {
            this.getInitialData();
        }
    };

    /**
     * @return {Promise<void>}
     */
    getInitialData = async ( ) => {
        const {
            fetchAction,
            selectRow
        } = this.props;

        const {
            order,
            offset,
        } = this.state;

        const filter = this.state.filter ? [...this.state.filter] : [...this.props.initFilter];
        await fetchAction(GET, filter, LIMIT, offset, order);

        const tableData = this.props.tableData ? [...this.props.tableData] : [...this.state.tableData];
        if (this._isMounted) {
            this.setState({
                tableData
            })
        }
    };

    /**
     * @param values {Object}
     */
    onDataFilter = async (values) => {
        const {
            fetchAction,
            selectRow
        } = this.props;

        let filter = this.props.initFilter;

        for (let key in values) {
            if (values.hasOwnProperty(key)) {
                const compare = (values[key] === 'true' || values[key] === 'false') ? '=' : 'LIKE';
                if (values[key] !== 'all') {
                    filter = [...filter, {name: key, value: `${values[key]}`, compare}]
                } else {
                    filter = [...filter, {name: key, value: `${values[key]}`, compare}];
                    filter.splice(this.state.filter && this.state.filter.findIndex(({name}) => name === key), 1)
                }
            }
        }

        await fetchAction(GET, filter, LIMIT, null, this.state.order);
        const tableData = this.props.tableData ? [...this.props.tableData] : [...this.state.tableData];

        this.setState({
            page: 1,
            tableData,
            filter,
            isTestHidden: false
        }, () => {
            selectRow(tableData[0])
        })
    };

    /**
     * Забираем текущую страницу пагинации
     * @param state
     * @return {Promise<void>}
     */
    getTableData = async (state) => {
        const offset = (state.page - 1) * LIMIT;
        const page = state.page;

        this.setState({
            offset,
            page
        })
    };

    /**
     * @param name {String}
     */
    onSort = (name) => {
        const {
            filterName
        } = this.state;

        const isSorted = filterName === 'nosort' ? false : !this.state.isSorted;
        const order = isSorted ? `{${name}}` : `{${name} DESC}`;

        this.setState(prevState => ({
            tableKey: !prevState.tableKey,
            isSorted,
            order,
            offset: 0,
            page: 1,
            filterName: name
        }))
    };

    onFilterIconClick = async (isHidden) => {
        const {
            fetchAction,
            selectRow
        } = this.props;

        const filter = isHidden ? [{name: "test", value: false}, {name: "blocked", value:false}] : [];

        await fetchAction(GET, filter, LIMIT, null, this.state.order);const tableData = this.props.tableData ? [...this.props.tableData] : [...this.state.tableData];

        this.setState({
            page: 1,
            tableData,
            filter,
            isTestHidden: isHidden
        }, () => {
            selectRow(tableData[0])
        })
    };

    get pages() {
        const {
            total
        } = this.props;

        return total ? Math.ceil(total / LIMIT) : 0;
    }

    render() {
        const {
            columns,
            filterForm,
            total,
            noDataText,
            headerTitle,
            headerIcon,
            onToggle,
            onRowClick,
            tableKey,
            isHeader,
            className,
            isAddIconVisible,
            prefix,
            selected,
            children,
            isFilterIconVisible
        } = this.props;

        const {
            tableData,
            filterName,
            isSorted,
            page,
            isTestHidden
        } = this.state;

        return (
            <div className="d-flex flex-column w-100">
                {isHeader && <TableHeader
                    title={headerTitle}
                    icon={headerIcon}
                    isAddIconVisible={isAddIconVisible}
                    onToggle={onToggle}
                    onReset={this.onReset}
                    onFilterIconClick={this.onFilterIconClick}
                    isFilterIconVisible={isFilterIconVisible}
                    prefix={prefix}
                    count={total}
                    isTestHidden={isTestHidden}
                />}
                <div className="d-flex overflow-hidden">
                    {children}
                    <div className={classNames(className, "col p-0")}>
                        <FilterForm
                            form={filterForm}
                            columns={columns}
                            filterName={filterName}
                            isSorted={isSorted}
                            onSort={this.onSort}
                            onFilter={this.onDataFilter}
                        />
                        <ReactTable
                            data={[...tableData]}
                            key={tableKey}
                            PaginationComponent={TablePagination}
                            page={page}
                            columns={columns}
                            manual
                            onFetchData={this.getTableData}
                            total={total}
                            pages={this.pages}
                            defaultPageSize={tableData && tableData.length}
                            noDataText={noDataText}
                            getTrProps={(state, rowInfo) => {
                                return {
                                    onClick: (e, t) => {
                                        onRowClick(rowInfo)
                                    },
                                    className: rowInfo && rowInfo.original.id === getStoreData(() => selected.id) ? "-selected" : ""
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(Table);
