import {
    VISIT2_IS_EDITED,
    VISIT2_IS_ERROR,
    VISIT2_IS_GET,
    VISIT2_IS_LOADING
} from '../../actions/visit2/visit2Action';

const initialData = {
    isLoading: false,
    isError: false,
    formData: null,
    initialData: null
};

export function visit2Reducer(state = initialData, action){
    switch (action.type) {
        case VISIT2_IS_LOADING:
            return {...state, isLoading: true, isError: false};
        case VISIT2_IS_ERROR:
            return {...state, isLoading: false, isError: true};
        case VISIT2_IS_GET:
            return {...state, isLoading: false, isError: false, initialData: action.payload, formData: action.formData};
        case VISIT2_IS_EDITED:
            return {...state, isLoading: false, isError: false, formData: action.payload };
        default:
            return state;
    }
}
