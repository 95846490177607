import React, {PureComponent} from 'react';
import {Col, Container, Row} from "reactstrap";
import './styles/login.scss';
import LoginForm from './components/LoginForm'

class Login extends PureComponent {
    render() {
        const {
            history
        } = this.props;

        return (
            <Container fluid className="mainWrapper">
                <Row className="h-100">
                    <Col xs={12} md={6} className="p-0">
                        <div className="top-line">
                            <div className="logo" />
                        </div>
                        <div className="login-card">
                            <LoginForm
                                history={history}
                            />
                        </div>
                    </Col>
                    <Col xs={6} className="bg-success d-none d-md-block">
                        <div className="description">
                            <h3>Наблюдательная программа "ТЕАТР"</h3>
                            <span>Всероссийская наблюдательная неинтервенционная программа применения Тенотена в комплексной терапии Артериальной гипертензии на фоне невротических реакций Тревожно-депрессивного спектра</span>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Login;
