export const HOMEPAGE = 'https://teatr.scithis.com/teatr';

export const SERVICE = 'service/';

export const GET = 'get';
export const SET = 'set';
export const ADD = 'add';
export const DEL = 'del';

//object
export const LOGIN_OBJECT = 'usr.login';
export const RESET_PASS_OBJECT = 'usr.pass_reset'; //сброс пароля на странице авторизации.
export const PROFILE_OBJECT = 'usr.profile';
export const USR_OBJECT = 'usr.users'; //Администраторы
export const SUB_OBJECT ='sub.subs';
export const LINKED_USERS_OBJECT = 'usr.links_all'; //Прикрепленные исследователи.
export const LINKED_CHANGE_USERS_OBJECT = 'usr.links'; //Изменение статуса, чекбокс да/нет.
export const SUB_MR_OBJECT = 'sub.subs_mp'; //Список пациентов прикрепленных к мед.представителю

//visit1
export const SUB_VISIT1_GET_OBJECT = 'sub.visit_1_get'; //Запрос
export const SUB_VISIT1_OBJECT = 'sub.visit_1'; //Сохранение данных
export const SUB_VISIT1_THERAPY_OBJECT = 'sub.visit_1_therapy'; //Терапия основного заболевания
export const SUB_VISIT1_DISEASES_OBJECT = 'sub.visit_1_diseases'; //Сопутствующие заболевания

//visit2
export const SUB_VISIT2_GET_OBJECT = 'sub.visit_2_get'; //Запрос
export const SUB_VISIT2_OBJECT = 'sub.visit_2'; //Сохранение данных
export const SUB_VISIT2_THERAPY_OBJECT = 'sub.visit_2_therapy_changes'; //Изменения терапии основного заболевания
export const SUB_VISIT2_EVENTS_OBJECT = 'sub.visit_2_adverse_events';

export const MSG_ERR = 'Указаны некорректные данные для входа';


//roles
export const ROLE_ADMIN ="ADMIN";
export const ROLE_MP = "ROLES.MP";
export const ROLE_INV = "INV";
export const MP_ROLE = "MP";

//roles name
export const ROLE_NAME_INV = "ROLES.INV";

//functions
export const USERS_ADD_FUNCTION = 'users_add';
export const PASS_RESET_FUNCTION = 'pass_reset';


